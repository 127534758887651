import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const Terms = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        spacing={4}
      >
        <Grid
          item
          lg={8}
          xs={12}
        >
          <div className={classes.content}>
            <Typography variant="h1">
              Termini e condizioni
            </Typography>
            <Typography variant="h6" gutterBottom align="left">
            1. Contenuto del Servizio
      </Typography>
            <Typography variant="body1"  align="left">
Inventu S.r.l.s. società di sviluppo software con sede legale in P.zza Indipendenza 3 (OT) Italia, mette a disposizione degli utenti che intendano avvalersene un servizio (da ora in poi il "Servizio Base" e/o "Servizio") web based che consente di pubblicare e consultare offerte di soggetti privati o professionali che intendano alienare o acquistare beni o prestare e ricevere servizi. Il Servizio consente altresì agli utenti inserzionisti ed agli utenti interessati a quanto pubblicato di entrare in contatto tra di loro.
</Typography>
<Typography variant="h6" gutterBottom align="left">
2. Titolarità della piattaforma
      </Typography>
      <Typography variant="body1"  align="left">
Inventu S.r.l.s., è l'unica titolare della piattaforma web per il tramite della quale viene gestito il Servizio nonchè di tutti i relativi diritti inerenti e conseguenti allo sfruttamento della piattaforma medesima.
</Typography>
<Typography variant="h6" gutterBottom align="left">
3. Applicabilità delle condizioni
      </Typography>
      <Typography variant="body1"  align="left">



Le presenti Condizioni Generali del Servizio si applicano sia agli utenti che utilizzino il Servizio in consultazione delle offerte pubblicate sia agli utenti inserzionisti di offerte (d‘ora in poi collettivamente “utente/i”).
</Typography>
<Typography variant="h6" gutterBottom align="left">
4. Termini per l'uso del Servizio
      </Typography>
      <Typography variant="body1"  align="left">

L’utilizzo del Servizio è consentito solo ad utenti maggiorenni secondo la legge italiana (maggiori di anni 18). L’eventuale utilizzo del Servizio è concesso altresì agli utenti che abbiano compiuto sedici anni  esclusivamente a valle della  autorizzazione e comunque sotto la supervisione dei genitori o di chi ne esercita la potestà o la tutela, che assumeranno quindi ogni responsabilità dell’operato del minore nei confronti di Inventu S.r.l. e dei terzi ad ogni titolo coinvolti.

L'utilizzo del Servizio Base consente la libera consultazione delle offerte e la pubblicazione di offerte e la creazione di utenze finalizzate all'utilizzo del servizio medesimo.

Le relazioni intrattenute tra gli utenti del Servizio, incluso l'acquisto, lo scambio di informazioni, anche per il tramite della chat, la consegna o il pagamento di beni o servizi, avvengono esclusivamente tra utenti senza che Inventu S.r.l. sia parte della relazione. L'utenza si impegna, altresì, a non fare utilizzo improprio dei contatti presenti a qualunque titolo sulla piattaforma di Inventu. A titolo esemplificativo ma non esaustivo è vietato l'invio di pubblicità, materiale promozionale, o qualsiasi altra forma di sollecitazione non autorizzata o non richiesta tramite e-mail o con qualsiasi altro metodo di contatto.

Gli utenti manterranno la titolarità di tutti diritti di proprietà relativi a video, fotografie, fermo immagini, audio o altro materiale (di seguito, le “Riproduzioni”) che Inventu ha consentito agli stessi di pubblicare sul proprio sito. Fermo restando quanto precede, l’utente concede a Inventu una licenza non esclusiva, gratuita, perpetua, trasferibile, irrevocabile e senza limiti di territorio, per utilizzare (anche in combinazione con altri materiali audio e/o video e/o fotografici) e, dunque, fissare, all’interno del sito e/o su ogni altro mezzo (sia esso digitale, elettronico o cartaceo), riprodurre, modificare, adattare, tradurre, distribuire, pubblicare, visualizzare, riprodurre in pubblico le Riproduzioni dell’utente, impegnandosi peraltro a non opporsi alla pubblicazione, utilizzo, modifica, cancellazione e sfruttamento delle Riproduzioni da parte di Inventu.

Con l’utilizzo del Servizio, gli utenti si assumono l’esclusiva responsabilità circa il diritto di utilizzo delle Riproduzioni pubblicate dagli stessi sul sito, manlevando e tenendo indenne Inventu da qualsiasi pretesa e/o richiesta di risarcimento formulata da soggetti terzi a fronte della violazione dei diritti di quest’ultimi.

Al momento della registrazione sul sito da parte dell’utente, quest’ultimo sarà tenuto a fornire i propri dati anagrafici reali senza ricorrere all'utilizzo di indirizzi email temporanei o alias (ovvero indirizzi associati al proprio indirizzo email dal quale però non è possibile inviare email ma solo riceverle). Resta espressamente inteso che, in caso di mancata osservanza delle disposizioni della presente clausola da parte dell’utente, Inventu sarà considerata liberata dell’onere di fornire taluni servizi di assistenza allo stesso, essendo questa impossibilitata alla verifica della corrispondenza utente-email.
</Typography>
<Typography variant="h6" gutterBottom align="left">
5. Responsabilità dell'utente
      </Typography>
      <Typography variant="body1"  align="left">

L'utente è totalmente ed esclusivamente responsabile dell'uso del Servizio (da intendersi espressamente con riguardo alle funzioni di pubblicazione, di consultazione, di gestione delle offerte e di contatto tra utenti) ed è pertanto l'unico garante e responsabile dei beni e dei servizi offerti per il tramite del Servizio nonchè della correttezza, completezza e liceità delle offerte e del proprio comportamento nell'ambito del contatto tra utenti. L'utente garantisce la disponibilità e/o la titolarità del bene/servizio oggetto delle offerte medesime. L'utente garantisce altresì che le proprie offerte non violano alcun diritto d'autore né diritto di proprietà industriale né altro diritto di terzi. In caso di contestazione da parte di terzi riguardo a qualsiasi offerta o condotta ad esso legata, l'utente se ne assume la piena responsabilità e si impegna a tenere manlevata e indenne Inventu S.r.l.s. da qualsiasi danno, perdita o spesa. L'utente si impegna ad utilizzare la chat al solo scopo di prendere contatto e scambiare informazioni con gli altri utenti relativamente alle offerte, utilizzando un linguaggio consono, nel rispetto della legge, dell’etica e della netiquette. L'utente, inoltre, si assume ogni responsabilità per eventuali danni che possano derivare al suo sistema informatico dall'uso del Servizio o a quello di terzi. Resta infine inteso che ogni eventuale utilizzo di robot, spider, scraper e/o ulteriori  strumenti automatici per accedere al sito e/o per estrapolare i relativi dati, contenuti, informazioni è espressamente vietato.
</Typography>
<Typography variant="h6" gutterBottom align="left">
6. Limitazione di responsabilità
      </Typography>
      <Typography variant="body1"  align="left">

Inventu S.r.l.s. non presta alcuna garanzia circa il contenuto, la completezza e la correttezza delle offerte pubblicate nè con riguardo ai dati pubblicati, né relativamente alle informazioni successivamente fornite dall’utente, nè con riferimento al numero o alla qualità dei risultati ottenuti tramite il Servizio. In ogni caso Inventu S.r.l.s. si riserva, in qualsiasi momento, il diritto di valutare, approvare, eliminare o impedire l'offerta ovvero il diritto di inibire la consultazione o il contatto per il tramite della chat all'offerta nel caso in cui, a proprio insindacabile giudizio, l'uso del Servizio da parte dell'utente si riferisca a particolari sezioni merceologiche o possa considerarsi lesivo di diritti o delle prerogative di Inventu S.r.l.s. o di terzi. Resta inteso che anche in caso di valutazione ed approvazione delle offerte (siano esse automatiche o manuali) Inventu S.r.l.s. non presta alcuna garanzia circa il contenuto, la completezza e la correttezza delle stesse. Inventu S.r.l.s. è altresì estranea alle trattative eventualmente nascenti dall'uso del Servizio e pertanto non garantisce nè la bontà nè l'esito delle stesse, di conseguenza nessuna richiesta di restituzione, compensazione, riparazione e/o risarcimento a qualunque titolo potrà essere indirizzata nei confronti di Inventu S.r.l.s.

Il Servizio è offerto per il tramite del sito zappami.com (e www.zappami.com)  che possono contenere banner/link ad altri siti Internet o applicazioni che non sono sotto il controllo di Inventu S.r.l.s.; la pubblicazione dei predetti banner/link non comporta l’approvazione o l’avallo da parte di Inventu S.r.l.s. dei relativi siti e dei loro contenuti, né implica alcuna forma di garanzia da parte di quest’ultima che pertanto non si assume alcuna responsabilità. L'utente riconosce, quindi, che Inventu S.r.l.s. non è responsabile, a titolo meramente esemplificativo, della veridicità, correttezza, completezza, del rispetto dei diritti di proprietà intellettuale e/o industriale, né risponde della loro eventuale contrarietà all’ordine pubblico, al buon costume e/o alla morale.
</Typography>
<Typography variant="h6" gutterBottom align="left">
7. Limitazioni nell'erogazione del Servizio
      </Typography>
      <Typography variant="body1"  align="left">

Inventu S.r.l.s. si riserva il diritto di modificare, sospendere o interrompere, in tutto o in parte, il Servizio in qualsiasi momento anche senza preavviso e senza che la stessa sia tenuta ad indicare le ragioni sottese alle predette azioni. Inventu S.r.l.s. ha implementato un sistema antispam unitamente alla chat, che respinge le richieste di contatto attraverso la chat in caso di violazione delle norme applicabili, delle regole e/o delle presenti condizioni (quali a mero titolo esemplificativo, presenza di insulti, epiteti razzisti, attività di spam o phishing, ecc.). Qualora l’utente indichi nella casella di testo della chat link o indirizzi email, il sistema antispam, oscurerà i predetti elementi dandone comunicazione al destinatario. Il sistema antispam in ogni caso non garantisce né l’autenticità del prodotto/servizio in vendita né la veridicità del contenuto del contatto. Inventu S.r.l.s. non potrà quindi in nessun caso essere ritenuta parte o responsabile dello scambio di comunicazioni tra gli utenti e delle trattative da esse nascenti. Inventu S.r.l.s. non potrà parimenti essere ritenuta responsabile dei danni conseguenti alla mancata prestazione del Servizio oggetto del presente contratto a causa dell'errato o mancato funzionamento dei mezzi elettronici di comunicazione per cause estranee alla sfera del proprio prevedibile controllo.

A titolo esemplificativo, ma non esaustivo, il malfunzionamento dei server ed altri dispositivi elettronici anche non facenti parte integrante della rete Internet, malfunzionamento dei software installati, virus informatici, nonchè da azioni di hacker o altri utenti aventi accesso alla rete.
</Typography>
<Typography variant="h6" gutterBottom align="left">
8. Pubblicazione seriale di offerte e/o per conto terzi
      </Typography>
      <Typography variant="body1"  align="left">

E’ espressamente vietato, salvo esplicita autorizzazione da parte di Inventu S.r.l.s.:
- l'utilizzo di sistemi automatici di caricamento offerte, salvo quelli espressamente autorizzati da Inventu S.r.l.s.;
- la pubblicazione seriale e/o la gestione di offerte per conto terzi con ogni mezzo o modalità;
- rivendere a terzi i servizi di Inventu S.r.l.s.
</Typography>
<Typography variant="h6" gutterBottom align="left">
9. Limitazioni al contenuto delle pubblicazioni
      </Typography>
      <Typography variant="body1"  align="left">

L'utente si impegna a non falsificare la propria identità ed a rispettare tutte le disposizioni di legge vigenti nonché le Regole di Inventu S.r.l.s., che a tal fine l’utente accetta e riconosce di dover visionare prima dell’inserimento di ogni offerta o di prendere contatto con un altro utente per il tramite della chat.  

L'utente si impegna altresì, a non utilizzare il Servizio per la pubblicazione, trasmissione, scambio di materiale illecito, volgare, osceno, calunnioso, diffamatorio, offensivo della morale corrente, o, comunque, lesivo dei diritti altrui o di messaggi incitanti all'odio ed alla discriminazione razziale o religioso. Inoltre, l'utente si impegna a non utilizzare il Servizio in maniera tale da violare diritti di proprietà intellettuale o industriale di Inventu S.r.l.s. o di terzi.
</Typography>
<Typography variant="h6" gutterBottom align="left">
10. Pubblicazioni ulteriori
      </Typography>
      <Typography variant="body1"  align="left">

L'utente riconosce ed accetta la possibilità offerta da Inventu S.r.l.s. di far pubblicare le offerte anche su altri siti internet o, comunque, appartenenti a soggetti terzi interessati a promuovere e/o pubblicare le offerte dell'utente. L'utente acconsente inoltre all'utilizzo del contenuto dell'offerta (foto, testo, ecc.) da parte di Inventu S.r.l.s. al fine della pubblicazione dello stesso su siti terzi, riviste on line o cartacee o altri media, impegnandosi altresì a tenere indenne e manlevare Inventu S.r.l.s. da qualsiasi responsabilità e/o richiesta al riguardo.

Inventu S.r.l.s. vieta l’utilizzo da parte di software/applicazioni/siti cd. aggregatori e/o terzi, non espressamente e previamente autorizzati, di qualunque contenuto afferente al Servizio. Ogni violazione al predetto divieto potrà essere perseguito a norma di legge.
</Typography>
<Typography variant="h6" gutterBottom align="left">
11. Giurisdizione, legge applicabile e foro competente
      </Typography>
      <Typography variant="body1"  align="left">

I rapporti tra Inventu S.r.l.s. e gli utenti sono regolati dalla legge e dalla giurisdizione italiana, in base alla quale anche le presenti Condizioni Generali dovranno essere interpretate. Salvo quanto disposto da norme di legge non derogabili, il Tribunale di Milano sarà competente in via esclusiva a dirimere ogni controversia riguardante le presenti Condizioni Generali ed i rapporti dalle stesse regolati.
</Typography>
<Typography variant="h6" gutterBottom align="left">
12. Validità delle presenti Condizioni Generali
      </Typography>
      <Typography variant="body1"  align="left">

Le presenti condizioni Generali di Servizio si considereranno applicabili, ove compatibili, anche in caso di ulteriori, diversi e specifici accordi relativi ai futuri servizi a pagamento. Inventu S.r.l.s. potrà comunque apportare unilateralmente in qualsiasi momento modifiche alle presenti Condizioni Generali dandone comunicazione sulla propria piattaforma web.
</Typography>
<Typography variant="h6" gutterBottom align="left">
13. Modifiche
      </Typography>
      <Typography variant="body1"  align="left">

Le presenti condizioni potrebbero essere soggette a modifiche. In caso di sostanziali modifiche, Inventu S.r.l.s. avviserà l’utente pubblicandole con la massima evidenza sulle proprie pagine o tramite email o altro mezzo di comunicazione.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Terms;
