import React, { createContext, useEffect } from 'react';
import { useMyOffersReducer } from '../reducers/useMyOffersReducer';
import { GET_MY_OFFERS, ADD_MY_OFFER } from '../actions';
import {
  OFFERS_SEARCH_QUERY,
  OFFER_GET_QUERY,
} from '../../graphql/queries';
import { useLazyQuery } from '@apollo/client';

const MyOffersContext = createContext();


const MyOffersProvider = (props) => {
  const [state, dispatch] = useMyOffersReducer();
  const { offers } = state;

  const addMyOfferDispatch = (offer) => dispatch({ type: ADD_MY_OFFER, offer });
  const getMyOffersDispatch = (offers) => dispatch({ type: GET_MY_OFFERS, offers });

  const onCompleteOffersSearch = dataOffer => {
/*     if (!dataOffer.offers || !dataOffer.offers[0] || !dataOffer.offers[0].allData) return;
    getMyOffersDispatch({position: {}, list: dataOffer.offers[0].allData}); */
  };

  const [offersSearch, { data, refetch }] = useLazyQuery(OFFERS_SEARCH_QUERY, {
    onCompleted: onCompleteOffersSearch
  });

  const fetchMyOffers = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if(!user)
    return;    
    const userID = user.id;
    await offersSearch({ variables: {filter: {userID}, salt: Math.random().toString(36).substring(7)}});
  }

  const pushMyOffer = async (offer) => {
    addMyOfferDispatch(offer);
  }

  const addMyOffer = (offer) => {
    // send the offer as a post call   
    pushMyOffer(offer);
  }

  // We use useEffect to make API calls.
  useEffect(() => {
    fetchMyOffers();
  }, []);

  // We use useEffect to check my offers updates.
   useEffect(() => {
    if (data && data.offers && data.offers[0] && data.offers[0].allData) {
      getMyOffersDispatch({position: {}, list: data.offers[0].allData});
    }
  }, [data]); 

  const getMyOffers = () => {
    refetch();
  }

  const providerValue = {
    offers,
    addMyOffer,
    getMyOffers
  }

  return (<MyOffersContext.Provider value={providerValue}>
    {props.children}
  </MyOffersContext.Provider>)

}

export { MyOffersContext, MyOffersProvider };
