import React, { useContext, Fragment } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout, MinimalAuth as MinimalAuth } from './layouts';

import {
  Dashboard as DashboardView,
  UserList as UserListView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  Forgot as ForgotView,
  Home as HomeView,
  Tools as ToolsView,
  NotFound as NotFoundView,
  Terms as TermsView,
  Privacy as PrivacyView,
  Cnge as CngeView
} from './views';
import {AccountContext} from './common/providers/AccountContext';

const Routes = () => {

  const {checkIfAuthenticated} = useContext(AccountContext);

  return (
    <Switch>
      { checkIfAuthenticated() ||  <Fragment>  
           <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />      <RouteWithLayout
        component={ForgotView}
        exact
        layout={MinimalLayout}
        path="/forgot-pwd"
    />      <RouteWithLayout
    component={TermsView}
    exact
    layout={MinimalLayout}
    path="/terms"
  /><RouteWithLayout
  component={PrivacyView}
  exact
  layout={MinimalLayout}
  path="/privacy"
/>
<RouteWithLayout
  component={CngeView}
  exact
  layout={MinimalLayout}
  path="/cnge"
/>
<RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
<Route exact path="/">
  <Redirect to="/sign-in" />
</Route>
    </Fragment> }
      <Redirect
        exact
        from="/home"
        to="/"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MinimalAuth}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={TermsView}
        exact
        layout={MinimalLayout}
        path="/terms"
      />
      <RouteWithLayout
  component={PrivacyView}
  exact
  layout={MinimalLayout}
  path="/privacy"
/>
<RouteWithLayout
  component={CngeView}
  exact
  layout={MinimalLayout}
  path="/cnge"
/>
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalAuth}
        path="/not-found"
      />
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MinimalAuth}
        path="/"
      />
      <RouteWithLayout
        component={ToolsView}
        exact
        layout={MinimalAuth}
        path="/tools"
    />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
