/* eslint-disable react/no-multi-comp */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { UploadPhoto } from 'components';
import { useMediaQuery } from '@material-ui/core';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import CheckIcon from '@material-ui/icons/Check';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
  TimePicker
} from '@material-ui/pickers';
import itLocale from 'date-fns/locale/it';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import validate, { isInteger } from 'validate.js';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import DeleteIcon from '@material-ui/icons/Delete';

function Alert(props) {
  return <MuiAlert
    elevation={6}
    variant="filled"
    {...props}
  />;
}

const schema = {
  descriptionGive: {
    presence: { allowEmpty: true, message: 'è obbligatorio' },
    length: {
      minimum: 4,
      maximum:630
    }
  },
  descriptionTake: {
    presence: { allowEmpty: true, message: 'è obbligatorio' },
    length: {
      minimum: 4,
      maximum: 630
    }
  },
  tagsTake: {
    presence: { allowEmpty: true, message: 'è obbligatorio' },
    length: {
      maximum: 64
    }
  },
  tagsGive: {
    presence: { allowEmpty: true, message: 'è obbligatorio' },
    length: {
      maximum: 128
    }
  }
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    textAlign: 'center',
    '& > *': {
      margin: theme.spacing(1)
    },
    flexGrow: 1,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: '50vh'
  },
  divChips: {
    margin: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  textAreaOffer: {
    fontSize: '2em',
    padding: theme.spacing(1),
  },
  stickToBottom: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  snackMessage: {
    textAlign: 'left'
  },
  snackTitle: {
    color: 'white'
  },
  finalConfirmImg: {
    width: '40%',
    display: 'block',
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '10%',
    },
  }
}));

const getMinutesOfDay = (date) =>{
  if (isInteger(date)){
    return date;
  }
  const currentMinutes = date.getMinutes();
  const hourMinutes = date.getHours();
  return ( hourMinutes * 60 ) + currentMinutes;
}

const getDayFromMinutes = (minutes) =>{
  if (!isInteger(minutes)){
    return minutes;
  }
  const hours = (minutes / 60);
  const rhours = Math.floor(hours);
  const rminutes = Math.round((hours - rhours) * 60);
  let d = new Date();
  let dateWithHour = new Date(d.setHours(hours));
  let dateWithMinutes = new Date(dateWithHour.setMinutes(rminutes));
  return dateWithMinutes;
}

function getSteps() {
  return ['Cosa offri?', 'Cosa chiedi?', 'Quando?',  'Aggiungi foto', 'Finito!'];
}

const names = [
  'Lunedì',
  'Martedì',
  'Mercoledì',
  'Giovedì',
  'Venerdì',
  'Sabato',
  'Domenica',
];

export default function OfferStepForm(props) {
  const classes = useStyles();
  const { listOfTakeTags, listOfGiveTags, offerData, saveUpdateOffer, updateOffer } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [selectedTakeTags, setSelectedTakeTags] = useState(offerData && offerData.tagsTake && offerData.tagsTake.map((obj) => obj._id) || []);
  const [selectedGiveTags, setSelectedGiveTags] = useState(offerData && offerData.tagsGive && offerData.tagsGive.map((obj) => obj._id) || []);
  const [] = useState(offerData || {});
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [daysName, setDaysName] = React.useState([]);
  const [snackBehavior, setSnackBehavior] = useState({open:false, message: '', title: 'Attenzione!', severity: 'warning'});

  const handleDateStartChange = (date) => {
    //setSelectedDate(date);
    updateFormState('scheduling', {...formState.values.scheduling, start: date});
  };

  const handleDateEndChange = (date) => {
    //setSelectedDate(date);
    updateFormState('scheduling', {...formState.values.scheduling, end: date});
  };

  const handleHourStartChange = (date) => {
    updateFormState('scheduling', {...formState.values.scheduling, hourStart: date});
  };

  const handleHourEndChange = (date) => {
    updateFormState('scheduling', {...formState.values.scheduling, hourEnd: date});
  };


  const handleDaysChange = (event) => {
    let days = event.target.value;
    setDaysName(days);
    updateFormState('scheduling', {...(formState.values.scheduling? formState.values.scheduling : {}), weekDays: days });

  };
  
  
  const [formState, setFormState] = useState({
    isValid: false,
    values: offerData || {},
    touched: {},
    errors: {},
    isInitialData: false
  });

  const steps = getSteps();

  const updateFormState = (elementName, elementValue) => {
    //update form state
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [elementName]: elementValue
      },
      touched: {
        ...formState.touched,
        [elementName]: true
      }
    }));
  }

  const saveAll = () => {
    // setting up properties to be compliant with graphql schema
    let offerForm= {...formState.values};
    let scheduling = null;
  
    const offerToSave = {
      userID: offerForm.userID,
      status: offerForm.status? offerForm.status : 'ACTIVE',
      ...(offerForm._id && {_id: offerForm._id}),
      ...(offerForm.descriptionGive && {descriptionGive: offerForm.descriptionGive}),
      ...(offerForm.descriptionTake && {descriptionTake: offerForm.descriptionTake}),
      ...(selectedGiveTags && {tagsGive: selectedGiveTags.map( id => {return {_id: id}} )}),
      ...(selectedTakeTags && {tagsTake: selectedTakeTags.map( id => {return {_id: id}} )}),
      ...(offerForm.scheduling && {scheduling:
      {
        ...( offerForm.scheduling.hourStart  && {hourStart: getMinutesOfDay(offerForm.scheduling.hourStart)}), 
        ...( offerForm.scheduling.hourEnd  && {hourEnd: getMinutesOfDay(offerForm.scheduling.hourEnd)}),
        ...( offerForm.scheduling.weekDays  && {weekDays: offerForm.scheduling.weekDays}),
        ...( offerForm.scheduling.start  && {start: offerForm.scheduling.start}),
        ...( offerForm.scheduling.end  && {end: offerForm.scheduling.end})
      }
      }),
      ...(offerForm.w3wData && {
        w3wData: {
          ...( offerForm.w3wData.address  && {address: offerForm.w3wData.address}),
          ...( offerForm.w3wData.idW  && {idW: offerForm.w3wData.idW})
        }
      }),
      ...(offerForm.position && {
        position: {
          ...( offerForm.position.lat  && {lat: offerForm.position.lat}),
          ...( offerForm.position.lng  && {lng: offerForm.position.lng}),
          ...( offerForm.position.coordinates  && {lat: offerForm.position.coordinates[1]}),
          ...( offerForm.position.coordinates && {lng: offerForm.position.coordinates[0]}),
        }
      })
    };

    // setting up photos
    if(offerForm.photos){
      offerToSave.photos =  offerForm.photos.map( aPhoto => {
        if(aPhoto && aPhoto.url){
          const name = aPhoto.name? aPhoto.name : '';
          const description = aPhoto.description? aPhoto.description : '';
          const url = aPhoto.url;
          const publicID = aPhoto.publicID;
          return {url, name, description, publicID };
        }
      })
    }
   
    saveUpdateOffer(offerToSave)
  }

  const removeOfferMeta = (offerMeta) => {
    if (offerMeta.tagType == 'GIVE') {
      setSelectedGiveTags(selectedGiveTags.filter(offerID => offerID != offerMeta._id));
      updateFormState('tagsGive', selectedGiveTags.filter(offerID => offerID != offerMeta._id))
    }
    if (offerMeta.tagType == 'TAKE') {
      setSelectedTakeTags(selectedTakeTags.filter(offerID => offerID != offerMeta._id));
      updateFormState('tagsTake', selectedTakeTags.filter(offerID => offerID != offerMeta._id))
    }
  };


  const addOfferMeta = (offerMeta) => {
    if (offerMeta.tagType == 'GIVE') {
      setSelectedGiveTags([...selectedGiveTags, offerMeta._id]);
      updateFormState('tagsGive', [...selectedGiveTags, offerMeta._id])
    }
    if (offerMeta.tagType == 'TAKE') {
      setSelectedTakeTags([...selectedTakeTags, offerMeta._id]);
      //update form state
      updateFormState('tagsTake', [...selectedTakeTags, offerMeta._id])
    }
  };

  const handleChangePhotos = photos => {
    updateFormState('photos', photos);
  }

  const handleAlertMessage = (message, severity = 'warning', title = 'Attenzione!') => {
    setSnackBehavior({open: true, message, severity, title});
  }

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <div><TextareaAutosize
            aria-label="maximum height"
            className={classes.textAreaOffer}
            defaultValue=""
            name="descriptionGive"
            onChange={handleChange}
            placeholder="Descrivi cosa vuoi offrire per il tuo terreno"
            rowsMax={20}
            style={{ width: '90%', height: '30vh' }}
            value={formState.values.descriptionGive || ''}
          />
          <div className={classes.divChips}>
            {listOfGiveTags && listOfGiveTags.map(tagMetaObj => (
              <Chip
                key={tagMetaObj._id}
                color={selectedGiveTags.includes(tagMetaObj._id) ? 'primary' : ''}
                deleteIcon={selectedGiveTags.includes(tagMetaObj._id) ? <DeleteIcon/> : <DoneIcon />}
                label={tagMetaObj.name}
                onClick={(e) => addOfferMeta(tagMetaObj, e)}
                onDelete={(e) => removeOfferMeta(tagMetaObj, e)}
                variant={selectedGiveTags.includes(tagMetaObj._id) ? 'default' : 'outlined'}
              />
            ))}
          </div>
          </div>);
      case 1:
        return (
          <div><TextareaAutosize
            aria-label="maximum height"
            className={classes.textAreaOffer}
            defaultValue=""
            name="descriptionTake"
            onChange={handleChange}
            placeholder="Descrivi cosa vuoi chiedere per il tuo terreno"
            rowsMax={20}
            style={{ width: '90%', height: '30vh' }}
            value={formState.values.descriptionTake || ''}
          />
          <div className={classes.divChips}>
            {listOfTakeTags && listOfTakeTags.map(tagMetaObj => (
              <Chip
                key={tagMetaObj._id}
                color={selectedTakeTags.includes(tagMetaObj._id) ? 'primary' : ''}
                deleteIcon={selectedTakeTags.includes(tagMetaObj._id) ? <DeleteIcon/> : <DoneIcon />}
                key={tagMetaObj.name}
                label={tagMetaObj.name}
                onClick={(e) => addOfferMeta(tagMetaObj, e)}
                onDelete={(e) => removeOfferMeta(tagMetaObj, e)}
                variant={selectedTakeTags.includes(tagMetaObj._id) ? 'default' : 'outlined'}
              />
            ))}
          </div>
          </div>);
      case 2:
        return (
          <MuiPickersUtilsProvider
            locale={itLocale}
            utils={DateFnsUtils}
          >
            <Grid
              container
              justify="space-around"
            >
      <Grid item xs={12} md={2}>
      <TimePicker
                ampm={false}
                error={false}
                id="time-picker1" 
                InputLabelProps={{ shrink: true }}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                label="Da che ora?"
                margin="normal"
                onChange={handleHourStartChange}
                required={false}
                value={formState.values.scheduling &&  getDayFromMinutes(formState.values.scheduling.hourStart) || null}
              /> 
      </Grid>
      <Grid item xs={12}  md={2}>
              <TimePicker
                ampm={false} 
                id="time-picker2"
                InputLabelProps={{ shrink: true }}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                label="Sino a che ora?"
                margin="normal"
                onChange={handleHourEndChange}
                value={formState.values.scheduling &&  getDayFromMinutes(formState.values.scheduling.hourEnd) || null}
              />
              </Grid>
              <Grid item xs={12}  md={2}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-checkbox-label">In quali giorni?</InputLabel>
                <Select
                  id="demo-mutiple-checkbox"
                  input={<Input />}
                  labelId="demo-mutiple-checkbox-label"
                  MenuProps={MenuProps}
                  multiple
                  onChange={handleDaysChange}
                  renderValue={(selected) => selected.map(element => names[element]).join(', ')}
                  value={daysName}
                >
                  {names.map((name, index) => (
                    <MenuItem
                      key={name}
                      value={index}
                    >
                      <Checkbox checked={daysName.indexOf(index) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Grid>
              <Grid item xs={12}  md={2}>
              <DatePicker
                clearable
                disableToolbar 
                format="MM/dd/yyyy"
                id="date-picker-inline"
                InputLabelProps={{ shrink: true }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                label="Data Inizio"
                margin="normal"
                onChange={handleDateStartChange}
                value={formState.values.scheduling && formState.values.scheduling.start || null}
                variant="inline"
              />
                            </Grid>
              <Grid item xs={12}  md={2}>
              <DatePicker
                clearable
                format="MM/dd/yyyy" 
                id="date-picker-dialog"
                InputLabelProps={{ shrink: true }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                label="Data finale"
                margin="normal"
                onChange={handleDateEndChange}
                value={formState.values.scheduling &&  formState.values.scheduling.end || null}
              />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        );
      case 3:
        return <UploadPhoto
          handleChangePhotos={handleChangePhotos}
          handleAlertMessage={handleAlertMessage}
          photos={offerData && formState.values.photos || []}
        />;
      case 4: 
        return  <Typography gutterBottom variant="h6">
        Complimenti, hai appena terminato l'inserimendo dell'offerta.
        Clicca il bottone in basso "Finito".
          <img className={classes.finalConfirmImg} src="images/refreshing_beverage_td3r.svg" />
        </Typography>
      default:
        return 'Unknown stepIndex';
    }
  }

  const handleNext = () => {
    // check for descriptionGive/take or tags validation (requireds)
  
    const errors = validate(formState.values || {}, schema);
    if(activeStep == 0 ){
      if ( errors && errors.descriptionGive && errors.tagsGive ) {
        setSnackBehavior({open: true, message: 'Inserisci una descrizione o clicca su un tag!',  title: 'Attenzione!'});
        return;
      }
    }
    if(activeStep == 1 ){
      if ( errors && errors.descriptionTake && errors.tagsTake ) {
        setSnackBehavior({open: true, message: 'Inserisci una descrizione o clicca su un tag!', title: 'Attenzione!',});
        return;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === steps.length - 1) {
      //save current offer
      saveAll();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSnackClose = () => {
    setSnackBehavior({open: false, message: ''});
  };

  useEffect(() => {
    if (!offerData) {
    }
  }, []);

  useEffect(() => {
    updateOffer(formState.values);
  }, [formState.values]);

  return (
    <div className={classes.root}>
      <Paper
        className={classes.header}
        elevation={0}
        square
      >
        <Typography
          gutterBottom
          variant="h4"
        >{steps[activeStep]}</Typography>
      </Paper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>Tutti i passaggi completati</Typography>
            <Button onClick={handleReset}>Cancella</Button>
          </div>
        ) : (
          <div>
            <div className={classes.instructions}><form
              autoComplete="off"
              className={classes.root}
              noValidate
            >
              {getStepContent(activeStep)}</form></div>
          </div>
        )}
      </div>
      <MobileStepper
        activeStep={activeStep}
        backButton={
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            size="small"
          >
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          Indietro
          </Button>
        }
        className={classes.stickToBottom}
        nextButton={
          <Button
            disabled={activeStep === 5}
            onClick={handleNext}
            size="small"
          >
            {activeStep === steps.length - 1 ? 'Finito' : 'Avanti'}
            {(activeStep === steps.length - 1)?  <CheckIcon /> :  theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        position="static"
        steps={6}
        variant="progress"
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={60000}
        className={classes.snackMessage}
        onClose={handleSnackClose}
        open={snackBehavior.open}
      >
        <Alert
          onClose={handleSnackClose}
          severity={snackBehavior.severity}
        >
          <AlertTitle className={classes.snackTitle}>{snackBehavior.title}</AlertTitle>
          {snackBehavior.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
