import { useReducer } from 'react';
import { SIGNIN, SIGNOUT, SIGNUP, RELOGIN, RESULT_SIGNIN_SIGNUP, UPDATE_USER, RESULT_FORGOT, RESULT_RESET } from '../actions';

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
};

/**
 * Reducer for account login/logout purposes.
 * @param {Object} state - The context state.
 * @param {Object} action - The contest action passed by dispatchers.
 * @returns {Object} - The updated state.
 */
const accountReducer = (state, action) => {
  switch (action.type) {
    case SIGNIN:
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      localStorage.setItem('token', JSON.stringify(action.payload.token));
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      };
    case SIGNOUT:
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null
      };
    case SIGNUP:
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      localStorage.setItem('token', JSON.stringify(action.payload.token));
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      }; 

    case UPDATE_USER:
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      }; 

    case RESULT_SIGNIN_SIGNUP:
      return {
        ...state,
        signinSignupResult: action.payload
      }; 
    case RESULT_FORGOT:
      return {
        ...state,
        forgotResult: action.payload
      };
      case RESULT_RESET:
        return {
          ...state,
          resetResult: action.payload
        }; 
    case RELOGIN:
    {
      const user = JSON.parse(localStorage.getItem('user'));
      const token = localStorage.getItem('token');
      const isTokenAvailable = token? true : false;
      return {
        ...state,
        isAuthenticated: isTokenAvailable,
        user,
        token
      }; }
    default:
      return state;
  }
};

export const useAccountReducer = () =>
  useReducer(accountReducer, initialState);