import { useReducer } from 'react';
import { ADD_PREFERRED_OFFER,
  DELETE_PREFERRED_OFFER,
  SEARCH_PREFERRED_OFFERS,
  RESULT_PREFERRED_OFFER } from '../actions';

const initialState = { allPreferredOffers: null,
  preferredSaveData: null,
  preferredSaveResult: null };

const preferredOffersReducer = (state, action) => {
  switch (action.type) {
    case DELETE_PREFERRED_OFFER:
      return {
        ...state,
        preferredSaveResult: action.payload
      };
    case ADD_PREFERRED_OFFER:
      return {
        ...state,
        preferredSaveData: action.payload
      };
    case RESULT_PREFERRED_OFFER:
      return {
        ...state,
        preferredSaveResult: action.payload
      };
    case SEARCH_PREFERRED_OFFERS:
      return {
        ...state,
        allPreferredOffers: action.payload
      };  
    default:
      return state;
  }
};


export const usePreferredOffersReducer = () =>
  useReducer(preferredOffersReducer, initialState);