import React, { createContext } from 'react';
import { useOfferDetailsReducer } from '../reducers/useOfferDetailsReducer';
import { REFRESH_OFFER_DETAILS, HIDE_OFFER_DETAILS } from '../actions';

const OfferDetailsContext = createContext();

const OfferDetailsProvider = (props) => {
    const [state, dispatch] = useOfferDetailsReducer();
    const { isOfferDetailsOpened, offerDetailsData } = state;

    const refreshOfferDetails = (isOfferDetailsOpened, offerDetailsData) => dispatch({ type: REFRESH_OFFER_DETAILS, payload: { isOfferDetailsOpened, offerDetailsData } });
    const hideOfferDetails = () => dispatch({type: HIDE_OFFER_DETAILS});

    const providerValue = {
        isOfferDetailsOpened,
        offerDetailsData,
        refreshOfferDetails,
        hideOfferDetails
    };

    return (
        <OfferDetailsContext.Provider value={providerValue}>
            {props.children}
        </OfferDetailsContext.Provider>
    )
};

export { OfferDetailsContext, OfferDetailsProvider };