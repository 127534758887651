import React, { createContext, useEffect } from 'react';
import { useCurrentChatReducer } from '../reducers/useCurrentChatReducer';
import {
  REFRESH_CURRENT_CHAT_INFO,
  REFRESH_CURRENT_CHAT_INFO_AND_MESSAGES,
  REFRESH_CURRENT_CHAT_MESSAGES,
  HIDE_CURRENT_CHAT,
  DELETE_CURRENT_CHAT,
  SHOW_CURRENT_CHAT
} from '../actions';
import {
  CHAT_ADD_MUTATION,
  CHAT_DELETE_MUTATION,
  CHAT_SEARCH_QUERY,
  MESSAGE_ADD_MUTATION,
  MESSAGE_UPDATE_MUTATION,
  MESSAGE_DELETE_MUTATION,
  MESSAGE_SEARCH_QUERY
} from '../../graphql/queries';
import { useLazyQuery, useMutation } from '@apollo/client';

const CurrentChatContext = createContext();

const CurrentChatProvider = props => {
  const [state, dispatch] = useCurrentChatReducer();
  const { isCurrentChatOpened, currentChatMessages, currentChatInfo, chatDeleteResult } = state;

  const getCurrentChatInfoDispatch = (info = null) =>
    dispatch({
      type: REFRESH_CURRENT_CHAT_INFO,
      payload: { info: info }
    });

  const getCurrentChatInfoAndMessagesDispatch = (info, messages = null) =>
    dispatch({
      type: REFRESH_CURRENT_CHAT_INFO_AND_MESSAGES,
      payload: { info: info, messages: messages }
    });
  const getCurrentChatMessagesDispatch = messages =>
    dispatch({ type: REFRESH_CURRENT_CHAT_MESSAGES, payload: messages });
  const hideCurrentChatDispatch = () => dispatch({ type: HIDE_CURRENT_CHAT });
  const showCurrentChatDispatch = () => dispatch({ type: SHOW_CURRENT_CHAT });
  const resultDeleteChatDispatch = resultChatMessage =>
  dispatch({ type: DELETE_CURRENT_CHAT, payload: resultChatMessage });

  const onCompleteMessageSearch = dataOffer => {
    if (
      !dataOffer.messages ||
      !dataOffer.messages[0] ||
      !dataOffer.messages[0].allData
    )
      return;
    const messages = { list: dataOffer.messages[0].allData };
    getCurrentChatMessagesDispatch(messages);
  };

  const onCompleteChatInfo = dataOffer => {
    if (
      !dataOffer.messages ||
      !dataOffer.messages[0] ||
      !dataOffer.messages[0].allData
    )
      return;
    const messages = { list: dataOffer.messages[0].allData };
    getCurrentChatInfoAndMessagesDispatch(currentChatInfo, messages);
  };

  const onCompleteChatAdd = dataChat => {
    if (!dataChat || !dataChat.addChat) return;
    const messages = { list: [] };
    getCurrentChatInfoAndMessagesDispatch(dataChat.addChat, messages);
  };

  const onCompleteChatDelete = dataChat => {
    if (!dataChat.deleteChat) {
      resultDeleteChatDispatch({ type: 'error', message: 'Errore nella rimozione della chat' });
      return;
    }
    resultDeleteChatDispatch({
      type: 'success',
      message: 'Chat rimossa con successo!'
    });
  };

  /**
   * Callback for chat add mutation error.
   * @param {Object} data - The error.
   */
  const onErrorChatAdd = error => {
    console.log(error);
  };

    /**
   * Callback for chat delete mutation error.
   * @param {Object} data - The error.
   */
  const onErrorChatDelete = error => {
    console.log(error);
  };

  /**
   * Callback for chat info query error.
   * @param {Object} data - The error.
   */
  const onErrorChatInfo = error => {
    console.log(error);
  };

  /**
   * Callback for chat update mutation error.
   * @param {Object} data - The error.
   */
  const onErrorMessageUpdate = error => {
    console.log(error);
  };

  /**
   * Callback for message send error.
   * @param {Object} data - The error.
   */
  const onErrorMessageSend = error => {
    console.log(error);
  };

  /**
   * Callback for messages search error.
   * @param {Object} data - The error.
   */
  const onErrorMessageSearch = error => {
    console.log(error);
  };

  const onCompleteMessageSend = dataMessage => {
    if (!dataMessage || !dataMessage.addMessage) return;
    getCurrentChatMessagesDispatch({
      list: [ dataMessage.addMessage,
        ...(currentChatMessages && currentChatMessages.list
          ? currentChatMessages.list
          : [])
      ]
    });
  };

  const onCompleteMessageUpdate = dataMessage => {
    if (!dataMessage || !dataMessage.updateMessage) return;
    getCurrentChatMessagesDispatch([
      ...(currentChatMessages ? currentChatMessages : []),
      dataMessage.updateMessage
    ]);
  };

  const [addChat, { data4, refetch4 }] = useMutation(CHAT_ADD_MUTATION, {
    onCompleted: onCompleteChatAdd,
    onError: onErrorChatAdd
  });


  const [deleteChat, { data5, refetch5 }] = useMutation(CHAT_DELETE_MUTATION, {
    onCompleted: onCompleteChatDelete,
    onError: onErrorChatDelete
  });

  const [messagesSearch, { data1, refetch1 }] = useLazyQuery(
    MESSAGE_SEARCH_QUERY,
    {
      onCompleted: onCompleteMessageSearch,
      onError: onErrorMessageSearch
    }
  );

  const [chatInfo, { data, refetch }] = useLazyQuery(MESSAGE_SEARCH_QUERY, {
    onCompleted: onCompleteChatInfo,
    onError: onErrorChatInfo
  });

  const [sendMessage, { data2, refetch2 }] = useMutation(MESSAGE_ADD_MUTATION, {
    onCompleted: onCompleteMessageSend,
    onError: onErrorMessageSend
  });

  const [updateMessage, { data3, refetch3 }] = useMutation(
    MESSAGE_UPDATE_MUTATION,
    {
      onCompleted: onCompleteMessageUpdate,
      onError: onErrorMessageUpdate
    }
  );

  const fetchGetCurrentChatMessages = async (id = null) => {
    if(!id){
      return;
    }
    let idChat =  id || (currentChatInfo && currentChatInfo._id);
    await messagesSearch({
      variables: {
        chatID: idChat,
        filter: {},
        salt: Math.random()
          .toString(36)
          .substring(7)
      }
    });

    /*    const chatDataMessages = await fetch('https://run.mocky.io/v3/983ad97f-27dc-4c13-8e6f-bbc1cee9e634');
    const chatDataJson = await chatDataMessages.json();
    getCurrentChatMessagesDispatch(chatDataJson);*/
  };

  const fetchSendMessageChat = async messageObj => {
    //fetchGetCurrentChatMessages(messageDataJson.chatId);
    //getCurrentChatMessagesDispatch([...(currentChatMessages? currentChatMessages : []), messageObj]);
    await sendMessage({
      variables: {
        chatID: messageObj.chatID,
        fromID: messageObj.fromID,
        content: messageObj.content,
        type: 'TEXT',
        salt: Math.random()
          .toString(36)
          .substring(7)
      }
    });
  };

  const fetchUpdateMessageChat = async messageObj => {
    //fetchGetCurrentChatMessages(messageDataJson.chatId);
    //getCurrentChatMessagesDispatch([...(currentChatMessages? currentChatMessages : []), messageObj]);
    await updateMessage({
      variables: {
        _id: messageObj._id,
        content: messageObj.content,
        condition: messageObj.condition,
        salt: Math.random()
          .toString(36)
          .substring(7)
      }
    });
  };

  const fetchGetCurrentChatInfo = async chat => {
    /*     const chatDataInfo = await fetch('https://run.mocky.io/v3/dd703cd7-ea43-46d9-9c0b-e4fdac6bb194');
    const chatDataJson = await chatDataInfo.json();
    // get latest messages or add chat messages in json info to avoid further fetch
    const chatId = chatDataJson.Chats[0]['id'];
    const chatDataMessages = await fetch('https://run.mocky.io/v3/983ad97f-27dc-4c13-8e6f-bbc1cee9e634');
    const chatDataMessagesJson = await chatDataMessages.json(); */
    if(!chat){
      return;
    }

    getCurrentChatInfoDispatch(chat);

    let idChat = chat._id || (currentChatInfo && currentChatInfo._id);

    if(refetch && currentChatInfo && currentChatInfo._id &&  chat._id === currentChatInfo._id){
      refetch();
      showCurrentChatDispatch();
      return;
    }

    await chatInfo({
      variables: {
        chatID: idChat,
        filter: {},
        salt: Math.random()
          .toString(36)
          .substring(7)
      }
    });

    //getCurrentChatInfoDispatch(chatDataJson, chatDataMessagesJson);
  };

  const fetchCreateCurrentChatInfo = async chatObj => {
    await addChat({
      variables: {
        offerID: chatObj.offerID,
        memberIDs: chatObj.memberIDs,
        salt: Math.random()
          .toString(36)
          .substring(7)
      }
    });
  };


  const fetchDeleteCurrentChat = async chatObj => {
    await deleteChat({
      variables: {
        _id: chatObj._id,
        salt: Math.random()
          .toString(36)
          .substring(7)
      }
    });
  };

  // We use useEffect to check current messages updates.
  useEffect(() => {
    if (data) {
      if (!data.messages || !data.messages[0] || !data.messages[0].allData || data.messages[0].allData.length === 0)
        return;
      const messages = { list: data.messages[0].allData };
      getCurrentChatInfoAndMessagesDispatch(data.messages[0].allData[0].chat, messages);
    }
  }, [data]);

  const getCurrentChatMessages = id => {
    fetchGetCurrentChatMessages(id);
  };
  const hideCurrentChat = () => {
    hideCurrentChatDispatch();
  };
  const sendMessageChat = messageObj => {
    fetchSendMessageChat(messageObj);
  };
  const updateMessageChat = messageObj => {
    fetchUpdateMessageChat(messageObj);
  };
  const getCurrentChatInfo = chat => {
    fetchGetCurrentChatInfo(chat);
  };
  const createCurrentChat = chatObj => {
    fetchCreateCurrentChatInfo(chatObj);
  };
  const deleteCurrentChat = chat => {
    // delete the chat as a post call
    fetchDeleteCurrentChat(chat);
  };

  const providerValue = {
    isCurrentChatOpened,
    currentChatInfo,
    currentChatMessages,
    chatDeleteResult,
    getCurrentChatMessages,
    getCurrentChatInfo,
    createCurrentChat,
    deleteCurrentChat,
    hideCurrentChat,
    sendMessageChat,
    updateMessageChat
  };

  return (
    <CurrentChatContext.Provider value={providerValue}>
      {props.children}
    </CurrentChatContext.Provider>
  );
};

export { CurrentChatContext, CurrentChatProvider };
