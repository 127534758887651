import React, { useContext, Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import {MyEngagementsContext} from '../../../../common/providers/MyEngagementsContext';
import { OfferDetailsContext } from '../../../../common/providers/offerDetailsContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justify: 'space-between'
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    marginBottom: '1em'
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  divChips: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const AccountEngagements = () =>  {
  const classes = useStyles();
  const {engagements, isFetching, removeMyEngagement} = useContext(MyEngagementsContext);
  const offerDetailsContext = useContext(OfferDetailsContext);
  const { refreshOfferDetails } = offerDetailsContext;
  const [open, setOpen] = React.useState(false);
  const [engagementToRemove, setEngagementToRemove] = useState(null);
  const handleClose = () => {
    setOpen(false);
  };

  const handleViewOfferClick = (offer) => {
    refreshOfferDetails(true, offer);
  }

  const handleDeleteEngagementClick = (engagement) => {
    const r = window.confirm('Vuoi cancellare la tua offerta?');
    if (r === true) {
      setEngagementToRemove(engagement);
      setOpen(true);
    } 
  }

  const handleDeleteAndClose = () => {
    removeMyEngagement(engagementToRemove.id);
    setOpen(false);
  };

  if( isFetching ){
    return <CircularProgress color="inherit" />
  }
  
  if( engagements && !engagements.list ){
    return <Typography
      color="inherit"
      variant="h3"
    >
    Nessun lavoro!
    </Typography>
  }
  return (
    <Fragment>
      {engagements.list && engagements.list.map( (offer) =>     
        <div key={offer._id}
          className={classes.root}>
          <Paper className={classes.paper} key={offer._id}>
            <Grid
              container
              spacing={2}
            >
              <Grid item>
                <ButtonBase className={classes.image}>
                  <img
                    alt="complex"
                    className={classes.img}
                    src={offer.photos[0].url}
                  />
                </ButtonBase>
              </Grid>
              <Grid
                container
                item
                sm
                xs={12}
              >
                <Grid
                  container
                  direction="column"
                  item
                  spacing={2}
                  xs
                >
                  <Grid
                    item
                    xs
                  >
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                    >
                      {offer.descriptionGive}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="body2"
                    >
                      {offer.descriptionTake}
                    </Typography>
                      <div className={classes.divChips}>
                        {offer &&  offer.tagsGive.map( tagMetaObj => (
                          <Chip
                            color="primary"
                            key={tagMetaObj.name}
                            label={tagMetaObj.name}
                            variant="default"
                          />
                        ))}
                      </div>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{ cursor: 'pointer' }}
                      variant="body2"
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">  
                    <IconButton
                      aria-label="view"
                      edge="end"
                      onClick={() => handleViewOfferClick(offer)}
                    >
                      <VisibilityIcon />
                    </IconButton>            
                    <IconButton
                      aria-label="delete"
                      edge="end"
                      onClick={() => handleDeleteEngagementClick(offer)}
                    >
                      <DeleteIcon />
                    </IconButton></Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </div>
      )}
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="alert-dialog-title">{'Vuoi terminare questa adesione all\'offerta?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
      Cliccando conferma interromperai l'impegno con la persona con la quale ti sei impegnata. Se trattasi di
      un'acquisto allora semplicemente cancellerai il tuo acquisto dallo storico.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
          >
      Annulla
          </Button>
          <Button
            autoFocus
            color="primary"
            onClick={handleDeleteAndClose}
          >
      Conferma
          </Button>
        </DialogActions>
      </Dialog></Fragment>
  );
}

export default AccountEngagements;
