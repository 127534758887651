import { useReducer } from 'react';
import { REFRESH_OFFER_DETAILS, HIDE_OFFER_DETAILS } from '../actions';

const initialState = {
    isOfferDetailsOpened: false,
    offerDetailsData: null
};

const offerDetailsReducer = (state, action) => {
    switch (action.type) {
        case REFRESH_OFFER_DETAILS:
            return {
                ...state,
                isOfferDetailsOpened: true,
                offerDetailsData: action.payload.offerDetailsData
            };
        case HIDE_OFFER_DETAILS:
            return {
                ...state,
                isOfferDetailsOpened: false
            };
        default:
            return state;
    }
};


export const useOfferDetailsReducer = () =>
    useReducer(offerDetailsReducer, initialState);