/* eslint-disable react/no-multi-comp */
import React, { useState, useContext, useEffect, Fragment } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import { green } from '@material-ui/core/colors';
import { OfferStepForm, OfferMultiStep} from 'components';
import { useMediaQuery } from '@material-ui/core';
import { OfferEditSaveContext } from '../../common/providers/OfferEditSaveContext';
import { CurrentOffersContext } from '../../common/providers/CurrentOffersContext';
import { MyOffersContext } from '../../common/providers/MyOffersContext';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: theme.palette.white
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

const OfferEdit = (props) => {
  const classes = useStyles();
  const { isOpen, ...rest } = props;
  const [openSnack, setOpenSnack] = useState(false);
  const [offerSaveMessage, setOfferSaveMessage] = useState({type: 'success', message: 'Offerta salvata con successo!'});
  const offerEditSaveContext = useContext(OfferEditSaveContext);
  const currentOffersContext = useContext(CurrentOffersContext);
  const {reloadOffers, currentPosition } = currentOffersContext;
  const { getMyOffers } = useContext(MyOffersContext);

  const {
    isOfferEditSaveOpened,
    allOfferTags,
    offerEditSaveData,
    toggleOffer,
    saveOffer,
    setOffer,
    offerEditSaveResult
  } = offerEditSaveContext;

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const handleClose = () => {
    toggleOffer();
  };

  const handleSave = () => {
    saveUpdateOffer(offerEditSaveData);
  };


  const handleSnackClose = () => {
    setOpenSnack(false);
  };

  const saveUpdateOffer = (offerObj) => {
    try {
      saveOffer(offerObj);
    } catch (error) {
      setOfferSaveMessage({type: 'error', message: 'Errore durante il salvataggio offerta!'});
    } 
  };

  const updateOffer = (offerObj) => {
    setOffer(offerObj);
  };

  useEffect(() => {
    console.log("cliccato mie offerte");
  }, [isOfferEditSaveOpened]);

  useEffect(() => {
    if(offerEditSaveResult){
      setOfferSaveMessage(offerEditSaveResult);
      setOpenSnack(true);
      if(offerEditSaveResult.type === 'success'){
        if(isOfferEditSaveOpened){
          toggleOffer();
        }
          
        reloadOffers({...currentPosition, lng: currentPosition.lng + 0.00005 });
        getMyOffers();
        //refresh map for new/update offer
      }
    }

  }, [offerEditSaveResult]);

  return (
    <Fragment>
      <Dialog fullScreen open={isOfferEditSaveOpened} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {offerEditSaveData && offerEditSaveData._id ? 'MODIFICA OFFERTA' : 'NUOVA OFFERTA'}
            </Typography>
            {false &&   <Button autoFocus color="inherit" onClick={handleSave}>
                            SALVA
            </Button>}
          </Toolbar>
        </AppBar>
        <OfferMultiStep offerData={offerEditSaveData} listOfTakeTags={allOfferTags && allOfferTags.TAKE}
          listOfGiveTags={allOfferTags && allOfferTags.GIVE} saveUpdateOffer={saveUpdateOffer} updateOffer={updateOffer}
        />
      </Dialog>
      <Snackbar
        open={openSnack}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleSnackClose}
        autoHideDuration={6000}
      >
        <Alert onClose={handleSnackClose} severity={offerSaveMessage.type}>
          {offerSaveMessage.message}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

export default OfferEdit;