import { useReducer } from 'react';
import { REMOVE_NOTIFICATIONS, GET_NOTIFICATIONS } from '../actions';

const initialState = {
    notifications: {},
};
const NotificationsReducer = (state, action) => {
    switch (action.type) {
        case REMOVE_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload
            };
        case GET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload
            };
        default:
                return state;
        
    }
}

export const useNotificationsReducer = () =>
    useReducer(NotificationsReducer, initialState);
