import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SendTwoToneIcon from '@material-ui/icons/SendTwoTone';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import './ChatBubble.css';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const ChatBubble = (props) => {
  const { chatInfo, chatterId, messages, onNewMessage } = props;
  const [newMessage, setNewMessage] = useState()
  const classes = useStyles();

  const getConversations = (messages) => {
    if (messages == undefined || !messages || !messages.list) {
      return;
    }

    const listItems = messages.list.map((message, index) => {
      let bubbleClass = 'me';
      let bubbleDirection = '';
      let senderId = message.from.id;
      let senderAvatar = message.from.profileImage && message.from.profileImage.url || '/images/avatars/avatar_11.png';
      if (senderId == chatterId) {
        bubbleClass = 'you';
        bubbleDirection = 'bubble-direction-reverse';
      }

      let aDoneIcon = <DoneAllIcon fontSize="small" />;
      if( message.condition !== 'SEEN' ) {
        aDoneIcon =  <DoneIcon fontSize="small" />;}

      return (
        <div className={`bubble-container ${bubbleDirection}`} key={index}>
          <img className={`img-circle`} src={senderAvatar} />
          <div className={`bubble ${bubbleClass}`}>
            {message.content}
            {senderId == chatterId && <span className={`bubble-check`} >{aDoneIcon}</span>}</div>
        </div>
      );
    });
    return listItems.reverse();
  }

  const handleSubmit = e => {
    e.preventDefault();
    if (onNewMessage && newMessage) {
      onNewMessage(newMessage)
    }
    setNewMessage('');
  }
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(scrollToBottom, [messages]);

  const handleInputChange = e => setNewMessage(e.target.value)

  return (
    <div className="chats">
      <div className="chat-list">
        {getConversations(messages)}
        <div ref={messagesEndRef} />
      </div>
      <form
        className="new-message"
        onSubmit={handleSubmit}
      >
        <input
          value={newMessage}
          placeholder="Scrivi un messaggio"
          onChange={handleInputChange}
          className="new-message-input"
        />
        <IconButton onClick={handleSubmit} aria-label="delete" className={classes.margin}>
          <SendTwoToneIcon fontSize="large" style={{ color: 'white' }} />
        </IconButton>
      </form>
    </div>
  );

}

ChatBubble.propTypes = {
  messages: PropTypes.array.isRequired,
  onNewMessage: PropTypes.func.isRequired,
};

export default ChatBubble;
