import React, { createContext } from 'react';
import { useW3WReducer } from '../reducers/useW3WReducer';
import { REFRESH_W3W } from '../actions';

const W3WContext = createContext();

const W3WProvider = (props) => {
    const [state, dispatch] = useW3WReducer();
    const { w3wAddress, w3wId } = state;

    const refresh = (w3wAddress, w3wId) => dispatch({ type: REFRESH_W3W, payload: { w3wAddress, w3wId } });

    const providerValue = {
        w3wAddress,
        w3wId,
        refresh,
    };

    return (
        <W3WContext.Provider value={providerValue}>
            {props.children}
        </W3WContext.Provider>
    )
};

export { W3WContext, W3WProvider };