export const CAPTURE = 'CAPTURE';
export const RELEASE = 'RELEASE';
export const ADD_OFFER = 'ADD_OFFER';
export const ADD_OFFERS = 'ADD_OFFERS';

export const SIGNIN = 'SIGNIN';
export const SIGNOUT = 'SIGNOUT';
export const SIGNUP = 'SIGNUP';
export const UPDATE_USER = 'UPDATE_USER';

export const RELOGIN = 'RELOGIN';
export const RESULT_SIGNIN_SIGNUP = 'RESULT_SIGNIN_SIGNUP';
export const RESULT_FORGOT = 'RESULT_FORGOT';
export const RESULT_RESET = 'RESULT_RESET';

export const GET_CURRENT_OFFERS = 'GET_CURRENT_OFFERS';
export const SEARCH_CURRENT_OFFERS = 'SEARCH_CURRENT_OFFERS';
export const SELECT_OFFER = 'SELECT_OFFER';
export const SET_CURRENT_POSITION = 'SET_CURRENT_POSITION';
export const DELETE_OFFER = 'DELETE_OFFER';
export const GET_OFFER = 'GET_OFFER';
export const GET_TAGS = 'GET_TAGS';
export const SAVE_OFFER = 'SAVE_OFFER';
export const TOGGLE_OFFER = 'TOGGLE_OFFER';
export const CREATE_OFFER = 'CREATE_OFFER';
export const RESULT_OFFER = 'RESULT_OFFER';
export const REFRESH_W3W = 'REFRESH_W3W';
export const REFRESH_OFFER_DETAILS = 'REFRESH_OFFER_DETAILS';
export const HIDE_OFFER_DETAILS = 'HIDE_OFFER_DETAILS';
export const GET_MY_OFFERS = 'GET_MY_OFFERS';
export const ADD_MY_OFFER = 'ADD_MY_OFFER';
export const ADD_MY_ENGAGEMENT = 'ADD_MY_ENGAGEMENT';
export const GET_MY_ENGAGEMENTS = 'GET_MY_ENGAGEMENTS';
export const ADD_MY_CHAT = 'ADD_MY_CHAT';
export const GET_MY_CHATS = 'GET_MY_CHATS';
export const REFRESH_CURRENT_CHAT_INFO = 'REFRESH_CURRENT_CHAT_INFO';
export const REFRESH_CURRENT_CHAT_INFO_AND_MESSAGES = 'REFRESH_CURRENT_CHAT_INFO_AND_MESSAGES';
export const REFRESH_CURRENT_CHAT_MESSAGES = 'REFRESH_CURRENT_CHAT_MESSAGES';
export const HIDE_CURRENT_CHAT = 'HIDE_CURRENT_CHAT';
export const SHOW_CURRENT_CHAT = 'SHOW_CURRENT_CHAT';
export const DELETE_CURRENT_CHAT = 'DELETE_CURRENT_CHAT';
export const REMOVE_NOTIFICATIONS = 'REMOVE_NOTIFICATIONS';
export  const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const FETCH_INIT = 'FETCH_INIT';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_FAILURE = 'FETCH_FAILURE';
export const ADD_PREFERRED_OFFER = 'ADD_PREFERRED_OFFER';
export const DELETE_PREFERRED_OFFER = 'DELETE_PREFERRED_OFFER';
export const SEARCH_PREFERRED_OFFERS = 'SEARCH_PREFERRED_OFFERS';
export const RESULT_PREFERRED_OFFER = 'RESULT_PREFERRED_OFFER';
