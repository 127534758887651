import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ChatIcon from '@material-ui/icons/Chat';
import StoreIcon from '@material-ui/icons/Store';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { AccountChats, AccountOffers, AccountEngagements, AccountDetails, AccountPreferreds} from './components';
import {MyEngagementsProvider} from '../../common/providers/MyEngagementsContext';
import {AccountProvider} from '../../common/providers/AccountContext';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: theme.palette.white,
  },
  divChips: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`scrollable-force-tab-${index}`}
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}



const AccountDialog = (props) => {
  const classes = useStyles();
  const { title, isAccountDialogOpened, accountDialogClosedHandler, defaultTab } = props;
  const [value, setValue] = React.useState(defaultTab);
  const [open, setOpen] = React.useState(isAccountDialogOpened);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const handleClose = () => {
    setOpen(false);
    accountDialogClosedHandler();
  };

  useEffect(() => {
    setOpen(isAccountDialogOpened);
  }, [isAccountDialogOpened])

  useEffect(() => {
    setValue(defaultTab);
  }, [defaultTab]);
  
  return (
    <Dialog fullScreen onClose={handleClose} open={open}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton aria-label="close" color="inherit" edge="start" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6">
            {title}
          </Typography>
          <Typography className={classes.title}>
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <AppBar color="default" position="static">
          <Tabs
            aria-label="scrollable force tabs example"
            indicatorColor="primary"
            onChange={handleChange}
            scrollButtons="on"
            textColor="primary"
            value={value}
            variant="scrollable"
          >
            <Tab icon={<ChatIcon />} label="Chat" {...a11yProps(0)} />
            <Tab icon={<FavoriteIcon />} label="Preferiti" {...a11yProps(3)} />
            <Tab icon={<StoreIcon />} label="Mie Offerte" {...a11yProps(1)} />
            {/* <Tab icon={<WorkOutlineIcon />} label="Miei Lavori" {...a11yProps(2)} />  */}
            <Tab icon={<PermIdentityIcon />} label="Dati utente" {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <TabPanel index={0} value={value}>
            <AccountChats />
        </TabPanel>
        <TabPanel index={1} value={value}>
        <AccountPreferreds />
        </TabPanel>
        <TabPanel index={2} value={value}>
            <AccountOffers />
        </TabPanel>
       {/*  <TabPanel index={3} value={value}>
        <MyEngagementsProvider>
            <AccountEngagements />
          </MyEngagementsProvider>
        </TabPanel>
         */}
        <TabPanel index={3} value={value}>
          <AccountProvider>
            <AccountDetails/>
          </AccountProvider>
        </TabPanel>
      </div>
    </Dialog>
  );
}

export default AccountDialog;