import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import {
  createSquareAndInfoWindowW3w,
  updateW3wInfoWindowContent,
  removeW3wSquare
} from 'helpers';
import { W3WContext } from '../../common/providers/W3wContext';
import { OfferEditSaveContext } from '../../common/providers/OfferEditSaveContext';

const useStyles = makeStyles(() => ({
  map: {
    width: '100%',
    height: '100%'
  }
}));

const mapService = { current: null };

const GoogleMaps = props => {
  const classes = useStyles();
  const [mapIsReady, setMapIsReady] = useState(false);
  const [currentCenter, setCurrentCenter] = useState({
    lat: props.initialCenter.lat,
    lng: props.initialCenter.lng
  });
  const w3WContext = useContext(W3WContext);
  const { refresh, w3wAddress, w3wId } = w3WContext;
  const offerEditSaveContext = useContext(OfferEditSaveContext);
  const { setOffer } = offerEditSaveContext;

  const loaded = useRef(false);
  const markerW3WService = useRef(null);

  const loadScript = (src, position, id) => {
    if (!position) {
      return;
    }
    const script = document.createElement('script');
    script.setAttribute('async', 'true');
    script.setAttribute('defer', 'true');
    script.setAttribute('id', id);
    script.src = src;
    script.addEventListener('load', () => {
      setMapIsReady(true);
    });
    position.appendChild(script);
  };

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps-script')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${props.apiKey}&libraries=places`,
        document.querySelector('head'),
        'google-maps-script'
      );
    }
    loaded.current = true;
  }

  const renderAllChildren = () =>
    React.Children.map(props.children, child => {
      return React.cloneElement(child, {
        map: mapService.current,
        google: window.google,
        currentCenter: currentCenter
      });
    });

  const createOrEditOffer = () => {
    const w3wWordsObj = markerW3WService.current['w3wInfo'] || { words: null };

    setOffer({
      position: {
        lat: markerW3WService.current.getPosition().lat(),
        lng: markerW3WService.current.getPosition().lng()
      },
      w3wData: { address: w3wWordsObj.words, idW: w3wWordsObj.words }
    });
  };

  const addW3WMarker = () => {
    markerW3WService.current = new window.google.maps.Marker({
      map: mapService.current,
      icon: {
        url: 'images/markers/markerAdd.svg',
        scaledSize: new window.google.maps.Size(42, 42)
      },
      draggable: false,
      animation: window.google.maps.Animation.DROP,
      position: new window.google.maps.LatLng(
        mapService.current.getCenter().lat(),
        mapService.current.getCenter().lng()
      )
    });
    markerW3WService.current.addListener('click', function() {
      createOrEditOffer();
    });
  };

  const refreshW3W = w3wWordsObj => {
    refresh(w3wWordsObj.words, w3wWordsObj.words);
    //re-set marker at the center of square
    if (!markerW3WService.current) {
      return;
    }
    let newLatLng = new window.google.maps.LatLng(
      w3wWordsObj.coordinates.lat,
      w3wWordsObj.coordinates.lng
    );
    markerW3WService.current.setPosition(newLatLng);
    markerW3WService.current['w3wInfo'] = w3wWordsObj;
  };

  const addW3WGrid = () => {
    // Add the what3words grid to the Google Map data layer once the desired zoom level is meet
    return null;
    // mapService.current.addListener('bounds_changed', function() {
    //   const zoom = mapService.current.getZoom();
    //   const loadFeatures = zoom > 17;

    //   if (loadFeatures) { // Zoom level is high enough
    //     let ne = mapService.current.getBounds().getNorthEast();
    //     let sw = mapService.current.getBounds().getSouthWest();

    //     // Call the what3words Grid API to obtain the grid squares within the current visble bounding box
    //     window.what3words.api
    //       .gridSectionGeoJson({
    //         southwest: {
    //           lat: sw.lat(), lng: sw.lng()
    //         },
    //         northeast: {
    //           lat: ne.lat(), lng: ne.lng()
    //         }
    //       }).then(function(data) {
    //         if (gridData !== undefined) {
    //           for (let i = 0; i < gridData.length; i++) {
    //             mapService.current.data.remove(gridData[i]);
    //           }
    //         }
    //         gridData = mapService.current.data.addGeoJson(data);
    //       }).catch(console.error);
    //   }

    //   // Set the grid display style
    //   mapService.current.data.setStyle({
    //     visible: loadFeatures,
    //     strokeColor: '#777',
    //     strokeWeight: 0.5,
    //   });

    //});
  };

  useEffect(() => {
    if (!mapService.current && window.google) {
      mapService.current = new window.google.maps.Map(
        document.getElementById('mapZap'),
        {
          center: currentCenter || {},
          mapTypeId: 'satellite',
          zoom: 19,
          mapTypeControlOptions: {
            style: window.google.maps.MapTypeControlStyle.VERTICAL_BAR,
            position: window.google.maps.ControlPosition.BOTTOM_CENTER
          }
        }
      );

      addW3WGrid();
      addW3WMarker();

      //assign listener for events
      mapService.current.addListener('dragend', () => {
        let latCenter = mapService.current.getCenter().lat();
        let lngCenter = mapService.current.getCenter().lng();
        //markerW3WService.current.setPosition(newLatLng);
        props.onDragEnd({ lat: latCenter, lng: lngCenter });
        //createSquareAndInfoWindowW3w({lat: latCenter, lng: latCenter}, mapService.current, markerW3WService.current);
      });

      mapService.current.addListener('dragstart', function() {
        removeW3wSquare();
      });

      mapService.current.addListener('center_changed', () => {
        let latCenter = mapService.current.getCenter().lat();
        let lngCenter = mapService.current.getCenter().lng();
        let newLatLng = new window.google.maps.LatLng(latCenter, lngCenter);
        markerW3WService.current.setPosition(newLatLng);
        updateW3wInfoWindowContent('__/__/__');
        props.onCenterChanged({ lat: latCenter, lng: latCenter });
      });

      /*  let x = mapService.current.getCenter().lat();
            let y = mapService.current.getCenter().lng();
            createSquareAndInfoWindowW3w({lat: x, lng: y}, mapService.current, markerW3WService.current); */
    }
    if (!mapService.current) {
      return undefined;
    }
  }, [mapIsReady]);

  useEffect(() => {
    if (mapService.current) {
      mapService.current.panTo(props.initialCenter);
      setCurrentCenter(props.initialCenter);
      let x = mapService.current.getCenter().lat();
      let y = mapService.current.getCenter().lng();
      createSquareAndInfoWindowW3w(
        { lat: x, lng: y },
        mapService.current,
        markerW3WService.current,
        refreshW3W
      );
    }
  }, [props.initialCenter]);

  return (
    <div className={classes.map} id="mapZap">
      {renderAllChildren()}
    </div>
  );
};

GoogleMaps.propTypes = {
  onCenterChanged: PropTypes.func,
  onDragEnd: PropTypes.func
};

export default GoogleMaps;
