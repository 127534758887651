/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Chip from '@material-ui/core/Chip';

import { CurrentOffersContext } from '../../common/providers/CurrentOffersContext';
import { OfferDetailsContext } from '../../common/providers/offerDetailsContext';
import { PreferredOffersContext } from '../../common/providers/PreferredOffersContext';
import { CurrentChatContext } from '../../common/providers/CurrentChatContext';
import { MyChatsContext } from '../../common/providers/MyChatsContext';
import {AccountContext} from '../../common/providers/AccountContext';

const moment = require('moment');

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  rowItem: {cursor: 'Pointer', },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end'
  },
  listCardHeader: {
    padding: '0.5em'
  },
  noActiveItem: {
    backgroundColor: '#ddd !important'
  }
}));

const validURL = str => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

const printOfferDescription = anOffer => {
  let offerPrintedString = '';
  offerPrintedString +=
    (anOffer.descriptionGive &&
      anOffer.descriptionGive.substring(0, 15) + '... ') ||
    '';
  offerPrintedString +=
    (anOffer.descriptionTake &&
      anOffer.descriptionTake.substring(0, 15) + '... ') ||
    '';
  return offerPrintedString;
};

const printOfferTags = anOffer => {
  let offerPrintedString = '';
  offerPrintedString +=
    (anOffer.tagsTake &&
      anOffer.tagsTake.map(tag => tag.name).join(', ') + ' ') ||
    '';
  if (
    anOffer.tagsTake &&
    anOffer.tagsTake.length > 0 &&
    anOffer.tagsGive &&
    anOffer.tagsGive.length > 0
  ) {
    offerPrintedString += ' - ';
  }
  offerPrintedString +=
    (anOffer.tagsGive && anOffer.tagsGive.map(tag => tag.name).join(', ')) ||
    '';
  return offerPrintedString;
};

const printOfferTagsChips = anOffer => {
  let offerPrintedTags = [];
  anOffer.tagsTake &&
    offerPrintedTags.push(
      anOffer.tagsTake.map(tag => <Chip size="small" label={tag.name} />)
    );
  anOffer.tagsGive &&
    offerPrintedTags.push(
      anOffer.tagsGive.map(tag => <Chip size="small" label={tag.name} />)
    );
  return offerPrintedTags;
};

const LatestProducts = props => {
  const { positionOffer, className, ...rest } = props;
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [chatterId, setChatterId] = useState('');
  const classes = useStyles();
  const {user} = useContext(AccountContext);
  const currentOffersContext = useContext(CurrentOffersContext);
  const { offers, reloadOffers, selectAnOffer, selectedOffer } = currentOffersContext;
  const offerDetailsContext = useContext(OfferDetailsContext);
  const { refreshOfferDetails } = offerDetailsContext;
  const preferredOffersContext = useContext(PreferredOffersContext);
  const {
    allPreferredOffers,
    savePreferredOffer,
    deletePreferredOffer
  } = preferredOffersContext;
  const { getCurrentChatInfo, createCurrentChat} = useContext(CurrentChatContext);
  const { chats, getMyChats } = useContext(MyChatsContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [showMoreOffersBtn, setShowMoreOffersBtn] = useState(true);
  const [selectedOfferId, setSelectedOfferId] = useState(null);
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedOfferId(id);
  };

  const checkIfOwnOffer = () => {
    if(!offers || !offers.list){
      return;
    }
    let offerToCheck = offers.list.find(offer => offer._id === selectedOfferId);
    if(!offerToCheck){
      return;
    }
    return offerToCheck.user.id === chatterId;
  }

  const handleClose = action => {
    setAnchorEl(null);
    if (action == 'details') {
      offers &&
        offers.list &&
        refreshOfferDetails(
          true,
          offers.list.find(offer => offer._id === selectedOfferId)
        );
    }
    if (action == 'chat') {
      let offerToChat = offers.list.find(offer => offer._id === selectedOfferId);
      if(checkIfOwnOffer()){
       alert('Non puoi aprire una chat per una tua offerta!');
       return;
    }
    handleSendChat(offerToChat)}
  };

  const handleRowClick = (event, coordinates, offer) => {
    if(coordinates){
      // eslint-disable-next-line no-undef
      if(event.currentTarget)
      reloadOffers({lat: coordinates[1] - 0.00002, lng: coordinates[0] - 0.00012 }, 20000);
      selectAnOffer(offer);
    }
  };

  const handlePreferredClick = (event, offerID) => {
    if(offerID){
      const favoriteOfferObj = checkIfFavorite(offerID);
      favoriteOfferObj ? deletePreferredOffer(favoriteOfferObj._id) : savePreferredOffer(offerID);
    }
  };

  const handleMoreOffers = () => {
    reloadOffers(positionOffer, 20000);
    setShowMoreOffersBtn(false);
  };

  const checkIfFavorite = offerID => {
    if(!allPreferredOffers){
      return;
    }
    const foundFavorite = allPreferredOffers.find(aPreferred => {
      return aPreferred.offer._id === offerID;
    });
    return foundFavorite;
  };

  const checkIfChatAlreadyExist = (offerID)  => {
    const existingChat = chats.list.find((chat) => {
      return chat.offer._id === offerID;
    })
    return existingChat;
  };

  const handleSendChat = (offer) => {
    //setOpen(false);
    const chat = checkIfChatAlreadyExist(offer._id);
    if(chat && chat._id){
      getCurrentChatInfo(chat);
      return;
    }
    
    const receiverId = offer.user.id;
    let newChatInfo = {
      offerID: offer._id,
      memberIDs: [chatterId, receiverId],
    };
    createCurrentChat(newChatInfo);
  };

  // We use useEffect to get all preferred offers.
  useEffect(() => {
    forceUpdate();
  }, [allPreferredOffers]);

  useEffect(() => {
    if(user && user.id){
      setChatterId(user.id);
    }
  },[user]);

  // We use useEffect to get current offers.
  useEffect(() => {
    if(offers && offers.list && offers.list.length){
      forceUpdate();
    }
  }, [offers]);
  

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        className={classes.listCardHeader}
        subtitle={`${offers && offers.list && offers.list.length} in total`}
        title="Ultime offerte"
      />
      <Divider />
      <Menu
        anchorEl={anchorEl}
        id="simple-menu"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}>
        <MenuItem onClick={() => handleClose('details')}>
          Vedi Dettagli
        </MenuItem>
        <MenuItem onClick={() => handleClose('chat')}>Invia Messaggio</MenuItem>
      </Menu>
      <CardContent className={classes.content}>
        <List>
          {offers &&
            offers.list &&
            offers.list.map((anOffer, i) => (
              <ListItem className={clsx({[classes.rowItem]: true, [classes.noActiveItem]: anOffer.status !== 'ACTIVE'} )} divider={i < offers.length - 1} key={anOffer._id} onClick={event =>handleRowClick(event,anOffer.position.coordinates, anOffer)}
              style={( selectedOffer && selectedOffer._id && selectedOffer._id === anOffer._id)? {backgroundColor: '#ededed'} : {backgroundColor: 'white'}}>
                <ListItemAvatar>
                  <img
                    alt="Photo"
                    className={classes.image}
                    src={
                      (anOffer.photos &&
                        anOffer.photos[0] &&
                        anOffer.photos[0].url &&
                        validURL(anOffer.photos[0].url)) ? anOffer.photos[0].url :
                        '/images/farm.png'
                    }
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={printOfferDescription(anOffer)}
                  secondary={printOfferTags(anOffer)}
                />
                <IconButton
                  edge="end"
                  onClick={event => handlePreferredClick(event, anOffer._id)}
                  size="small">
                  {checkIfFavorite(anOffer._id) ? (
                    <FavoriteIcon />
                  ) : (
                    <FavoriteBorderIcon />
                  )}
                </IconButton>

                <IconButton
                  id="moreOffer"
                  edge="end"
                  onClick={event => handleClick(event, anOffer._id)}
                  size="small">
                  <MoreVertIcon />
                </IconButton>
              </ListItem>
            ))}
        </List>
      </CardContent>
      <Divider />
      {showMoreOffersBtn && (
        <CardActions className={classes.actions}>
          <Button
            color="primary"
            onClick={handleMoreOffers}
            size="small"
            variant="text">
            Vedi tutte
            <ArrowRightIcon />
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

LatestProducts.propTypes = {
  className: PropTypes.string,
  positionOffer: PropTypes.any
};

export default LatestProducts;
