import React, { createContext, useEffect } from 'react';
import { useMyEngagementsReducer } from '../reducers/useMyEngagementsReducer';
import { ADD_MY_ENGAGEMENT, GET_MY_ENGAGEMENTS, FETCH_INIT, FETCH_SUCCESS, FETCH_FAILURE } from '../actions';

const MyEngagementsContext = createContext();


const MyEngagementsProvider = (props) => {
  const [state, dispatch] = useMyEngagementsReducer();
  const { engagements, isFetching } = state;

  const addMyEngagementDispatch = (engagement) => dispatch({ type: ADD_MY_ENGAGEMENT, payload: engagement });
  const getMyEngagementsDispatch = (engagements) => dispatch({ type: GET_MY_ENGAGEMENTS, payload: engagements });
  const fetchInitDispatch = () => dispatch({ type: FETCH_INIT });
  //const fetchSuccessDispatch = () => dispatch({ type: FETCH_SUCCESS });
  const fetchFailureDispatch = () => dispatch({ type: FETCH_FAILURE });

  const fetchGetMyEngagements = async () => {
    fetchInitDispatch();
    try { const engagementsData = await fetch('http://www.mocky.io/v2/5ecfb83a320000a58ee3d4ed');
      if (!engagementsData.ok) {
        throw 'Error fetching engagements';
      }
      const data = await engagementsData.json();
      const myEngagements = { list: data };
      getMyEngagementsDispatch(myEngagements);
    }
    catch(error) {
      // Error handling here!
      fetchFailureDispatch();
      return;
    }
  }

  const fetchRemoveMyEngagements = async (id) => {
    fetchInitDispatch();
    try { const engagementsData = await fetch('http://www.mocky.io/v2/5ecfb83a320000a58ee3d4ed');
      if (!engagementsData.ok) {
        throw 'Error fetching remove engagement';
      }
      const dataRemoved = engagements.list.filter(anEngagement=> anEngagement.id !== id)
      const myEngagements = { list: dataRemoved };
      getMyEngagementsDispatch(myEngagements);
    }
    catch(error) {
      // Error handling here!
      fetchFailureDispatch();
      return;
    }

  }

  const pushMyEngagement = async (engagement) => {
    addMyEngagementDispatch(engagement);
  }

  const addMyEngagement = (engagement) => {
    // send the engagement as a post call   
    pushMyEngagement(engagement);
  }

  const removeMyEngagement = (id) => {
    // send the engagement as a post call   
    fetchRemoveMyEngagements(id);
  }

  // We use useEffect to make API calls.
  useEffect(() => {
    fetchGetMyEngagements();
  }, []);


  const getMyEngagements = () => {
    fetchGetMyEngagements();
  }

  const providerValue = {
    engagements,
    isFetching,
    addMyEngagement,
    removeMyEngagement,
    getMyEngagements
  }

  return (<MyEngagementsContext.Provider value={providerValue}>
    {props.children}
  </MyEngagementsContext.Provider>)

}

export { MyEngagementsContext, MyEngagementsProvider };
