/* eslint-disable react/no-multi-comp */
/* eslint-disable no-unreachable */
import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Snackbar,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DropzoneArea } from 'material-ui-dropzone';
import request from 'superagent';
import uuid from 'uuid/v1';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';

import {AccountContext} from '../../../../common/providers/AccountContext';

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'è obbligatorio' },
    length: {
      maximum: 32
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'è obbligatorio' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: false,
    length: {
      maximum: 128,
      minimum: 6
    }
  },
  passwordConfirm: {
    equality: 'password',
    length: {
      maximum: 128,
      minimum: 6
    }
  }

};

const useStyles = makeStyles(() => ({
  root: {},
  photoProfile: {margin: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden'},
  dropZoneAccount: {minHeight: 'auto' }
}));

function CircularProgressWithLabel(props) {
  return (
    <Box
      display="inline-flex"
      position="relative"
    >
      <CircularProgress
        variant="static"
        {...props}
      />
      <Box
        alignItems="center"
        bottom={0}
        display="flex"
        justifyContent="center"
        left={0}
        position="absolute"
        right={0}
        top={0}
      >
        <Typography
          color="textSecondary"
          component="div"
          variant="caption"
        >{`${Math.round(
            props.value,
          )}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and static variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};


const AccountDetails = (props) => {
  const { className, client, ...rest } = props;

  const classes = useStyles();
  const [progress, setProgress] = useState(0);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    isInitialData: false
  });
  const [detailsMessage, setDetailsMessage] = React.useState({ open: false, message: '' });
  const {isAuthenticated, user, updateAccount, removeAccount, removePhoto, checkIfAuthenticated} = useContext(AccountContext);

  const onPhotoUploadProgress = (id, fileName, progress) =>  {
    console.log(progress.percent);
    setProgress(progress.percent);
  };
  
  const uploadToRemote = async (files) => {
    // send to remote
    const url = `https://api.cloudinary.com/v1_1/${
      'inventu'
    }/upload`;
    const title = 'this.titleEl.value';
  
    let uploadedFile = null;
  
    for (let file of files) {
      const photoId = uuid();
      const fileName = file.name;
      const response = await request.post(url)
        .field('upload_preset', 'bpc3izlw')
        .field('file', file)
        .field('multiple', true)
        .field('tags', title ? `offer,${title}` : 'zappami')
        .field('context', title ? `photo=${title}` : '')
        .on('progress', (progress) => onPhotoUploadProgress(photoId, file.name, progress));
      //onPhotoUploaded(photoId, fileName, response);
      if(response.body){
        const url = response.body.secure_url || null;
        const publicID = response.body.public_id || null;
        uploadedFile = {name: fileName, description: '', url, publicID};
      }
    }
    return uploadedFile;
  }
  
  const eraseToRemote = async (photo) => {
  
    // send to remote
    const url = `https://api.cloudinary.com/v1_1/${
      'inventu'
    }/upload/zappami`;
  
    try {
      const response = await request.delete(url)
        .field('upload_preset', 'bpc3izlw')
        .field('public_ids', photo.publicID)
        .set('Access-Control-Allow-Headers', 'Content-Type, X-Auth-Token, Origin');
      //onPhotoUploaded(photoId, fileName, response);
      if(response.deleted){
        return true;
      }
    } catch (error) {
      console.log(error);
      return true;
    }
    return false;
  }


  useEffect(() => {
    let errors = validate(formState.values, schema);
    if(formState.values.password){
      if(!formState.values.passwordConfirm){
        let constraints = {
          passwordConfirm: {
            equality: 'password'
          }
        };
        errors = {...errors, ...validate({password: formState.values.password , passwordConfirm: ''}, constraints)};
      }
    }
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  useEffect(() => {
    if ( user ) {

      setFormState(formState => ({
        ...formState,
        values: user,
        isInitialData: true
      }));
      return;
    }
    if(!checkIfAuthenticated()){
      window.location.href = '/sign-in';
    }
  }, [user]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };


  const handleSubmit = event => {
    event.preventDefault();
    let errors = validate(formState.values, schema);
    if(formState.values.password){
      if(!formState.values.passwordConfirm){
        errors =  validate({password: formState.values.password, passwordConfirm: ''},  {passwordConfirm: {
          equality: 'password',
        }} );

        setFormState(formState => ({
          ...formState,
          isValid: errors ? false : true,
          errors: errors || {}
        }));
        return;
      }
    }

    updateAccount(formState.values);
    setDetailsMessage({ open: true, message: 'Account aggiornato con successo!' });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setDetailsMessage({ open: false, message: '' });
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
   

  const handleChangePhoto = async (files) => {
    if(files && files.length > 0){
      const uploadedPhotosToRemote =  await uploadToRemote(files);
      if (!uploadedPhotosToRemote){
        return;
      }
      const userPhotoData = {id: formState.values.id, profileImage: uploadedPhotosToRemote};
      updateAccount(userPhotoData);
      setProgress(0);

      /*       updatedList = updatedList.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.name === thing.name && t.path === thing.path
        ))
      ) */
    }

  }

  const handleDeletePhoto = async (photo) => {
    if(photo){
      const resultEraseToRemote =  await eraseToRemote(photo);
      if (!resultEraseToRemote){
        return;
      }
      removePhoto(formState.values.id);
    }
  }

  const handleDeleteAccount = async () => {

    const r = window.confirm('Vuoi cancellare il tuo account?');
    if (r === true) {
      removeAccount(formState.values.id);
    } 
  }
  

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Snackbar
        action={
          <React.Fragment>
            <Button
              color="secondary"
              onClick={handleClose}
              size="small"
            />
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={handleClose}
              size="small"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={6000}
        message={detailsMessage.message}
        onClose={handleClose}
        open={detailsMessage.open}
      />
      <form
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <CardHeader
          subheader="Puoi editare le tue informazioni"
          title="Dati utente"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('name')}
                fullWidth
                helperText={
                  hasError('name') ? formState.errors.name[0] : null
                }
                label="Name"
                name="name"
                onChange={handleChange}
                type="text"
                value={formState.values && formState.values.name || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('email')}
                fullWidth
                helperText={
                  hasError('email') ? formState.errors.email[0] : null
                }
                label="Email address"
                name="email"
                onChange={handleChange}
                type="text"
                value={formState.values && formState.values.email || ''}
                variant="outlined"
              />
            </Grid>
        
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                autocomplete="new-password"
                className={classes.textField}
                error={hasError('password')}
                fullWidth
                helperText={
                  hasError('password') ? formState.errors.password[0] : null
                }
                label="Password"
                name="password"
                onChange={handleChange}
                placeholder="*************"
                type="text"
                type="password"
                value={formState.values && formState.values.password || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                autocomplete="new-password"
                className={classes.textField}
                error={hasError('passwordConfirm')}
                fullWidth
                helperText={
                  hasError('passwordConfirm') ? formState.errors.passwordConfirm[0] : null
                }
                label="Conferma Password"
                name="passwordConfirm"
                onChange={handleChange}
                placeholder="*************"
                type="text"
                type="password"
                value={formState.values && formState.values.passwordConfirm || ''}
                variant="outlined"
              />
            </Grid>
    
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            disabled={!formState.isValid}
            type="submit"
            variant="contained"
          >
            Salva
          </Button>
        </CardActions>
      </form>

      <CardHeader
        subheader="Puoi caricare/cambiare o cancellare la tua foto."
        title="Foto utente"
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={2}
            xs={12}
          > 

            <div className={classes.photoProfile}>
              <GridList
                cellHeight={180}
                className={classes.gridList}
              >
                <GridListTile
                  cols={2}
                  style={{ width: '100%' }}
                >
                  <img
                    alt={''} 
                    src={formState.values && formState.values.profileImage && formState.values.profileImage.url || '/images/avatars/avatar_11.png'}
                  />
                  <GridListTileBar
                    actionIcon={
                      <IconButton
                        aria-label={`info about ${''}`}
                        className={classes.icon}
                        onClick={() => handleDeletePhoto(formState.values.profileImage)}
                      >
                        <DeleteIcon color={'secondary'}/>
                      </IconButton>
                    }
                    subtitle={<span>{''}</span>}
                    title={''}
                  />
                </GridListTile>
              </GridList>
              { progress > 0 && <CircularProgressWithLabel value={progress} /> }
            </div>

          </Grid>
          <Grid
            item
            md={2}
            xs={12}
          > <DropzoneArea
              showAlerts={false}
              showPreviewsInDropzone={false}
              acceptedFiles={['image/*']}
              dropzoneText={'Carica immagine'}
              onAdd={(fileObjs) => console.log('Added Files:', fileObjs)}
              onChange={handleChangePhoto}
              dropzoneClass={classes.dropZoneAccount}
            />
          </Grid>
          <Grid
            item
            md={8}
            xs={12}
          ><div className={classes.photoProfile}>
              <Button
                color="secondary"
                onClick={handleDeleteAccount}
                variant="contained"
              >
          CANCELLATI DA ZAPPAMI
              </Button>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
