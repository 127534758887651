import { useReducer } from 'react';
import { CREATE_OFFER, SEARCH_CURRENT_OFFERS, SET_CURRENT_POSITION, SELECT_OFFER } from '../actions';

const searchOffers = (offers, state) => ({
  offers: offers
});

const initialState = {
  offers: [],
  currentPosition: { lat: 40.7669, lng: 9.5213 },
  selectedOffer: null
};

const currentOffersReducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_POSITION:
        return {
            ...state,
            currentPosition: action.payload
        }
    case CREATE_OFFER:
      return action.payload;
    case SEARCH_CURRENT_OFFERS:
      return {
        ...state,
        offers: action.payload
      };
      case SELECT_OFFER:
        return {
          ...state,
          selectedOffer: action.payload
        };

  }

}

export const useCurrentOffersReducer = () =>
  useReducer(currentOffersReducer, initialState);
