/* eslint-disable react/no-multi-comp */
import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, Typography } from '@material-ui/core';
import {GoogleMapsAutocomplete, LatestProducts, AddEditOffer } from 'components';
import { CurrentOffersContext } from '../../../../common/providers/CurrentOffersContext';
import { W3WContext } from '../../../../common/providers/W3wContext'

const drawerWidth = 270;
const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: '80vh',
      width: '25vw'
    },
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  drawerFull: {
    width: '100%'
  },
  rootFull: {
    height: '45vh'
  },
  listItemDesktop: {
    height: '20vh',
    overflowY: 'scroll !important',
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  quoteText: {
    color: '#AD1010',
    fontWeight: 700,
    fontSize: '4vw',
    fontFamily: 'Monospace',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.2em'
    },
  },
  addOfferButton: {
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      paddingTop: '1em',
      paddingBottom:'1em'
    },
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, anchor, ...rest } = props;

  const classes = useStyles();
  const currentOffersContext = useContext(CurrentOffersContext);
  const { offers, reloadOffers } = currentOffersContext;
  const w3wContext = useContext(W3WContext);
  const { w3wAddress, w3wId} = w3wContext;

  const selectedPlace = (position) => {
    //setShowItemBottom(true);
    reloadOffers(position);
  }


  const list = () => (
    <div
      {...rest}
      className={clsx(classes.root, className, anchor === 'bottom' && classes.rootFull)}
    >
      <GoogleMapsAutocomplete onSelectPlace={selectedPlace} />
      <Divider className={classes.divider} />
      <Typography
        className={classes.quoteText}
        variant="h6"
      >/// {w3wAddress}
      </Typography>
      <Divider className={classes.divider} />
      { (offers && offers.position) && <div className={classes.addOfferButton}>      
        <AddEditOffer
          positionOffer={offers.position}
          w3wAddress={w3wAddress}
          w3wId={w3wId}
        /></div>}
      <Divider className={classes.divider} />
      <LatestProducts positionOffer={offers.position} className={classes.listItemDesktop} />
    </div>);


  return (
    <Drawer
      anchor={anchor}
      classes={{ paper: anchor === 'bottom' ? classes.drawerFull  : classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      {list()}
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
