import React, { createContext, useEffect } from 'react';
import { usePreferredOffersReducer } from '../reducers/usePreferredOffersReducer';
import {
  ADD_PREFERRED_OFFER,
  DELETE_PREFERRED_OFFER,
  SEARCH_PREFERRED_OFFERS,
  RESULT_PREFERRED_OFFER
} from '../actions';
import {
  PREFERRED_OFFERS_ADD_MUTATION,
  PREFERRED_OFFERS_DELETE_MUTATION,
  PREFERRED_OFFERS_SEARCH_QUERY
} from '../../graphql/queries';
import { useLazyQuery, useMutation } from '@apollo/client';

const PreferredOffersContext = createContext();

const PreferredOffersProvider = props => {
  const [state, dispatch] = usePreferredOffersReducer();
  const { allPreferredOffers, preferredSaveData, preferredSaveResult } = state;

  const searchPreferredOffersDispatch = preferredOfferData =>
    dispatch({ type: SEARCH_PREFERRED_OFFERS, payload: preferredOfferData });
  const savePreferredOfferDispatch = preferredOfferData =>
    dispatch({ type: ADD_PREFERRED_OFFER, payload: preferredOfferData });
  const deletePreferredOfferDispatch = preferredOfferData =>
    dispatch({ type: DELETE_PREFERRED_OFFER, payload: preferredOfferData });
  const resultPreferredOfferDispatch = resultPreferredOfferMessage =>
    dispatch({
      type: RESULT_PREFERRED_OFFER,
      payload: resultPreferredOfferMessage
    });

  /**
   * Callback for offerAdd mutation.
   * @param {Object} data - The data offer.
   */
  const onCompletePreferredOfferAdd = async data => {
    if (!data.addPreferredOffer) {
      resultPreferredOfferDispatch({
        type: 'error',
        message: 'Errore nel salvataggio'
      });
      return;
    }
    // resultPreferredOfferDispatch({
    //   type: 'success',
    //   message: 'Aggiunta ai preferiti con successo!'
    // });
    //  savePreferredOfferDispatch(data.addPreferredOffer);
    refetch();
    //refreshPreferredOffers();
  };

  /**
   * Callback for deletePreferredOffer mutation.
   * @param {Object} data - The data offer.
   */
  const onCompletePreferredOfferDelete = async data => {
    if (!data.deletePreferredOffer) {
      resultPreferredOfferDispatch({
        type: 'error',
        message: 'Errore nella rimozione del preferito'
      });
      return;
    }
    // resultPreferredOfferDispatch({
    //   type: 'success',
    //   message: 'Offerta preferita eliminato con successo!'
    // });
    refetch();
    //refreshPreferredOffers();
  };

  /**
   * Callback for preferredOffer search error.
   * @param {Object} data - The error.
   */
  const onErrorSearchPreferredOffers = error => {
    resultPreferredOfferDispatch({ type: 'error', message: error.message });
  };

  /**
   * Callback for preferredOffer add mutation error.
   * @param {Object} data - The error.
   */
  const onErrorPreferredOfferAdd = error => {
    resultPreferredOfferDispatch({ type: 'error', message: error.message });
  };

  /**
   * Callback for preferredOffer delete mutation error.
   * @param {Object} data - The error.
   */
  const onErrorPreferredOfferDelete = error => {
    resultPreferredOfferDispatch({ type: 'error', message: error.message });
  };

  /**
   * Callback for preferred offers search query.
   * @param {Object} data - The preferred data offer.
   */
  const onCompleteSearchPreferredOffers = preferredData => {
    // if (!preferredData.preferredOffers || !preferredData.preferredOffers[0] || !preferredData.preferredOffers[0].allData) return;
    // searchPreferredOffersDispatch(preferredData.preferredOffers[0].allData);
  };

  const [addPreferredOffer] = useMutation(PREFERRED_OFFERS_ADD_MUTATION, {
    onCompleted: onCompletePreferredOfferAdd,
    onError: onErrorPreferredOfferAdd
  });

  const [deleteAPreferredOffer] = useMutation(
    PREFERRED_OFFERS_DELETE_MUTATION,
    {
      onCompleted: onCompletePreferredOfferDelete,
      onError: onErrorPreferredOfferDelete
    }
  );

  const [searchAllPreferredOffers, { data, refetch }] = useLazyQuery(
    PREFERRED_OFFERS_SEARCH_QUERY,
    {
      onCompleted: onCompleteSearchPreferredOffers,
      onError: onErrorSearchPreferredOffers
    }
  );

  const fetchSearchPreferreOffers = async id => {
    await searchAllPreferredOffers({
      variables: {
        filter: { userID: id }
      }
    });
  };

  const fetchSavePreferredOffer = async preferredOfferObj => {
    if (preferredOfferObj.userID && preferredOfferObj.offerID) {
      addPreferredOffer({ variables: preferredOfferObj });
    }
    return;
  };

  const fetchDeletePreferredOffer = async preferredOfferID => {
    if (preferredOfferID) {
      deleteAPreferredOffer({ variables: { _id: preferredOfferID } });
    }
    return;
  };
  const searchPreferredOffers = id => {
    // get the offer by a call
    fetchSearchPreferreOffers(id);
  };

  const savePreferredOffer = offerID => {
    // save the offer as a post call
    const user = JSON.parse(localStorage.getItem('user'));
    const userID = user.id;
    const preferredOfferObj = { userID, offerID };
    fetchSavePreferredOffer(preferredOfferObj);
  };

  const deletePreferredOffer = preferredID => {
    // delete the offer as a post call
    fetchDeletePreferredOffer(preferredID);
  };

  const refreshPreferredOffers = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if(!user)
    return;
    const userID = user.id;
    if (userID) {
      fetchSearchPreferreOffers(userID);
    }
  };
  // We use useEffect to get all preferreds.
  useEffect(() => {
    refreshPreferredOffers();
  }, []);

  // We use useEffect to check new offers updates.
  useEffect(() => {
    if (data) {
      searchPreferredOffersDispatch(data.preferredOffers[0].allData);
    }
  }, [data]);

  const providerValue = {
    allPreferredOffers,
    preferredSaveData,
    preferredSaveResult,
    searchPreferredOffers,
    savePreferredOffer,
    deletePreferredOffer,
    refreshPreferredOffers
  };

  return (
    <PreferredOffersContext.Provider value={providerValue}>
      {props.children}
    </PreferredOffersContext.Provider>
  );
};

export { PreferredOffersContext, PreferredOffersProvider };
