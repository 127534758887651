import React, { useState, useEffect, useContext } from 'react';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { NotificationsContext } from '../../common/providers/NotificationsContext';
import { CurrentChatContext } from '../../common/providers/CurrentChatContext';

const SSEevents = props => {
  const [data, updateData] = useState(null);
  const { url, token } = props;
  const { addNotifications, notifications } = useContext(NotificationsContext);
  const {
    isCurrentChatOpened,
    currentChatInfo,
    getCurrentChatInfo
  }
    = useContext(CurrentChatContext);

  useEffect(() => {
    const source = new EventSourcePolyfill(url, {
      headers: { Authorization: `Bearer ${token}` || null }
    });

    source.onmessage = event => {
      updateData(JSON.parse(event.data));
    };

    source.onerror = event => {
      console.log('*******SSE CONNECTION CLOSED *****: ' + event);
    };

    source.onpen = event => {
      console.log('*******SSE CONNECTION OPENED *****: ' + event);
    };
  }, []);

  useEffect(() => {
    console.log(data);
    if (!data) {
      return;
    }
    let unreadChats =
      notifications.chats && notifications.chats.unread > 0
        ? notifications.chats.unread
        : 0;
    let unreadEngagements =
      notifications.engagements && notifications.engagements.unread > 0
        ? notifications.engagements.unread
        : 0;
    let chatIds = notifications.chats && notifications.chats.ids && notifications.chats.ids.length > 0
      ? notifications.chats.ids
      : [];
    let engagementIds = notifications.engagements && notifications.engagements.ids && notifications.engagements.ids.length > 0
      ? notifications.engagements.ids
      : [];
    let allNotifications = null;

    if (Array.isArray(data)) {
      data.forEach((value, index) => {
        if (value.type === 'message') {
          if(value.data.chatID && !chatIds.find(elem => elem === value.data.chatID)){
            unreadChats++;
            chatIds.push(value.data.chatID);
          }

        }
        if (value.type === 'engagements') {
          if(value.data._ID && !engagementIds.find(elem => elem === value.data._ID)){
            unreadEngagements++;
            engagementIds.push(value.data._ID);
          }
        }
      });
    } else {
      if (data.type === 'message') {
        if(data.data.chatID && isCurrentChatOpened && currentChatInfo && currentChatInfo._id === data.data.chatID){
          getCurrentChatInfo(currentChatInfo); 
          return;
        } 
      
        if(data.data.chatID && !chatIds.find(elem => elem === data.data.chatID)){
          unreadChats++;
          chatIds.push(data.data.chatID);
        }  
      }

      if (data.type === 'engagements') {
        if(data.data._ID && !engagementIds.find(elem => elem === data.data._ID)){
          unreadEngagements++;
          engagementIds.push(data.data._ID);
        }
      }
    }
    allNotifications = {
      ...(unreadChats > 0 && {
        chats: {
          unread: unreadChats,
          ids: chatIds
        }
      }),
      ...(unreadEngagements > 0 && {
        engagements: {
          unread: unreadEngagements,
          ids: engagementIds
        }
      })
    };
    addNotifications(allNotifications);
  }, [data]);

  return '';
};

export default SSEevents;
