/* eslint-disable react/no-multi-comp */
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import VisibilityIcon from '@material-ui/icons/Visibility';
import { purple, blue } from '@material-ui/core/colors';
import Typewriter from 'typewriter-effect';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { GoogleMaps, GoogleMarker, OfferDetails, OfferEdit, Chat, SSEevents } from 'components';
import { CurrentOffersContext } from '../../common/providers/CurrentOffersContext';
import { OfferDetailsContext } from '../../common/providers/offerDetailsContext';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  dialogFull: {
    marginTop: '56px'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    minWidth: '100%',
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 700,
    fontSize: '1.2rem',
    fontFamily: 'Monospace'
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  or: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: theme.palette.white

  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  quoteTypeWriter: {
    backgroundColor: theme.palette.neutral,
    height: '30vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2em'
  },
  quoteVisit: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2em'
  },

  autocompleteHome: {
    marginTop: '2em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  map: {
    width: '100%',
    height: '100%',
  }
}));

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  },
}))(Button);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Home = props => {
  const { history } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [offerDetailsOpen, setOfferDetailsOpen] = useState(false);
  const [currentCenter, setCurrentCenter] = useState({ lat: 40.7669, lng: 9.5213 });
  const currentOffersContext = useContext(CurrentOffersContext);
  const { offers, reloadOffers, selectedOffer } = currentOffersContext;

  const offerDetailsContext = useContext(OfferDetailsContext);
  const { refreshOfferDetails } = offerDetailsContext;

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleSignIn = event => {
    event.preventDefault();
    history.push('/items/products');
  };

  const handleMarkerClick = (id) => {
    console.log("marker clicked: " + id);
    offers.list && refreshOfferDetails(true, offers.list.find(offer => offer._id === id));

    setOfferDetailsOpen(true);
  };

  const hideOfferDetails = () => {
    setOfferDetailsOpen(false);
  }

  const onDragEnd = position => {
    reloadOffers(position);
  }

  const onCenterChanged = position => {
    //setCurrentCenter(position);
  }

  // We use useEffect to get current offers.
  useEffect(() => {
    if(offers && offers.list && offers.list.length){
      forceUpdate();
    }
  }, [offers, selectedOffer]);

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        alignItems="center"
        direction="column"
        justify="space-between"
        container
      >
        <Dialog className={classes.dialogFull} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Grid item className={classes.quoteTypeWriter}>
                <Typewriter
                  style={{ minHeight: '150px' }}
                  options={{
                    strings: ['<h1><mark>Una piattaforma per condividere la tua terra</mark></h1>', '<h2><mark>Clicca sulla mappa e metti online la tua terra</mark></h2>'],
                    autoStart: true,
                    loop: true,
                    wrapperClassName: classes.quoteText
                  }}
                />
                <Typography
                  className={classes.quoteText}
                  variant="h1"
                >
                </Typography>
              </Grid>
              <Grid item className={classes.quoteVisit}>
                <ColorButton
                  color="secondary"
                  onClick={handleClose}
                  size="large"
                  variant="contained"
                >
                  <VisibilityIcon className={classes.socialIcon} />
                  Vai alla mappa
                </ColorButton>
              </Grid>
            </div>
          </div>
        </Dialog>
        <GoogleMaps onDragEnd={onDragEnd} onCenterChanged={onCenterChanged} apiKey='AIzaSyBlIV7ji5P3pHLE-mbG6z6TZ0DX75FHFvQ' initialCenter={offers.position || currentCenter}>
          {
            offers.list && offers.list.map((offer) => {
              const markerType = ( selectedOffer && selectedOffer._id && selectedOffer._id === offer._id) ? 'SELECTED' : 'SELL';
              return   <GoogleMarker idMarker={offer._id} position={{lng:offer.position.coordinates[0], lat:offer.position.coordinates[1]}} onClick={handleMarkerClick} type={markerType} status={offer.status} key={offer._id} />
            }
            )
          }

        </GoogleMaps>
        <OfferEdit/>
        <OfferDetails offerDetailsOpen={offerDetailsOpen} />
        <Chat />
        <SSEevents
          token={JSON.parse(localStorage.getItem('token'))}
          url={'https://zappami-push.azurewebsites.net/events'}
        />
      </Grid>
    </div>
  );
};

Home.propTypes = {
  history: PropTypes.object
};

export default withRouter(Home);
