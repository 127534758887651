import React, { createContext, useEffect } from 'react';
import { LOGIN_MUTATION, SIGNUP_MUTATION, FORGOT_MUTATION, RESET_MUTATION, USER_UPDATE_MUTATION, USER_DELETE_MUTATION, USER_PHOTO_REMOVE_MUTATION, } from '../../graphql/queries';
import { useAccountReducer } from '../reducers/useAccountReducer';
import { SIGNIN, SIGNOUT, SIGNUP, RELOGIN, RESULT_SIGNIN_SIGNUP, UPDATE_USER, RESULT_FORGOT, RESULT_RESET } from '../actions';
import { useMutation } from '@apollo/client';

const AccountContext = createContext();
/**
 * Context api provider for account purposes.
 * @param {Object} props - Context api provider properties.
 * @returns {Component} - The provider component.
 */
const AccountProvider = (props) => {
  const [state, dispatch] = useAccountReducer();
  const { isAuthenticated, user, token, signinSignupResult, forgotResult, resetResult } = state;
  const reloginAccountDispatch = () => dispatch({ type: RELOGIN }); 
  const loginAccountDispatch = (user, token) => dispatch({ type: SIGNIN, payload: { user, token } });
  const logoutAccountDispatch = () => dispatch({ type: SIGNOUT });
  const signupAccountDispatch = (user, token) => dispatch({ type: SIGNUP, payload: { user, token } });
  const resultForgetDispatch = resultForgetMessage =>
    dispatch({ type: RESULT_FORGOT, payload: resultForgetMessage });
const resultResetDispatch = resultResetMessage =>
    dispatch({ type: RESULT_RESET, payload: resultResetMessage });
  const resultSigninSignupDispatch = resultSigninSignupMessage =>
    dispatch({ type: RESULT_SIGNIN_SIGNUP, payload: resultSigninSignupMessage });
  const updateAccountDispatch = (user) => dispatch({ type: UPDATE_USER, payload: { user } });
  
  /**
 * Callback for login mutation.
 * @param {Object} data - The data signin.
 */
  const onCompleteSignin = (data) => {
    if (!data.login)
      return
    loginAccountDispatch(data.login.user, data.login.token);
  };

  /**
   * Callback for error signin mutation.
   * @param {Object} error - The error.
   */
  const onErrorSignin= error => {
    console.log(error);
    resultSigninSignupDispatch(null);
    resultSigninSignupDispatch(error.message || 'Errore, riprovare.');
  };

  /**
 * Callback for login mutation.
 * @param {Object} data - The data signin.
 */
  const onCompleteForgot = (data) => {
    if (!data.forgot)
      return;
    if(!data.forgot.id){
      resultSigninSignupDispatch(null);
      resultSigninSignupDispatch('Errore nel reset della password, riprovare.');
      return;
    }
    resultForgetDispatch('Congratulazioni! Riceverai una email per il reset password.')
  };

  /**
 * Callback for error signin mutation.
 * @param {Object} error - The error.
 */
  const onErrorForgot= error => {
    resultSigninSignupDispatch(null);
    resultSigninSignupDispatch(error.message || 'Errore, riprovare.');
  };
  
    /**
 * Callback for reset mutation.
 * @param {Object} data - The data reset.
 */
const onCompleteReset = (data) => {
  if (!data.reset)
    return;
  if(!data.reset.id){
    resultSigninSignupDispatch(null);
    resultSigninSignupDispatch('Errore nella creazione nuova password, riprovare.');
    return;
  }
  resultResetDispatch('Congratulazioni! La tua password è stata cambiata correttamente.')
};

/**
* Callback for error reset mutation.
* @param {Object} error - The error.
*/
const onErrorReset= error => {
  resultSigninSignupDispatch(null);
  resultSigninSignupDispatch(error.message || 'Errore, riprovare.');
};

  /**
 * Callback for signup mutation.
 * @param {Object} data - The data signup.
 */
  const onCompleteSignup = (data) => {
    if (!data.signup)
      return
    if(!data.signup.token){
      resultSigninSignupDispatch(null);
      resultSigninSignupDispatch('Errore, nella creazione nuovo account. Ti sei già registrato con questa mail? Oppure contattare l\'assistenza.');
      return;
    }
    signupAccountDispatch(data.signup.user, data.signup.token);
  };

  /**
   * Callback for error signup mutation.
   * @param {Object} error - The error.
   */
  const onErrorSignup = error => {
    console.log(error);
    resultSigninSignupDispatch(null);
    resultSigninSignupDispatch(error.message || 'Errore, riprovare.');
  };

  /**
 * Callback for update mutation.
 * @param {Object} data - The data update.
 */
  const onCompleteUpdate = (data) => {
    if (!data.updateUser)
      return;
    if(!data.updateUser.user || !data.updateUser.user.id){
      resultSigninSignupDispatch(null);
      resultSigninSignupDispatch('Errore, aggiornamento dati utente.');
      return;
    }
    updateAccountDispatch(data.updateUser.user);
  };

  /**
 * Callback for error update mutation.
 * @param {Object} error - The error.
 */
  const onErrorUpdate = error => {
    console.log(error);
    resultSigninSignupDispatch(null);
    resultSigninSignupDispatch(error.message || 'Errore, riprovare.');
  };

  /**
 * Callback for remove mutation.
 * @param {Object} data - The data remove returned.
 */
  const onCompleteRemove = (data) => {
    if (!data.deleteUser)
      return;
    if(!data.deleteUser || !data.deleteUser.id){
      resultSigninSignupDispatch(null);
      resultSigninSignupDispatch('Errore, eliminazione dati utente.');
      return;
    }
    logoutAccountDispatch();
  };

  /**
* Callback for error remove mutation.
* @param {Object} error - The error.
*/
  const onErrorRemove = error => {
    console.log(error);
    resultSigninSignupDispatch(null);
    resultSigninSignupDispatch(error.message || 'Errore, riprovare.');
  };

  /**
 * Callback for update mutation.
 * @param {Object} data - The data update.
 */
  const onCompletePhotoRemove = (data) => {
    if (!data.removePhoto)
      return;
    if(!data.removePhoto || !data.removePhoto.id){
      resultSigninSignupDispatch(null);
      resultSigninSignupDispatch('Errore cancellazione foto utente.');
      return;
    }
    updateAccountDispatch(data.removePhoto);
  };

  /**
* Callback for error update mutation.
* @param {Object} error - The error.
*/
  const onErrorPhotoRemove = error => {
    console.log(error);
    resultSigninSignupDispatch(null);
    resultSigninSignupDispatch(error.message || 'Errore, riprovare.');
  };
  
  const [signup] = useMutation(SIGNUP_MUTATION, { onCompleted: onCompleteSignup, onError: onErrorSignup });
  const [update] = useMutation(USER_UPDATE_MUTATION, { onCompleted: onCompleteUpdate, onError: onErrorUpdate });

  const [signin] = useMutation(LOGIN_MUTATION, { onCompleted: onCompleteSignin, onError: onErrorSignin });
  const [forgot] = useMutation(FORGOT_MUTATION, { onCompleted: onCompleteForgot, onError: onErrorForgot });
  const [reset] = useMutation(RESET_MUTATION, { onCompleted: onCompleteReset, onError: onErrorReset });

  const [remove] = useMutation(USER_DELETE_MUTATION, { onCompleted: onCompleteRemove, onError: onErrorRemove });

  const [userPhotoRemove] = useMutation(USER_PHOTO_REMOVE_MUTATION, { onCompleted: onCompletePhotoRemove, onError: onErrorPhotoRemove });


  /**
 * Fetch signin data with a graphql mutation.
 * @param {Object} userData - The user data to send for signin.
 */
  const fetchSignin = async (userData) => {
    signin({ variables: { email: userData.email, password: userData.password } });
  };

  /**
 * Fetch signup data with a graphql mutation.
 * @param {Object} userData - The user data to send for signup.
 */
  const fetchSignup = async (userData) => {
    signup({ variables: { email: userData.email, password: userData.password, name: userData.firstName } });
  };

  /**
 * Fetch update data with a graphql mutation.
 * @param {Object} userData - The user data to send for signup.
 */
  const fetchUpdate = async (userData) => {
    update({ variables: { id: userData.id, email: userData.email, password: userData.password, name: userData.name, profileImage: userData.profileImage } });
  };

  /**
 * Fetch account remove with a graphql mutation.
 * @param {Object} idUser - The user id account to remove.
 */
  const fetchRemove = async (idUser) => {
    remove({ variables: { id: idUser} });
  };

  /**
 * Fetch photo remove with a graphql mutation.
 * @param {Object} idUser - The user id photo to remove.
 */
  const fetchPhotoRemove = async (idUser) => {
    userPhotoRemove({ variables: { id: idUser} });
  };

  /**
 * Fetch forgot data with a graphql mutation.
 * @param {Object} email - The user email to send for pwd recovery.
 */
  const fetchForgot = async (email) => {
    forgot({ variables: email });
  };

    /**
 * Fetch forgot data with a graphql mutation.
 * @param {Object} email - The user email to send for pwd recovery.
 */
const fetchResetPwd = async (key, pwd) => {
  reset({ variables: {key, pwd} });
};


  /**
 * Call a fetch method to send signup data.
 * @param {Object} signupData - The user data for signup.
 */
  const signupAccount = (signupData) => {
    fetchSignup(signupData);
  };

  /**
 * Call a fetch method to send signup data.
 * @param {Object} signinData - The user data for signin.
 */
  const signinAccount = (signinData) => {
    fetchSignin(signinData);
  };

  /**
 * Call a fetch method to send update data.
 * @param {Object} updateData - The user data to update.
 */
  const updateAccount = (updateData) => {
    fetchUpdate(updateData);
  };

  /**
 * Call a fetch method to send remove account.
 * @param {Object} idUser - The user id account to remove.
 */
  const removeAccount = (idUser) => {
    fetchRemove(idUser);
  };

  /**
 * Call a fetch method to send remove photo data.
 * @param {Object} idUser - The user id photo to remove.
 */
  const removePhoto = (idUser) => {
    fetchPhotoRemove(idUser);
  };

  /**
 * Call a fetch method to send forgot data.
 * @param {Object} email - The email of forgot password.
 */
  const forgotPwd = (email) => {
    fetchForgot(email);
  };

    /**
 * Call a fetch method to send forgot data.
 * @param {Object} key - The key of forgot password.
 * @param {Object} pwd - The new password.
 */
const resetPwd = (key, pwd) => {
  fetchResetPwd(key, pwd);
};

  /**
 * Call a dispatch method to unset variables for signout.
 */
  const signoutAccount = () => {
    logoutAccountDispatch();
  };

  /**
 * Called once to re-login account.
 */
  useEffect(()=>{
    reloginAccountDispatch();
  }, []);

  const checkIfAuthenticated = () =>{
    const token = localStorage.getItem('token');
    return token? true : false;
  }

  const providerValue = {
    isAuthenticated,
    user,
    token,
    signupAccount,
    signinAccount,
    signoutAccount,
    checkIfAuthenticated,
    forgotPwd,
    resetPwd,
    updateAccount,
    removeAccount,
    removePhoto,
    signinSignupResult,
    forgotResult,
    resetResult
  };

  return (
    <AccountContext.Provider value={providerValue}>
      {props.children}
    </AccountContext.Provider>
  )
};

export { AccountContext, AccountProvider };