import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { ApolloClient, ApolloProvider, HttpLink, ApolloLink, InMemoryCache, concat  } from '@apollo/client';
import { cache } from './cache';
import { onError } from "@apollo/client/link/error";

const AUTH_TOKEN = 'token';
const token = JSON.parse(localStorage.getItem(AUTH_TOKEN));

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      if (message === "jwt expired") {
        // every 401/unauthorized error will be caught here and update the global local state
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem('user');
        window.location.href = '/';
/*         appCache.writeData({
          data: {
            authStatus: {
              __typename: 'authStatus',
              status: 'loggedOut',
            },
          },
        }); */

      }
    });
  }
});

const httpLink = new HttpLink({ uri: 'https://zappami-gateway.azurewebsites.net' });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      Authorization: `Bearer ${token}` || null,
    }
  });

  return forward(operation);
})

const link = ApolloLink.from([
  authMiddleware,
  errorLink,
  httpLink
]);

const client = new ApolloClient({
  cache,
  link
});

ReactDOM.render(<ApolloProvider client={client}><App /></ApolloProvider>, document.getElementById('root'));

serviceWorker.register({
  onUpdate: (reg) => {
    window.update = () => {
      try {
        reg.waiting.postMessage({ type: 'SKIP_WAITING' })
        window.location.reload()
      } catch (error) {
        console.warn('error', error)
      }
    }
  },
})
