import React, {useContext} from 'react';


let square = null;
let infowindow = null;
let infoWindowContent = null;
const createSquareAndInfoWindowW3w = (position, map, marker, callbackW3W = null) => {
    
    getW3wWords(position.lat, position.lng, (w3wWordsObj) => {
        //infowindow.setContent(newContent);
        createW3wSquare(w3wWordsObj, map);
        createW3wInfoWindow(w3wWordsObj, map, marker);
        // call callback to return a w3w object
        if(callbackW3W){
            callbackW3W(w3wWordsObj);
        }
    });
}

const getW3wWords = (latitude, longitude, callback) => {
    window.what3words && window.what3words.api.convertTo3wa({ lat: latitude, lng: longitude }, "it").then(function (response) {
        console.log("[convertTo3wa]", response);
        callback(response);
    });
}


const createW3wSquare = (w3wWordsObj, map) => {

    if (!w3wWordsObj || !map) {
        return null;
    }
    square === null || square.setMap(null);
    let squareCoords = [
        { lat: w3wWordsObj.square.northeast.lat, lng: w3wWordsObj.square.northeast.lng },
        { lat: w3wWordsObj.square.southwest.lat, lng: w3wWordsObj.square.northeast.lng },
        { lat: w3wWordsObj.square.southwest.lat, lng: w3wWordsObj.square.southwest.lng },
        { lat: w3wWordsObj.square.northeast.lat, lng: w3wWordsObj.square.southwest.lng }
    ];
    // Construct the polygon.
    square = new window.google.maps.Polygon({
        paths: squareCoords,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35
    });
    square.setMap(map);
    return square;
}

const createW3wInfoWindow = (w3wWordsObj, map, marker) => {

    if (infowindow) infowindow.close();
    if (!document.getElementById("textInfoWindow")) {
        let div = document.createElement('div');
        div.setAttribute('id', 'containerInfoWindow');
        let p = document.createElement('p');
        p.setAttribute('id', 'textInfoWindow');
        div.appendChild(p);
        infoWindowContent = document.querySelector('body').appendChild(div);
    }

    document.getElementById("textInfoWindow").innerHTML = w3wWordsObj.words;
    infowindow = new window.google.maps.InfoWindow({
        content: document.getElementById("containerInfoWindow")
    });


    infowindow.open(map, marker);
    return infowindow;
}

const updateW3wInfoWindowContent = (text) => {
    if (document.getElementById("textInfoWindow")) {
        document.getElementById("textInfoWindow").innerHTML = text;
    }
}

const removeW3wSquare = () => {
    square === null || square.setMap(null);
}

export { getW3wWords, createSquareAndInfoWindowW3w, updateW3wInfoWindowContent, removeW3wSquare };