import React, { createContext, useEffect } from 'react';
import { useNotificationsReducer } from '../reducers/useNotificationsReducer';
import { REMOVE_NOTIFICATIONS, GET_NOTIFICATIONS } from '../actions';

const NotificationsContext = createContext();

const NotificationsProvider = (props) => {
  const [state, dispatch] = useNotificationsReducer();
  const { notifications } = state;

  const getNotificationsDispatch = (data) => dispatch({ type: GET_NOTIFICATIONS, payload: data });
  const removeNotificationsDispatch = (notifications) => dispatch({ type: REMOVE_NOTIFICATIONS, payload: notifications });

  const fetchGetNotifications = async () => {
    const notificationsData = await fetch('https://run.mocky.io/v3/899f8cef-c89d-4bec-a70d-917c91c9f96f');
    const notificationJson = await notificationsData.json();
    getNotificationsDispatch(notificationJson);
  }

  const fetchRemoveNotifications = async (type) => {
    const notificationsData = await fetch('https://run.mocky.io/v3/899f8cef-c89d-4bec-a70d-917c91c9f96f');
    //const data = await notificationsData.json();
    //delete data[type];
    let data = {...notifications}
    delete data[type];
    getNotificationsDispatch(data);
  }

  // We use useEffect to make API calls.
  useEffect(() => {
   // fetchGetNotifications();
  }, []);

  const getNotifications = () => {
    fetchGetNotifications();
  }

  const addNotifications = (notifications) => {
    getNotificationsDispatch(notifications);
  }

  const removeNotifications = (type) => {
    fetchRemoveNotifications(type);
  }

  const providerValue = {
    notifications,
    getNotifications,
    addNotifications,
    removeNotifications
  }

  return (<NotificationsContext.Provider value={providerValue}>
    {props.children}
  </NotificationsContext.Provider>)

}

export { NotificationsContext, NotificationsProvider };
