import { useReducer } from 'react';
import { REFRESH_CURRENT_CHAT_INFO, REFRESH_CURRENT_CHAT_INFO_AND_MESSAGES, REFRESH_CURRENT_CHAT_MESSAGES, DELETE_CURRENT_CHAT, HIDE_CURRENT_CHAT, SHOW_CURRENT_CHAT } from '../actions';

const initialState = {
    isCurrentChatOpened: false,
    currentChatMessages: null,
    currentChatInfo: null,
    chatDeleteResult: null
};

const currentChatReducer = (state, action) => {
    switch (action.type) {
        case REFRESH_CURRENT_CHAT_INFO:
            return {
                ...state,
                currentChatInfo: action.payload.info
            };
            case REFRESH_CURRENT_CHAT_INFO_AND_MESSAGES:
                return {
                    ...state,
                    isCurrentChatOpened: true,
                    currentChatInfo: action.payload.info,
                    currentChatMessages: action.payload.messages
                };        
        case REFRESH_CURRENT_CHAT_MESSAGES:
            return {
                ...state,
                currentChatMessages: action.payload
            };
        case DELETE_CURRENT_CHAT:
                return {
                  ...state,
                  chatDeleteResult: action.payload
                };
        case HIDE_CURRENT_CHAT:
            return {
                ...state,
                isCurrentChatOpened: false
            };
            case SHOW_CURRENT_CHAT:
                return {
                    ...state,
                    isCurrentChatOpened: true
                };
        default:
            return state;
    }
};


export const useCurrentChatReducer = () =>
    useReducer(currentChatReducer, initialState);