/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState, useContext, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import SendIcon from '@material-ui/icons/Send';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Tooltip from '@material-ui/core/Tooltip';
import { OfferDetailsContext } from '../../common/providers/offerDetailsContext';
import { CurrentOffersContext } from '../../common/providers/CurrentOffersContext';
import { CurrentChatContext } from '../../common/providers/CurrentChatContext';
import { PreferredOffersContext } from '../../common/providers/PreferredOffersContext';
import {AccountContext} from '../../common/providers/AccountContext';
import { MyChatsContext } from '../../common/providers/MyChatsContext';

import { isInteger } from 'validate.js';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import DateRangeIcon from '@material-ui/icons/DateRange';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import TodayIcon from '@material-ui/icons/Today';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ShareOutlined } from '@material-ui/icons';
import ListSubheader from '@material-ui/core/ListSubheader';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flexGrow: 1,
    color: theme.palette.white
  },
  w3wName: {
    textAlign: 'center'
  },
  w3wSubheader:{
    lineHeight: 'normal',
    textAlign: 'center'
  },
  divChips: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  imageGallery: {
    width: '20vw',
    paddingTop: '1em'
  },
  section1: {
    margin: theme.spacing(3, 2),
  },
  section2: {
    margin: theme.spacing(2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  dividerInset: {
    margin: `5px 0 0 ${theme.spacing(9)}px`,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide
    direction="up"
    ref={ref}
    {...props}
  />;
});

const names = [
  'Lunedì',
  'Martedì',
  'Mercoledì',
  'Giovedì',
  'Venerdì',
  'Sabato',
  'Domenica'
];

const getHoursAndMinutes = (minutes) => { 
  if (!isInteger(minutes)){
    return minutes;
  }
  const hours = (minutes / 60);
  const rhours = Math.floor(hours);
  const rminutes = Math.round((hours - rhours) * 60);
  const finalHour = Math.round(hours);
  return `${finalHour} : ${ rminutes <10 ? '0'+rminutes : rminutes}`;
};

const OfferDetails = props => {
  const classes = useStyles();
  const { offerDetailsOpen } = props;
  const [chatterId, setChatterId] = useState('');
  const {user} = useContext(AccountContext);

  const [open, setOpen] = React.useState(offerDetailsOpen);
  const offerDetailsContext = useContext(OfferDetailsContext);
  const {
    isOfferDetailsOpened,
    offerDetailsData,
    hideOfferDetails
  } = offerDetailsContext;

  const {
    isCurrentChatOpened,
    currentChatInfo,
    currentChatMessages,
    getCurrentChatMessages,
    getCurrentChatInfo,
    getCurrentChat,
    sendMessageChat,
    hideCurrentChat,
    createCurrentChat
  } = useContext(CurrentChatContext);

  const currentOffersContext = useContext(CurrentOffersContext);
  const { offers } = currentOffersContext;
  const preferredOffersContext = useContext(PreferredOffersContext);
  const {
    allPreferredOffers,
    preferredSaveResult,
    refreshPreferredOffers,
    savePreferredOffer,
    deletePreferredOffer
  } = preferredOffersContext;

  const { chats, getMyChats } = useContext(MyChatsContext);

  useEffect(() => {
    if(user && user.id){
      setChatterId(user.id);
    }
  },[user]);

  useEffect(() => {
    if(isOfferDetailsOpened === true || isOfferDetailsOpened === false){
      setOpen(isOfferDetailsOpened);
    }
  },[isOfferDetailsOpened]);

  const checkIfChatAlreadyExist = ()  => {
    const existingChat = chats.list.find((chat) => {
      return chat.offer._id === offerDetailsData._id;
    })
    return existingChat;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    //setOpen(false);
    hideOfferDetails();
  };

  const handleSendChat = () => {
    //setOpen(false);
    const chat = checkIfChatAlreadyExist();
    if(chat && chat._id){
      getCurrentChatInfo(chat);
      return;
    }
    
    const receiverId = offerDetailsData.user.id;
    let newChatInfo = {
      offerID: offerDetailsData._id,
      memberIDs: [chatterId, receiverId],
    };
    createCurrentChat(newChatInfo);
  };

  const handlePreferredClick = (event, offerID) => {
    if(offerID){
      const favoriteOfferObj = checkIfFavorite(offerID);
      favoriteOfferObj ? deletePreferredOffer(favoriteOfferObj._id) : savePreferredOffer(offerID);
    }
  };

  const checkIfFavorite = offerID => {
    if(!allPreferredOffers){
      return;
    }
    const foundFavorite = allPreferredOffers.find(aPreferred => {
      return aPreferred.offer._id === offerID;
    });
    return foundFavorite;
  };

  useEffect(() => {
    setOpen(offerDetailsOpen);
  }, [offerDetailsOpen]);


  if( offerDetailsData && !offerDetailsData.user  ){
    return <CircularProgress color="inherit" />
  }
  

  return (
    <div>
      <Dialog
        fullScreen
        onClose={handleClose}
        open={open}
        TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              aria-label="close"
              color="inherit"
              edge="start"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              className={classes.title}
              variant="h6"
            >
              Dettagli Offerta - {offerDetailsData && offerDetailsData.user.name}
            </Typography>
            <Typography className={classes.title} />
            {offerDetailsData && chatterId && offerDetailsData.user.id !== chatterId && ['ACTIVE', 'BUSY'].includes(offerDetailsData.status) && <Button
              className={classes.button}
              color="secondary"
              endIcon={<SendIcon />}
              onClick={handleSendChat}
              variant="contained"
            >
              Chat
            </Button>
            }
          </Toolbar>
        </AppBar>
        <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader" className={classes.w3wSubheader} disableGutters={true}>
                  {offerDetailsData && offerDetailsData.w3wData && '///' + offerDetailsData.w3wData.address}
                </ListSubheader>
              }>
          <ListItem button>
            <ListItemText
              primary="Cosa offre"
              secondary={offerDetailsData && offerDetailsData.descriptionGive}
            />
            <div className={classes.divChips}>
              {offerDetailsData &&
                offerDetailsData.tagsGive.map(tagMetaObj => (
                  <Chip
                    color="primary"
                    key={tagMetaObj.name}
                    label={tagMetaObj.name}
                    variant="default"
                  />
                ))}
            </div>
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Cosa vuole"
              secondary={offerDetailsData && offerDetailsData.descriptionTake}
            />
            <div className={classes.divChips}>
              {offerDetailsData &&
                offerDetailsData.tagsTake.map(tagMetaObj => (
                  <Chip
                    color="primary"
                    key={tagMetaObj.name}
                    label={tagMetaObj.name}
                    variant="default"
                  />
                ))}
            </div>
          </ListItem>
          <Divider />
      
          <ListItem button>
            <ListItemText
              primary="Quando?"
              secondary={''}
            />
          </ListItem>
          <Divider
            component="li"
            variant="inset" />
            
          { offerDetailsData &&  offerDetailsData.scheduling && (
            <Fragment>
              { ( offerDetailsData.scheduling.hourStart || offerDetailsData.scheduling.hourEnd ) && (
                <Fragment>
                  <li>
                    <Typography
                      className={classes.dividerInset}
                      color="textSecondary"
                      display="block"
                      variant="caption"
                    >
                Orario
                    </Typography>
                  </li>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <AccessAlarmIcon />
                      </Avatar>
                    </ListItemAvatar>

                    <div className={classes.divChips}>
                      { offerDetailsData.scheduling.hourStart && <Chip
                        icon={<ScheduleIcon />}
                        color="secondary"
                        label={getHoursAndMinutes(offerDetailsData.scheduling.hourStart)}
                        variant="outlined"
                      />}
                    </div>
                    <div className={classes.divChips}>
                      { offerDetailsData.scheduling.hourEnd &&  <Fragment><SwapHorizIcon /><Chip
                        icon={<ScheduleIcon />}
                        color="secondary"
                        label={getHoursAndMinutes(offerDetailsData.scheduling.hourEnd)}
                        variant="outlined"
                      /></Fragment>}
                    </div>
                  </ListItem>
                </Fragment>
              )}

              { ( offerDetailsData.scheduling.weekDays && offerDetailsData.scheduling.weekDays.length > 0 ) && (
                <Fragment>
                  <li>
                    <Typography
                      className={classes.dividerInset}
                      color="textSecondary"
                      display="block"
                      variant="caption"
                    >
                      Giorni della settimana
                    </Typography>
                  </li>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <DateRangeIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <div className={classes.divChips}>
                      { offerDetailsData.scheduling.weekDays && offerDetailsData.scheduling.weekDays.map(day => (
                        <Chip
                          color="secondary"
                          label={names[day]}
                          variant="outlined"
                        />
                      ))}
                    </div>
                  </ListItem>
                </Fragment>
              )}


              { ( offerDetailsData.scheduling.start || offerDetailsData.scheduling.end ) && (
                <Fragment>
                  <li>
                    <Typography
                      className={classes.dividerInset}
                      color="textSecondary"
                      display="block"
                      variant="caption"
                    >
                      { offerDetailsData.scheduling.start &&  'Data inizio'}
                      { offerDetailsData.scheduling.start &&  offerDetailsData.scheduling.end && ' e '}  
                      { offerDetailsData.scheduling.end &&  'Data fine'}
                    </Typography>
                  </li>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <TodayIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <div className={classes.divChips}>
                      { offerDetailsData.scheduling.start && <Chip
                        avatar={<Avatar>da</Avatar>}
                        color="secondary"
                        label={offerDetailsData.scheduling.start.slice(0,10)}
                        variant="outlined"
                      />}
                      { offerDetailsData.scheduling.end &&  <Chip
                        avatar={<Avatar>a</Avatar>}
                        color="secondary"
                        label={offerDetailsData.scheduling.end.slice(0,10)}
                        variant="outlined"
                      />}
                    </div>
                  </ListItem>
                </Fragment>
              )}
            </Fragment>
          )}
          <Divider />
          <ListItem button>
            <div className={classes.divChips}>
              {offerDetailsData &&
                offerDetailsData.photos.map(element => (
                  <img
                    className={classes.imageGallery}
                    src={element && element.url}
                  />
                ))}
            </div>
          </ListItem>
          <Divider />
          <ListItem button>
            <Tooltip
              title={
                offerDetailsData && checkIfFavorite(offerDetailsData._id)
                  ? 'Rimuovi dai preferiti'
                  : 'Aggiungi ai preferiti'
              }
            >
              <IconButton
                edge="end"
                onClick={event =>
                  offerDetailsData &&
                  handlePreferredClick(event, offerDetailsData._id)
                }
                size="small"
              >
                {offerDetailsData && checkIfFavorite(offerDetailsData._id) ? (
                  <FavoriteIcon />
                ) : (
                  <FavoriteBorderIcon />
                )}
              </IconButton>
            </Tooltip>
            { (offerDetailsData && offerDetailsData.status &&  offerDetailsData.status !== 'ACTIVE') && 
                        <Chip
                          color="primary"
                          label={offerDetailsData.status}
                          variant="outlined"
                        />
              }
          </ListItem>
        </List>
      </Dialog>
    </div>
  );
};

export default OfferDetails;
