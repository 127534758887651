import React, { useState, useEffect, useMemo, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { useMediaQuery } from '@material-ui/core';

const autocompleteService = { current: null };
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },

  root: {
    width: '100%'
  },
  inputRoot: {
    color: 'inherit',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.85),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.95),
    },
    marginLeft: 0,
    minWidth: '60px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));


export default function GoogleMaps(props) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);
  const { onSelectPlace, ...rest } = props;
  let textInput = useRef(null);

  const isDesktop = useMediaQuery(useTheme().breakpoints.up('lg'), {
    defaultMatches: true
  });
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const choosePlace = (event, value) => {
    textInput.current.blur();
    if (!value) {
      return;
    }
    let service = new window.google.maps.Geocoder;

    service.geocode({
      placeId: value.place_id
    }, function (result, status) {
      if(!result || !Array.isArray(result) || !result.length ){
        return;
      }
      const location = result[0].geometry.location;
      console.log('Trovata la posizione di place: ' + location);

      onSelectPlace({lat: location.lat(), lng: location.lng()}, '');
    });
    
  }


  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <Autocomplete
      autoComplete
      classes={{
        root: classes.root,
        inputRoot: classes.inputRoot,
        input: classes.inputInput

      }}
      filterOptions={x => x}
      freeSolo
      getOptionLabel={option => option.description}
      id="google-map-demo"
      includeInputInList
      onChange={choosePlace}
      options={options}
      renderInput={params => (
        <TextField
          {...params}
          autoFocus={false}
          color="secondary"
          fullWidth
          margin={isDesktop? 'normal' : 'dense'}
          onChange={handleChange}
          placeholder="Cerca un terreno"
          variant="outlined"
          inputRef={textInput}
        />
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid
            alignItems="center"
            container
          >
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid
              item
              xs
            >
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography
                color="textSecondary"
                variant="body2"
              >
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
