import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Avatar from '@material-ui/core/Avatar';
import FaceIcon from '@material-ui/icons/Face';
import { UploadPhoto } from 'components';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    textAlign: 'center',
    '& > *': {
      margin: theme.spacing(1)
    },
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: '50vh'
  },
  divChips: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  textAreaOffer: {
    fontSize: '2em'
  }
}));

function getSteps() {
  return ['Cosa offri?', 'Cosa chiedi?', 'Quando?', 'Conferma!'];
}

export default function OfferStepForm(props) {
  const classes = useStyles();
  const { listOfTakeTags, listOfGiveTags, offerData, saveUpdateOffer, updateOffer,...rest } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [selectedTakeTags, setSelectedTakeTags] = useState(offerData && offerData.tagsTake || []);
  const [selectedGiveTags, setSelectedGiveTags] = useState(offerData && offerData.tagsGive || []);
  const [offerToBeSaved, setOfferToBeSaved] = useState(offerData || {});
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });
  const [formState, setFormState] = useState({
    isValid: false,
    values: offerData || {},
    touched: {},
    errors: {},
    isInitialData: false
  });

  const steps = getSteps();
  let listOfferMeta = [];

  const updateFormState = (elementName, elementValue) => {
    //update form state
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [elementName]: elementValue
      },
      touched: {
        ...formState.touched,
        [elementName]: true
      }
    }));
  }

  const saveAll = () => {
    saveUpdateOffer(formState.values)
  }

  const removeOfferMeta = (offerMeta, event) => {
    if (offerMeta.tagType == 'GIVE') {
      setSelectedGiveTags(selectedGiveTags.filter(offerID => offerID != offerMeta._id));
      updateFormState('tagsGive', selectedGiveTags.filter(offerID => offerID != offerMeta._id))
    }
    if (offerMeta.tagType == 'TAKE') {
      setSelectedTakeTags(selectedTakeTags.filter(offerID => offerID != offerMeta._id));
      updateFormState('tagsTake', selectedTakeTags.filter(offerID => offerID != offerMeta._id))
    }
  };


  const addOfferMeta = (offerMeta) => {
    if (offerMeta.tagType == 'GIVE') {
      setSelectedGiveTags([...selectedGiveTags, offerMeta._id]);
      updateFormState('tagsGive', [...selectedGiveTags, offerMeta._id])
    }
    if (offerMeta.tagType == 'TAKE') {
      setSelectedTakeTags([...selectedTakeTags, offerMeta._id]);
      //update form state
      updateFormState('tagsTake', [...selectedTakeTags, offerMeta._id])
    }
  };

  const handleChangePhotos = photos => {
    updateFormState('photos', photos);
  }

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <div><TextareaAutosize
            aria-label="maximum height"
            className={classes.textAreaOffer}
            defaultValue=""
            name="descriptionGive"
            onChange={handleChange}
            placeholder="Descrivi cosa vuoi offrire per il tuo terreno"
            rowsMax={20}
            style={{ width: '90%', height: '30vh' }}
            value={formState.values.descriptionGive || ''}

          />
            <div className={classes.divChips}>
            {listOfTakeTags.map(tagMetaObj => (
                <Chip
                  key={tagMetaObj.name}
                  label={tagMetaObj.name}
                  onClick={(e) => addOfferMeta(tagMetaObj, e)}
                  onDelete={(e) => removeOfferMeta(tagMetaObj, e)}
                  deleteIcon={selectedTakeTags.includes(tagMetaObj._id) ? '' : <DoneIcon />}
                  color={selectedTakeTags.includes(tagMetaObj._id) ? 'primary' : ''}
                  variant={selectedTakeTags.includes(tagMetaObj._id) ? 'default' : 'outlined'}
                />
              ))}
            </div></div>);
      case 1:
        return (
          <div><TextareaAutosize
            style={{ width: '90%', height: '30vh' }}
            className={classes.textAreaOffer}
            rowsMax={20}
            aria-label="maximum height"
            placeholder="Descrivi cosa vuoi chiedere per il tuo terreno"
            onChange={handleChange}
            defaultValue=""
            name="descriptionTake"
            value={formState.values.descriptionTake || ''}
          />
            <div className={classes.divChips}>
              {listOfGiveTags.map(tagMetaObj => (
                <Chip
                  label={tagMetaObj.name}
                  onClick={(e) => addOfferMeta(tagMetaObj, e)}
                  onDelete={(e) => removeOfferMeta(tagMetaObj, e)}
                  deleteIcon={selectedGiveTags.includes(tagMetaObj._id) ? '' : <DoneIcon />}
                  color={selectedGiveTags.includes(tagMetaObj._id) ? 'primary' : ''}
                  variant={selectedGiveTags.includes(tagMetaObj._id) ? 'default' : 'outlined'}
                />
              ))}
            </div></div>);
      case 2:
        return <UploadPhoto photos={offerData && formState.values.photos || []} handleChangePhotos={handleChangePhotos} />;
      default:
        return 'Unknown stepIndex';
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === steps.length - 1) {
      //save current offer
      saveAll();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (!offerData) {
    }
  }, []);

  useEffect(() => {
    updateOffer(formState.values);
  }, [formState.values]);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>Tutti i passaggi completati</Typography>
            <Button onClick={handleReset}>Cancella</Button>
          </div>
        ) : (
            <div>
              <div className={classes.instructions}><form className={classes.root} noValidate autoComplete="off">
                {getStepContent(activeStep)}</form></div>
              <div>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}>
                  Indietro
                </Button>
                <Button variant="contained" color="primary" onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Finito' : 'Avanti'}
                </Button>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
