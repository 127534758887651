import { useReducer } from 'react';
import { GET_OFFER, SAVE_OFFER, DELETE_OFFER, GET_TAGS, TOGGLE_OFFER, RESULT_OFFER } from '../actions';

const initialState = { isOfferEditSaveOpened: false, allOfferTags: null,  offerEditSaveData: null, offerEditSaveResult: null, offerDeleteResult: null };

const offerEditSaveReducer = (state, action) => {
  switch (action.type) {
    case GET_OFFER:
      return {
        ...state,
        isOfferEditSaveOpened: true,
        offerEditSaveData: action.payload
      };
    case SAVE_OFFER:
      return {
        ...state,
        offerEditSaveData: action.payload
      };
    case RESULT_OFFER:
      return {
        ...state,
        offerEditSaveResult: action.payload
      };
    case DELETE_OFFER:
      return {
        ...state,
        offerDeleteResult: action.payload
      };
    case GET_TAGS:
      return {
        ...state,
        allOfferTags: action.payload
      };

    case TOGGLE_OFFER:
      return {
        ...state,
        isOfferEditSaveOpened: !action.payload
      };   
    default:
      return state;
  }
};


export const useOfferEditSaveReducer = () =>
  useReducer(offerEditSaveReducer, initialState);