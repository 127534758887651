
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { DropzoneDialog } from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import request from 'superagent';
import uuid from 'uuid/v1';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  previewImage: {
    width: '20vw',
    paddingTop: '1em'
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

const UploadPhoto = props => {
  const { className, style, photos, handleChangePhotos, handleAlertMessage, ...rest } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [listOfPhotos, setListOfPhotos] = useState(photos || []);

  const handleChange = async (files) => {
    if(files && files.length > 0){
      const uploadedPhotosToRemote =  await uploadToRemote(files);
      let updatedList = [...listOfPhotos, ...uploadedPhotosToRemote];
      /*       updatedList = updatedList.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.name === thing.name && t.path === thing.path
        ))
      ) */
      setListOfPhotos(updatedList);
      handleChangePhotos(updatedList);
      if(uploadToRemote && uploadToRemote.length > 0 ){
        handleAlertMessage('Foto caricate con successo!', 'success', 'Ottimo!');
      }
    }

  }

  const uploadToRemote = async (files) => {
    // send to remote
    const url = `https://api.cloudinary.com/v1_1/${
      'inventu'
    }/upload`;
    const title = 'this.titleEl.value';

    let updatedList = [];

    for (let file of files) {
      const photoId = uuid();
      const fileName = file.name;
      const response = await request.post(url)
        .field('upload_preset', 'bpc3izlw')
        .field('file', file)
        .field('multiple', true)
        .field('tags', title ? `offer,${title}` : 'zappami')
        .field('context', title ? `photo=${title}` : '')
        .on('progress', (progress) => onPhotoUploadProgress(photoId, file.name, progress));
      //onPhotoUploaded(photoId, fileName, response);
      if(response.body){
        const url = response.body.secure_url || null;
        const publicID = response.body.public_id || null;
        updatedList.push({name: fileName, description: '', url, publicID});
      }
    }
    return updatedList;
  }

  const eraseToRemote = async (photo) => {

    const listOfUpdatedPhotos = listOfPhotos.filter((aPhoto) => {
      return photo.publicID !== aPhoto.publicID;
    });
    setListOfPhotos(listOfUpdatedPhotos);
    handleChangePhotos(listOfUpdatedPhotos);
    handleAlertMessage('Foto eliminata con successo!', 'success', 'Ottimo!');
    return;
    // send to remote
    const url = `https://api.cloudinary.com/v1_1/${
      'inventu'
    }/upload/zappami`;

    try {
      const response = await request.delete(url)
        .field('upload_preset', 'bpc3izlw')
        .field('public_ids', photo.publicID)
        .set('Access-Control-Allow-Headers', 'Content-Type, X-Auth-Token, Origin');
      //onPhotoUploaded(photoId, fileName, response);
      if(response.deleted){
        const listOfUpdatedPhotos = listOfPhotos.filter((aPhoto) => {
          return photo.publicID !== aPhoto.publicID;
        });
        setListOfPhotos(listOfUpdatedPhotos);
      }
    } catch (error) {
      console.log(error)
    }
  }


  const onPhotoUploadProgress = (id, fileName, progress) =>  {
    console.log(progress);
  };

  const removedFile = (file) => {
    const listOfUpdatedPhotos = listOfPhotos.filter((photo) => {
      return photo.fileName !== file.name;
    });
    setListOfPhotos(listOfUpdatedPhotos);
  }

  return (
    <div>
      <Button
        color="primary"
        onClick={() => setOpen(true)}
        variant="contained"
      >
        CARICA
      </Button>
      <div className={classes.root}>
        <GridList cellHeight={180} className={classes.gridList}>
          <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
            <ListSubheader component="div"></ListSubheader>
          </GridListTile>
          {listOfPhotos.map((tile) => (
            <GridListTile key={tile.publicID}>
              <img src={tile.url} alt={tile.name} />
              <GridListTileBar
                title={tile.title}
                subtitle={<span>{tile.name}</span>}
                actionIcon={
                  <IconButton onClick={() => eraseToRemote(tile)} aria-label={`info about ${tile.name}`} className={classes.icon}>
                    <DeleteIcon />
                  </IconButton>
                }
              />
            </GridListTile>
          ))}
        </GridList>
      </div>
      <DropzoneDialog
        acceptedFiles={['image/*']}
        cancelButtonText={'Chiudi'}
        clearOnUnmount={false}
        dialogTitle={'Carica i file'}
        dropzoneText={'Trascina i file o clicca'}
        /* getFileAddedMessage= {(fileName) => (`File ${fileName} aggiunto con successo.`)}*/
        showAlerts={false}
        initialFiles={listOfPhotos}
        maxFileSize={5000000}
        onChange={handleChange}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          console.log('Files:', files);
          setOpen(false);
        }}
        open={open}
        previewText={'Anteprima'}
        showFileNamesInPreview
        showPreviews
        submitButtonText={'Invia'}
        useChipsForPreview
        addRemoveLinks={true}
        removedfile= {removedFile}
      />
    </div>
  );

};

UploadPhoto.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};

export default UploadPhoto;
