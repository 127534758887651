import gql from 'graphql-tag';

const GET_OFFER = gql`
  query($from: Int, $many: Int, $search: String) {
    products(from: $from, many: $many, search: $search) {
      allData {
        name
        related
        images
        tags
        categories
        price
        id
        description
        shortDescription
        permalink
        urlImage
      }
      allCount
    }
  }
`;

/* const GET_OFFERS = gql`
`;
const GET_CHAT = gql`
`;
const GET_CHATS = gql`
`;
const GET_OWNOFFERS = gql`
`;
const GET_OWNWORKS = gql`
`;
const GET_PREFERREDS = gql`
`; */
const SIGNUP_MUTATION = gql`
  mutation SignupMutation($email: String!, $password: String!, $name: String!) {
    signup(email: $email, password: $password, name: $name) {
      token
      user
    }
  }
`;

const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user
    }
  }
`;

const USER_UPDATE_MUTATION = gql`
  mutation UpdateUserMutation($id: ID!, $email: String, $password: String, $name: String, $profileImage: UserPhotoInput) {
    updateUser(id: $id, email: $email, password: $password, name: $name, profileImage: $profileImage) {
      token
      user
    }
  }
`;

const USER_DELETE_MUTATION = gql`
  mutation DeleteUserMutation($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

const USER_PHOTO_REMOVE_MUTATION = gql`
  mutation RemovePhotoMutation($id: ID!) {
    removePhoto(id: $id) {
      id
      email
      name
    }
  }
`;

const FORGOT_MUTATION = gql`
  mutation ForgotMutation($email: String!) {
    forgot(email: $email) {
      id
    }
  }
`;

const RESET_MUTATION = gql`
  mutation ResetMutation($key: String!, $pwd: String!) {
    reset(key: $key, pwd: $pwd) {
      id
    }
  }
`;

const CURRENTUSER_MUTATION = gql`
  mutation updateUserMutation(
    $id: ID!
    $email: String!
    $password: String!
    $name: String!
    $profileImage: String
  ) {
    updateUser(
      id: $id
      email: $email
      password: $password
      name: $name
      profileImage: $profileImage
    ) {
      user
    }
  }
`;
const GET_CURRENTUSER = gql`
  query currentUser {
    currentUser {
      id
      name
      email
      votes
      profileImage{
        url
        publicID
      }
      role
    }
  }
`;

const OFFER_ADD_MUTATION = gql`
  mutation addOfferMutation(
    $userID: String
    $descriptionGive: String
    $tagsGive: [OfferTagInput]
    $descriptionTake: String
    $tagsTake: [OfferTagInput]
    $scheduling: OfferSchedulingInput
    $status: Status
    $photos: [OfferPhotoInput]
    $position: Point
    $w3wData: OfferW3WInput
  ) {
    addOffer(
      userID: $userID
      descriptionGive: $descriptionGive
      tagsGive: $tagsGive
      descriptionTake: $descriptionTake
      tagsTake: $tagsTake
      scheduling: $scheduling
      status: $status
      photos: $photos
      position: $position
      w3wData: $w3wData
    ) {
      _id
      descriptionGive
      tagsGive {
        _id
        name
        slug
        description
        tagType
      }
      descriptionTake
      tagsTake {
        _id
        name
        slug
        description
        tagType
      }
      scheduling {
        hourStart
        hourEnd
        weekDays
        start
        end
      }
      status
      photos {
        name
        description
        url
        publicID
      }
      creationDate
      updateDate
      position
      w3wData {
        address
        idW
      }
      user {
        id
        email
        name
        votes
        profileImage {
          url
          publicID        }
        role
      }
    }
  }
`;

const OFFER_UPDATE_MUTATION = gql`
  mutation updateOfferMutation(
    $_id: ID!
    $userID: String
    $descriptionGive: String
    $tagsGive: [OfferTagInput]
    $descriptionTake: String
    $tagsTake: [OfferTagInput]
    $scheduling: OfferSchedulingInput
    $status: Status
    $photos: [OfferPhotoInput]
    $position: Point
    $w3wData: OfferW3WInput
  ) {
    updateOffer(
      _id: $_id
      userID: $userID
      descriptionGive: $descriptionGive
      tagsGive: $tagsGive
      descriptionTake: $descriptionTake
      tagsTake: $tagsTake
      scheduling: $scheduling
      status: $status
      photos: $photos
      position: $position
      w3wData: $w3wData
    ) {
      _id
      descriptionGive
      tagsGive {
        _id
        name
        slug
        description
        tagType
      }
      descriptionTake
      tagsTake {
        _id
        name
        slug
        description
        tagType
      }
      scheduling {
        hourStart
        hourEnd
        weekDays
        start
        end
      }
      status
      photos {
        name
        description
        url
        publicID
      }
      creationDate
      updateDate
      position
      w3wData {
        address
        idW
      }
      user {
        id
        email
        name
        votes
        profileImage {
          url
          publicID        }
        role
      }
    }
  }
`;

const OFFER_DELETE_MUTATION = gql`
mutation deleteOfferMutation($_id: ID!) {
  deleteOffer(
  _id: $_id
  ){
    _id
  }}
`;
const OFFERS_SEARCH_QUERY = gql`
  query offersQuery(
    $search: String
    $from: Int
    $many: Int
    $filter: OfferFilterArg
  ) {
    offers(search: $search, from: $from, many: $many, filter: $filter) {
      allData {
        _id
        descriptionGive
        tagsGive {
          _id
          name
          slug
          description
          tagType
        }
        descriptionTake
        tagsTake {
          _id
          name
          slug
          description
          tagType
        }
        scheduling {
          hourStart
          hourEnd
          weekDays
          start
          end
        }
        status
        photos {
          name
          description
          url
          publicID
        }
        creationDate
        updateDate
        position
        w3wData {
          address
          idW
        }
        user {
          id
          email
          name
          votes
          profileImage{
            url
            publicID
          }
          role
        }
      }
    }
  }
`;

const OFFER_GET_QUERY = gql`
  query offerQuery($_id: ID!) {
    offer(_id: $_id) {
        _id
        descriptionGive
        tagsGive {
          _id
          name
          slug
          description
          tagType
        }
        descriptionTake
        tagsTake {
          _id
          name
          slug
          description
          tagType
        }
        scheduling {
          hourStart
          hourEnd
          weekDays
          start
          end
        }
        status
        photos {
          name
          description
          url
          publicID
        }
        creationDate
        updateDate
        position
        w3wData {
          address
          idW
        }
        user {
          id
          email
          name
          votes
          profileImage{
            url
            publicID
          }
          role
      }
    }
  }
`;

const TAGS_SEARCH_QUERY = gql`
  query offerTagsQuery(
    $search: String
    $from: Int
    $many: Int
    $filter: OfferTagFilterArg
  ) {
    offerTags(search: $search, from: $from, many: $many, filter: $filter) {
      allData {
        _id
        tagType
        slug
        name
      }
    }
  }
`;

//preferreds mutations an queries
const PREFERRED_OFFERS_ADD_MUTATION = gql`
mutation  addPreferredOfferMutation(
  $userID: String
  $offerID: String
  ){
addPreferredOffer(
  userID: $userID
  offerID: $offerID
  ){
  _id
user {
  id
}
offer {
  _id
  status
  position
}
creationDate
updateDate
    }
  }`;

const PREFERRED_OFFERS_DELETE_MUTATION = gql`
mutation  deletePreferredOfferMutation(
  $_id: ID!
  ){
    deletePreferredOffer(
  _id: $_id
  ){
      _id
  }
}`;

const PREFERRED_OFFERS_SEARCH_QUERY = gql`
  query searchPreferredOfferQuery(
    $from: Int
    $many: Int
    $filter: PreferredOfferFilterArg
  ) {
    preferredOffers(from: $from, many: $many, filter: $filter) {
      allData {
        _id
        user {
          id
          email
          name
          votes
          profileImage{
            url
            publicID
          }
          role
        }
offer { _id
  descriptionGive
  tagsGive {
    _id
    name
    slug
    description
    tagType
  }
  descriptionTake
  tagsTake {
    _id
    name
    slug
    description
    tagType
  }
  scheduling {
    hourStart
    hourEnd
    weekDays
    start
    end
  }
  status
  photos {
    name
    description
    url
    publicID
  }
  creationDate
  updateDate
  position
  w3wData {
    address
    idW
  }
}
creationDate
updateDate
      }
    }
  }
`;

const CHAT_ADD_MUTATION = gql`
mutation  addChatMutation(
  $offerID: String
$memberIDs: [String]!
  ){
addChat(
  offerID: $offerID
  memberIDs: $memberIDs
  ){
    _id
    offer{
      _id
    }
    members{
      id
      name
    }
    createdAt
    updatedAt
    }
  }`;

  const CHAT_DELETE_MUTATION = gql`
mutation  deleteChatMutation(
  $_id: ID!
  ){
deleteChat(
  _id: $_id
  ){
    _id
    }
  }`;

const CHAT_SEARCH_QUERY = gql`
  query searchChatQuery(
    $from: Int
    $many: Int
    $filter: ChatFilterArg
  ) {
    chats(from: $from, many: $many, filter: $filter) {
      allData {
        _id
        offer{
          _id
          descriptionGive
          tagsGive {
            _id
            name
            slug
            description
            tagType
          }
          descriptionTake
          tagsTake {
            _id
            name
            slug
            description
            tagType
          }

        }
        members{
          id
          name
          profileImage{
            url
            publicID
          }
        }
        lastMessages{
          _id
          content
          condition
          createdAt
          from{
            id
            name
          }
        }

        createdAt
        updatedAt
      }
    }
  }
`;

const MESSAGE_ADD_MUTATION = gql`
mutation  addMessageMutation(
  $chatID: String!
  $fromID: String!
  $content: String!
  $type: Type
  $condition: Condition
  ){
addMessage(
  chatID: $chatID
  fromID: $fromID
  content: $content
  type: $type
  condition: $condition
  ){
    _id
    from {
      id
      name
    }
    chat {
      _id
      createdAt
    }
    content
    createdAt
    updatedAt
    type
    condition
    }
  }`;

  const MESSAGE_UPDATE_MUTATION = gql`
mutation  updateMessageMutation(
  $_id: ID!
  $content: String
  $condition: Condition
  ){
updateMessage(
  _id: $_id
  content: $content
  condition: $condition
  ){
    _id
    from {
      id
      name
    }
    chat {
      _id
      createdAt
    }
    content
    createdAt
    updatedAt
    type
    condition
    }
  }`;

const MESSAGE_DELETE_MUTATION = gql`
  mutation  deleteMessageMutation(
    $_id: ID!
    ){
  deleteMessage(
    _id: $_id
    ){
      _id
      from {
        id
        name
      }
      chat {
        _id
        createdAt
      }
      content
      createdAt
      updatedAt
      type
      condition
      }
    }`;


const MESSAGE_SEARCH_QUERY = gql`
  query searchMessageQuery(
    $chatID: String!
    $from: Int
    $many: Int
    $filter: MessageFilterArg
  ) {
    messages(chatID: $chatID, from: $from, many: $many, filter: $filter) {
      allData {
        _id
        from {
          id
        name
        profileImage {
          url
          publicID        }
        email
      }
        chat {
          _id
        }
        content
        createdAt
        updatedAt
        type
        condition
        }
      }
  }`;

export {
  SIGNUP_MUTATION,
  LOGIN_MUTATION,
  USER_UPDATE_MUTATION,
  USER_DELETE_MUTATION,
  USER_PHOTO_REMOVE_MUTATION,
  FORGOT_MUTATION,
  RESET_MUTATION,
  GET_CURRENTUSER,
  CURRENTUSER_MUTATION,
  OFFER_ADD_MUTATION,
  OFFER_UPDATE_MUTATION,
  OFFER_DELETE_MUTATION,
  OFFER_GET_QUERY,
  OFFERS_SEARCH_QUERY,
  TAGS_SEARCH_QUERY,
  PREFERRED_OFFERS_ADD_MUTATION,
  PREFERRED_OFFERS_DELETE_MUTATION,
  PREFERRED_OFFERS_SEARCH_QUERY,
  CHAT_ADD_MUTATION,
  CHAT_DELETE_MUTATION,
  CHAT_SEARCH_QUERY,
  MESSAGE_ADD_MUTATION,
  MESSAGE_UPDATE_MUTATION,
  MESSAGE_DELETE_MUTATION,
  MESSAGE_SEARCH_QUERY,

};
