import React, { createContext, useEffect } from 'react';
import { useMyChatsReducer } from '../reducers/useMyChatsReducer';
import { ADD_MY_CHAT, GET_MY_CHATS } from '../actions';
import {
  CHAT_SEARCH_QUERY,
  MESSAGE_SEARCH_QUERY,
} from '../../graphql/queries';
import { useLazyQuery } from '@apollo/client';

const MyChatsContext = createContext();


const MyChatsProvider = (props) => {
  const [state, dispatch] = useMyChatsReducer();
  const { chats } = state;

  const addMyChatDispatch = (chat) => dispatch({ type: ADD_MY_CHAT, chat });
  const getMyChatsDispatch = (chats) => dispatch({ type: GET_MY_CHATS, chats });

  const onCompleteChatSearch = dataOffer => {
    if (!dataOffer.chats || !dataOffer.chats[0] || !dataOffer.chats[0].allData) return;
    const chats = { list: dataOffer.chats[0].allData };
    getMyChatsDispatch(chats);
  };


  const [chatsSearch, { data, refetch }] = useLazyQuery(CHAT_SEARCH_QUERY, {
    onCompleted: onCompleteChatSearch
  });

  const fetchMyChats = async () => {
    if(refetch){
      refetch();
      return;
    }
    await chatsSearch({ variables: {filter: {}, salt: Math.random().toString(36).substring(7)}});
  };

  const pushMyChat = async (chat) => {
    addMyChatDispatch(chat);
  }

  const addMyChat = (chat) => {
    // send the chat as a post call   
    pushMyChat(chat);
  }

  // We use useEffect to make API calls.
  useEffect(() => {
    fetchMyChats();
  }, []);

  useEffect(() => {
    if (data) {
      onCompleteChatSearch(data);
    }
  }, [data]);

  const getMyChats = () => {
    fetchMyChats();
  }

  const providerValue = {
    chats,
    addMyChat,
    getMyChats
  }

  return (<MyChatsContext.Provider value={providerValue}>
    {props.children}
  </MyChatsContext.Provider>)

}

export { MyChatsContext, MyChatsProvider };
