import { useReducer } from 'react';
import { ADD_MY_CHAT, GET_MY_CHATS } from '../actions';

const getMyChats = (chats, state) => ({
    chats: chats
});

const myChatsReducer = (state, action) => {
    switch (action.type) {
        case ADD_MY_CHAT:
            return action.chat;
        case GET_MY_CHATS:
            return getMyChats(action.chats, state);
    }
}

export const useMyChatsReducer = () =>
    useReducer(myChatsReducer, { chats: [] });
