import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { PreferredOffersContext } from '../../../../common/providers/PreferredOffersContext';


const  validURL = (str) =>  {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justify: 'space-between'
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    marginBottom: '1em'
  },
  image: {
    width: 128,
    height: 128,
    cursor: 'auto'
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  divChips: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
}));

const AccountPreferreds = () => {
  const classes = useStyles();
  const { allPreferredOffers,
    deletePreferredOffer, } = useContext(PreferredOffersContext);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const handleEditPreferredClick = (preferred) => {
    deletePreferredOffer(preferred._id);
  }

  // We use useEffect to get all preferred offers.
  useEffect(() => {
    if(allPreferredOffers){
      forceUpdate();
    }
  }, [allPreferredOffers]);

  if (allPreferredOffers === null) {
    return <CircularProgress color="inherit" />
  }
  if (allPreferredOffers.length === 0) {
    return  <Typography
      className={classes.title}
      variant="h6"
    >
    Non hai ancora nessun preferito!
    </Typography>
  }
  return (
    allPreferredOffers.map((aPreferred) =>
      <div className={classes.root}>
        <Paper
          className={classes.paper}
          key={aPreferred.offer._id}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid item>
              <ButtonBase className={classes.image}>
                <img
                  alt="complex"
                  className={classes.img}
                  src={aPreferred.offer.photos && aPreferred.offer.photos[0] && validURL(aPreferred.offer.photos[0].url) && aPreferred.offer.photos[0].url || '/images/farm.png'}
                />
              </ButtonBase>
            </Grid>
            <Grid
              container
              item
              sm
              xs={12}
            >
              <Grid
                container
                direction="column"
                item
                spacing={2}
                xs
              >
                <Grid
                  item
                  xs
                >
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                  >
                    {aPreferred.offer.descriptionGive}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body2"
                  >
                    {aPreferred.offer.descriptionTake}
                  </Typography>
                  <div className={classes.divChips}>
                    {aPreferred.offer && aPreferred.offer.tagsGive && aPreferred.offer.tagsGive.map(tagMetaObj => (
                      <Chip
                        color="primary"
                        key={tagMetaObj.id}
                        label={tagMetaObj.name}
                        variant="default"
                      />
                    ))}
                    {aPreferred.offer && aPreferred.offer.tagsTake && aPreferred.offer.tagsTake.map(tagMetaObj => (
                      <Chip
                        color="primary"
                        key={tagMetaObj.id}
                        label={tagMetaObj.name}
                        variant="default"
                      />
                    ))}
                  </div>
                </Grid>
                <Grid item>
                  <Typography
                    style={{ cursor: 'pointer' }}
                    variant="body2"
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">              
                  <IconButton
                    aria-label="delete"
                    edge="end"
                    onClick={() => handleEditPreferredClick(aPreferred)}
                  >
                    <DeleteIcon />
                  </IconButton></Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  );
}

export default AccountPreferreds;
