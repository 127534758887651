import { useEffect, useRef } from 'react';

const GoogleMarker = (props) => {

  const { map, google, position, idMarker, onClick, type, status } = props;
  const markerService = useRef(null);

  const getStatusIcon = (markerStatus) => {
    switch(markerStatus){
      case 'ACTIVE':
        return 'images/markers/seller2.svg';
      case 'INACTIVE':
        return 'images/markers/seller2deactive.svg';
      case 'BUSY':
        return 'images/markers/seller2busy.svg';
      case 'EXPIRED':
        return 'images/markers/seller2deactive.svg';
    }
  }

  const getIcon = () => {
    switch (type) {
      case 'SELL':
      case 'SHARE':
      case 'RENT':
      case 'SELECTED':
      {
        return {
          url: getStatusIcon(status),
          scaledSize: new google.maps.Size(32, 32)
        };
      }
      case 'CENTRAL':
        return {
          url: 'images/markers/markerAdd.svg',
          scaledSize: new google.maps.Size(42, 42)
        };


    }
  }

  const cleanupMarker = () => {
    if( google && google.maps && markerService.current){
      google && google.maps.event.clearInstanceListeners(markerService.current);
      markerService.current.setMap(null);
    }

  }
  const createMarker = () => {

    // Create new marker and render it on the map.
    if (!google) {
      return null
    }

    markerService.current = new google.maps.Marker({
      map: map,
      icon: getIcon(),
      draggable: false,
      animation: type === 'SELECTED'? google.maps.Animation.BOUNCE : google.maps.Animation.DROP,
      position: new google.maps.LatLng(position.lat, position.lng)
    });

    markerService.current.addListener('click', function () {
      onClick(idMarker);
    });


  }

  useEffect(() => {
    if (map) {
      createMarker();
    }


  }, [map]);

  useEffect(() => {
    //marker.  let newLatLng = new window.google.maps.LatLng(x, y);
    if (markerService.current) {
      markerService.current.setPosition(position);
      markerService.current.setAnimation(type === 'SELECTED'? google.maps.Animation.BOUNCE : google.maps.Animation.DROP);
      markerService.current.setIcon(getIcon());
      
    }
    //marker.setPosition(position);

  }, [position]);

  useEffect(() => {
    // returned function will be called on component unmount 
    return () => {
      cleanupMarker();
    }

  }, []);

  return null;
}

export default GoogleMarker;