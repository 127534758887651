import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';

import { MyChatsContext } from '../../../../common/providers/MyChatsContext';
import { CurrentChatContext } from '../../../../common/providers/CurrentChatContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  rowChat: {
    paddingRight: 78,
    paddingLeft:0,
  }
}));

const getChatterInfo = (members) =>{
  const user = JSON.parse(localStorage.getItem('user'));
  if(!user)
    return;
  const userID = user.id;
  let chatter =  members.find( member => {
    return member.id != userID
  });
  return chatter;
}

const AccountChats = () => {
  const classes = useStyles();
  const { chats, getMyChats } = useContext(MyChatsContext);
  const {
    isCurrentChatOpened,
    currentChatMessages,
    getCurrentChatMessages,
    chatDeleteResult,
    getCurrentChatInfo,
    hideCurrentChat,
    deleteCurrentChat}
    = useContext(CurrentChatContext);
  const handleClickChat = (chat) => {
    getCurrentChatInfo(chat);
  };

  useEffect(() => {
    getMyChats();
  }, []);

    // We use useEffect to refresh all my offers in case of deletion.
    useEffect(() => {
      if(chatDeleteResult && chatDeleteResult.type === 'success'){
        getMyChats();
      }
         
    }, [chatDeleteResult]);

  const printOfferDescription = anOffer => {
    let offerPrintedString = '';
    offerPrintedString +=
      (anOffer.descriptionGive &&
        anOffer.descriptionGive.substring(0, 15) + '... ') ||
      '';
    offerPrintedString +=
      (anOffer.descriptionTake &&
        anOffer.descriptionTake.substring(0, 15) + '... ') ||
      '';
    return offerPrintedString;
  };
  
  const printOfferTags = anOffer => {
    let offerPrintedString = '';
    offerPrintedString +=
      (anOffer.tagsTake &&
        anOffer.tagsTake.map(tag => tag.name).join(', ') + ' ') ||
      '';
    if (
      anOffer.tagsTake &&
      anOffer.tagsTake.length > 0 &&
      anOffer.tagsGive &&
      anOffer.tagsGive.length > 0
    ) {
      offerPrintedString += ' - ';
    }
    offerPrintedString +=
      (anOffer.tagsGive && anOffer.tagsGive.map(tag => tag.name).join(', ')) ||
      '';
    return offerPrintedString;
  };

  const checkForNotSeenMessages = (chat) => {
    let notSeenMessage = false;
    const lastMessages = chat.lastMessages;
    notSeenMessage = lastMessages.find((aMessage) => { 
      return aMessage.from.id === getChatterInfo(chat.members)['id'] && aMessage.condition === 'DELIVERED';
    });
    return notSeenMessage;
  }

  const getLastUpdateDateChat = (chat) => {
    let lastUpdateDateChat = chat.createdAt.slice(0,10);
    if(chat.lastMessages && chat.lastMessages.length > 0){
      if(chat.lastMessages[0].createdAt){
        lastUpdateDateChat = chat.lastMessages[0].createdAt.slice(0,10);
      }
    }
    return lastUpdateDateChat;
  }

  const handleDeleteChatClick = (chat) => {
    const r = window.confirm('Vuoi cancellare questa chat?');
    if (r === true) {
      deleteCurrentChat(chat);
    } 
  }

  if (!chats || !chats.list) {
    return <CircularProgress color="inherit" />
  }

  return (
    <List className={classes.root}>
      {chats.list.map((aChat) =>
      (getChatterInfo(aChat.members) &&  <React.Fragment key={aChat._id}>
          <ListItem className={classes.rowChat} alignItems="flex-start" button onClick={() => handleClickChat(aChat)}>
            <ListItemAvatar>
              <Avatar alt="Remy Sharp" src={getChatterInfo(aChat.members)['profileImage'] && getChatterInfo(aChat.members)['profileImage']['url']} />
            </ListItemAvatar>
            <ListItemText
              primary={printOfferDescription(aChat.offer) + printOfferTags(aChat.offer)}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant={checkForNotSeenMessages(aChat)? 'h4' : 'body2'}
                    className={classes.inline}
                    color="textPrimary"
                  >
                    {getChatterInfo(aChat.members)['name']}
                  </Typography>
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
            <ListItemText  primary={''} secondary={`⌚${getLastUpdateDateChat(aChat)}`} />
              <ListItemIcon onClick={() => handleDeleteChatClick(aChat)} edge="end">
                    <DeleteIcon />
              </ListItemIcon >
            </ListItemSecondaryAction>
          </ListItem>
          <Divider variant="inset" component="li" />
        </React.Fragment> )
      )}
    </List >
  );
}


export default AccountChats;