import { useReducer } from 'react';
import { ADD_MY_OFFER, GET_MY_OFFERS } from '../actions';

const getMyOffers = (offers, state) => ({
    offers: offers
});

const myOffersReducer = (state, action) => {
    switch (action.type) {
        case ADD_MY_OFFER:
            return action.offer;
        case GET_MY_OFFERS:
            return getMyOffers(action.offers, state);
    }
}

export const useMyOffersReducer = () =>
    useReducer(myOffersReducer, { offers: [] });
