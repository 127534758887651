import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { MyOffersContext } from '../../../../common/providers/MyOffersContext';
import { CurrentOffersContext } from '../../../../common/providers/CurrentOffersContext';
import { OfferEditSaveContext } from '../../../../common/providers/OfferEditSaveContext';

const  validURL = (str) =>  {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justify: 'space-between'
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    marginBottom: '1em'
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  divChips: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 50,
  },
}));

const AccountOffers = () => {
  const classes = useStyles();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [selectedIndex, setSelectedIndex] = React.useState('');
  const { offers, getMyOffers } = useContext(MyOffersContext);
  const currentOffersContext = useContext(CurrentOffersContext);
  const { reloadOffers, currentPosition } = currentOffersContext;
  const offerEditSaveContext = useContext(OfferEditSaveContext);
  const {
    getOffer,
    removeOffer,
    offerDeleteResult,
    offerEditSaveResult,
    saveOffer
  } = offerEditSaveContext;

  // We use useEffect to get all my offers.
  useEffect(() => {
    if(offers && offers.list && offers.list.length){
      forceUpdate();
    }
  }, [offers]);

  // We use useEffect to refresh all my offers in case of deletion.
  useEffect(() => {
    if(offerDeleteResult && offerDeleteResult.type === 'success'){
      getMyOffers();
      reloadOffers({...currentPosition, lng: currentPosition.lng + 0.00005 });
    }
       
  }, [offerDeleteResult]);

  // We use useEffect to refresh all my offers in case of deletion.
  useEffect(() => {
    if(offerEditSaveResult && offerEditSaveResult.type === 'success'){
      getMyOffers();
      reloadOffers({...currentPosition, lng: currentPosition.lng + 0.00005 });
    }
         
  }, [offerEditSaveResult]);

  const handleEditOfferClick = (offer) => {
    getOffer(offer._id);
  }

  const handleChangeStatus = (event, offer) => {
    const selectedStatus = event.target.value;

    if(selectedStatus !== offer.status){
      const r = window.confirm('Vuoi cambiare lo stato dell\'offerta?');
      if (r === true) {
        try {
          offer = {...offer, status: selectedStatus,
            position: {
              ...( offer.position.lat  && {lat: offer.position.lat}),
              ...( offer.position.lng  && {lng: offer.position.lng}),
              ...( offer.position.coordinates  && {lat: offer.position.coordinates[1]}),
              ...( offer.position.coordinates && {lng: offer.position.coordinates[0]}),
            }
          };
          saveOffer(offer);
        } catch (error) {
          //setOfferSaveMessage({type: 'error', message: 'Errore durante il salvataggio offerta!'});
        } 
        //removeOffer(offer._id);
      } 
    }
  
  }

  const handleClose = () => {
    setSelectedIndex('');
  };

  const handleOpen = index => {
    if (selectedIndex === index) {
      setSelectedIndex('')
    } else {
      setSelectedIndex(index)
    }
  };

  const handleDeleteOfferClick = (offer) => {
    const r = window.confirm('Vuoi cancellare la tua offerta?');
    if (r === true) {
      removeOffer(offer._id);
    } 
  }

  if (!offers || !offers.list) {
    return <CircularProgress color="inherit" />
  }
  if (offers.list && offers.list.length === 0) {
    return  <React.Fragment><Typography
      className={classes.title}
      variant="h6"
    >
    Non hai ancora inserito alcuna offerta! 
    </Typography>
    <Typography
    className={classes.title}
    variant="h6"
  >
    Vai alla mappa  e inseriscine una!
    </Typography>
    <Typography
    className={classes.title}
    variant="h3"
  >
    <Link href="/" >
    Vai alla mappa 
      </Link>
      </Typography>
    </React.Fragment>
  }

  return (
    offers.list.map((anOffer) =>
      <div className={classes.root} key={anOffer._id} >
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase className={classes.image}>
                <img
                  alt="Photo"
                  className={classes.image}
                  src={
                    (anOffer.photos &&
                        anOffer.photos[0] &&
                        anOffer.photos[0].url &&
                        validURL(anOffer.photos[0].url)) ? anOffer.photos[0].url :
                      '/images/farm.png'
                  }
                />              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1">
                    {anOffer.descriptionGive}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {anOffer.descriptionTake}
                  </Typography>
                  <div className={classes.divChips}>
                    {anOffer && anOffer.tagsGive && anOffer.tagsGive.map(tagMetaObj => (
                      <Chip key={tagMetaObj._id}
                        label={tagMetaObj.name}
                        color='primary'
                        variant='default'
                      />
                    ))}
                    {anOffer && anOffer.tagsTake && anOffer.tagsTake.map(tagMetaObj => (
                      <Chip key={tagMetaObj._id}
                        label={tagMetaObj.name}
                        color='primary'
                        variant='default'
                      />
                    ))}
                  </div>
                </Grid>
                <Grid item>
                  <Typography variant="body2" style={{ cursor: 'pointer' }}>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">              
                  <IconButton edge="end" aria-label="edit" onClick={() => handleEditOfferClick(anOffer)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteOfferClick(anOffer)}>
                    <DeleteIcon />
                  </IconButton>
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      open={anOffer._id === selectedIndex? true : false}
                      onClose={handleClose}
                      onOpen={()=> handleOpen(anOffer._id)}
                      value={anOffer.status}
                      onChange={(e) => handleChangeStatus(e, anOffer)}
                    >
                      <MenuItem value={anOffer.status}>
                      </MenuItem>
                      <MenuItem value={'ACTIVE'}>Attiva</MenuItem>
                      <MenuItem value={'BUSY'}>Occupata</MenuItem>
                      <MenuItem value={'INACTIVE'}>Inattiva</MenuItem>
                      <MenuItem value={'EXPIRED'}>Scaduta</MenuItem>
                    </Select>
                  </FormControl>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  );
}

export default AccountOffers;
