import React, { createContext, useEffect } from 'react';
import { useOfferEditSaveReducer } from '../reducers/useOfferEditSaveReducer';
import {
  GET_OFFER,
  SAVE_OFFER,
  DELETE_OFFER,
  GET_TAGS,
  TOGGLE_OFFER,
  RESULT_OFFER
} from '../actions';
import {
  OFFER_ADD_MUTATION,
  OFFER_UPDATE_MUTATION,
  OFFER_DELETE_MUTATION,
  OFFER_GET_QUERY,
  TAGS_SEARCH_QUERY
} from '../../graphql/queries';
import { useLazyQuery, useMutation } from '@apollo/client';

const OfferEditSaveContext = createContext();

const OfferEditSaveProvider = props => {
  const [state, dispatch] = useOfferEditSaveReducer();
  const {
    isOfferEditSaveOpened,
    allOfferTags,
    offerEditSaveData,
    offerEditSaveResult,
    offerDeleteResult
  } = state;

  const getOfferDispatch = offerData =>
    dispatch({ type: GET_OFFER, payload: offerData });
  const saveOfferDispatch = offerData =>
    dispatch({ type: SAVE_OFFER, payload: offerData });
  const resultOfferDispatch = resultOfferMessage =>
    dispatch({ type: RESULT_OFFER, payload: resultOfferMessage });
  const resultDeleteOfferDispatch = resultOfferMessage =>
    dispatch({ type: DELETE_OFFER, payload: resultOfferMessage });
  const getTagsDispatch = tagsData =>
    dispatch({ type: GET_TAGS, payload: tagsData });
  const toggleOfferDispatch = isOfferEditSaveOpened =>
    dispatch({ type: TOGGLE_OFFER, payload: isOfferEditSaveOpened });

  /**
   * Callback for offerAdd mutation.
   * @param {Object} data - The data offer.
   */
  const onCompleteOfferAdd = data => {
    if (!data.addOffer) {
      resultOfferDispatch({ type: 'error', message: 'Errore nel salvataggio' });
      return;
    }
    resultOfferDispatch({
      type: 'success',
      message: 'Offerta salvata con successo!'
    });
    saveOfferDispatch(data.addOffer);
  };

  /**
   * Callback for offerAdd mutation.
   * @param {Object} data - The data offer.
   */
  const onCompleteOfferUpdate = data => {
    if (!data.updateOffer) {
      resultOfferDispatch({ type: 'error', message: 'Errore nel salvataggio della modifica' });
      return;
    }
    resultOfferDispatch({
      type: 'success',
      message: 'Offerta aggiornata con successo!'
    });
    saveOfferDispatch(data.updateOffer);
  };

  /**
   * Callback for offerAdd mutation.
   * @param {Object} data - The data offer.
   */
  const onCompleteOfferDelete = data => {
    if (!data.deleteOffer) {
      resultDeleteOfferDispatch({ type: 'error', message: 'Errore nella rimozione della offerta' });
      return;
    }
    resultDeleteOfferDispatch({
      type: 'success',
      message: 'Offerta rimossa con successo!'
    });
  };

  /**
   * Callback for offerAdd mutation error.
   * @param {Object} data - The error.
   */
  const onErrorOfferAdd = error => {
    let errorMessage = error.message;
    if(error.message.includes('duplicate')){
      errorMessage = 'Offerta duplicata, cambia posizione!';
    }
    resultOfferDispatch({ type: 'error', message: errorMessage });
  };

  /**
   * Callback for offerUpdate mutation error.
   * @param {Object} data - The error.
   */
  const onErrorOfferUpdate = error => {
    let errorMessage = error.message;
    if(error.message.includes('duplicate')){
      errorMessage = 'Offerta duplicata, cambia posizione!';
    }
    resultOfferDispatch({ type: 'error', message: errorMessage });
  };
  
  /**
   * Callback for offerDelete mutation error.
   * @param {Object} data - The error.
   */
  const onErrorOfferDelete = error => {
    let errorMessage = error.message;
    if(error.message.includes('duplicate')){
      errorMessage = 'Offerta duplicata, cambia posizione!';
    }
    resultOfferDispatch({ type: 'error', message: errorMessage });
  };
  

  /**
   * Callback for tags search query.
   * @param {Object} data - The data offer.
   */
  const onCompleteTagsSearch = data => {
    if (!data.offerTags) return;
    let allTags = { TAKE: [], GIVE: [] };
    data.offerTags[0].allData &&
      data.offerTags[0].allData.map(value => {
        allTags[value.tagType].push(value);
      });
    getTagsDispatch(allTags);
  };

  /**
   * Callback for offerDelete mutation error.
   * @param {Object} data - The error.
   */
  const onErrorTagsSearch = error => {
    console.log( error.message );
  };

  const onCompleteOfferGet = data => {
    if (!data.offer) return;
    getOfferDispatch(data.offer);  
  };

  /**
   * Callback for offerDelete mutation error.
   * @param {Object} data - The error.
   */
  const onErrorOfferGet = error => {
    console.log( error.message );
  };

  const [addOffer] = useMutation(OFFER_ADD_MUTATION, {
    onCompleted: onCompleteOfferAdd,
    onError: onErrorOfferAdd
  });

  const [updateOffer] = useMutation(OFFER_UPDATE_MUTATION, {
    onCompleted: onCompleteOfferUpdate,
    onError: onErrorOfferUpdate
  });

  const [deleteOffer] = useMutation(OFFER_DELETE_MUTATION, {
    onCompleted: onCompleteOfferDelete,
    onError: onErrorOfferDelete
  });

  const [offerTags] = useLazyQuery(TAGS_SEARCH_QUERY, {
    onCompleted: onCompleteTagsSearch,
    onError: onErrorTagsSearch
  });

  const [offerGet, { data, refetch }] = useLazyQuery(OFFER_GET_QUERY, {
    onCompleted: onCompleteOfferGet,
    onError: onErrorOfferGet
  });

  const fetchTagsOffer = async () => {
    offerTags({ variables: {many: 50} });
  };

  const fetchGetOffer = async id => {
    await offerGet({ variables: { _id: id, salt: Math.random().toString(36).substring(7)}, fetchPolicy: 'network-only'  });
  };

  const fetchSaveOffer = async offerObj => {
    if(offerObj._id){
      updateOffer({ variables: offerObj });
      return;
    }
    addOffer({ variables: offerObj });
  };

  const fetchDeleteOffer = async id => {
    if(id){
      deleteOffer({ variables: {_id: id} });
    }
  };

  const getTags = () => {
    // get the offer by a call
  };

  const getOffer = id => {
    // get the offer by a call

    fetchGetOffer(id);
  };

  const saveOffer = offerObj => {
    // save the offer as a post call
    fetchSaveOffer(offerObj);
  };

  const removeOffer = id => {
    // save the offer as a post call
    fetchDeleteOffer(id);
  };

  const setOffer = (offerObj = {}) => {
    getOfferDispatch(offerObj);
  };

  const toggleOffer = () => {
    toggleOfferDispatch(isOfferEditSaveOpened);
  };

  // We use useEffect to get all tags.
  useEffect(() => {
    fetchTagsOffer();
  }, []);

  // We use useEffect to get offer.
  useEffect(() => {
    if ( data && data.offer){
    }
  }, [data]);

  // We use useEffect to check offer gets.
  /*     useEffect(() => {
      if (data) {
        getOfferDispatch(data.offer);
      }
    }, [data]); */

  const providerValue = {
    isOfferEditSaveOpened,
    allOfferTags,
    offerEditSaveData,
    offerEditSaveResult,
    offerDeleteResult,
    getTags,
    saveOffer,
    getOffer,
    setOffer,
    removeOffer,
    toggleOffer
  };

  return (
    <OfferEditSaveContext.Provider value={providerValue}>
      {props.children}
    </OfferEditSaveContext.Provider>
  );
};

export { OfferEditSaveContext, OfferEditSaveProvider };
