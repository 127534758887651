import React, { useState, useContext, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import { AccountDialog } from 'components';
import { NotificationsContext } from '../../../../common/providers/NotificationsContext';
import {AccountContext} from '../../../../common/providers/AccountContext';
import {CurrentOffersContext} from '../../../../common/providers/CurrentOffersContext';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  title: {
    flexGrow: 1,
    color: theme.palette.white
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const Topbar = props => {
  const { className, onSidebarOpen, history, ...rest } = props;

  const classes = useStyles();
  const [isAccountDialogOpened, setIsAccountDialogOpened] = useState(false);
  const [defaultDialogTab, setDefaultDialogTab] = useState(0);
  const { notifications,
    getNotifications,
    removeNotifications } = useContext(NotificationsContext);
  const {signoutAccount} = useContext(AccountContext);
  const currentOffersContext = useContext(CurrentOffersContext);
  const { reloadOffers } = currentOffersContext;

  const handleSignOut = event => {
    event.preventDefault();
    signoutAccount();
    //localStorage.removeItem(AUTH_TOKEN)
    //window.location.href = '/sign-in'
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [engagementNotifications, setEngagementNotifications] = useState(null);
  const [chatNotifications, setChatNotifications] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setIsAccountDialogOpened(true);
  };

  const handleChatMenuOpen = (event) => {
    setDefaultDialogTab(0);
    setIsAccountDialogOpened(true);
    //setChatNotifications(0);
    notifications.chats && removeNotifications('chats');
  };

  const handleCenterMap = (event) => {
      // Try HTML5 geolocation.
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          position => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            reloadOffers(pos);
            // mapService.current.setCenter(pos);
            // props.onDragEnd(pos);
          },
          () => {
            alert('Errore: Il servizio di geolocalizzazione non è al momento disponibile.');
          }
        );
      } else {
        // Browser doesn't support Geolocation
        alert('Errore: il tuo browser non supporta la geolocalizzazione.');
      }
  };
  const handleEngagementMenuOpen = (event) => {
    setDefaultDialogTab(1);
    setIsAccountDialogOpened(true);
    //setEngagementNotifications(0);
    notifications.engagements && removeNotifications('engagements');
  };

  const handleMobileMenuClose = (element) => {
    if (element === 'account') {
      setIsAccountDialogOpened(true);
    }
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = (element) => {
    if (element === 'account') {
      setIsAccountDialogOpened(true);
    }
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const accountDialogClosedHandler = () => {
    setIsAccountDialogOpened(false);
  }

  useEffect(()=>{
    if(notifications && (notifications.chats || notifications.engagements)){
      setEngagementNotifications(notifications.engagements && notifications.engagements.unread || null);
      setChatNotifications(notifications.chats && notifications.chats.unread || null);
    }else{
      setEngagementNotifications(null);
      setChatNotifications(null);
    }
  },[notifications])

  const menuId = 'primary-search-account-menu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      onClose={handleMenuClose}
      open={isMenuOpen}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem onClick={() => handleMenuClose('account')}>Profilo</MenuItem>
      <MenuItem onClick={handleMenuClose}>Le mie offerte</MenuItem>
      {/* <MenuItem onClick={handleMenuClose}>I miei lavori</MenuItem> */}
      <MenuItem onClick={handleMenuClose}>I preferiti</MenuItem>
      <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
    </Menu>
  );


  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      onClose={handleMobileMenuClose}
      open={isMobileMenuOpen}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem onClick={handleCenterMap}>
        <IconButton
          aria-label="center map"
          color="inherit"
        >
            <CenterFocusWeakIcon />
        </IconButton>
        <p>Centra</p>
      </MenuItem>
      <MenuItem onClick={handleChatMenuOpen}>
        <IconButton
          aria-label="show 4 new mails"
          color="inherit"
        >
          <Badge
            badgeContent={chatNotifications}
            color="secondary"
          >
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem onClick={handleEngagementMenuOpen}>
        <IconButton
          aria-label="show new notifications"
          color="inherit"
        >
          <Badge
            badgeContent={engagementNotifications}
            color="secondary"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          aria-label="account of current user"
          color="inherit"
        >
          <DashboardIcon />
        </IconButton>
        <p>Dashboard</p>
      </MenuItem>
      <MenuItem onClick={handleSignOut}>
        <IconButton
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          aria-label="account of current user"
          color="inherit"
        >
          <ExitToAppIcon />
        </IconButton>
        <p>Esci</p>
      </MenuItem>
    </Menu>
  );

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logos/logo-tazebao.png"
          />
        </RouterLink>
        <Typography
          className={classes.title}
          variant="h4"
        >
          Zappami
        </Typography>
        <div className={classes.flexGrow} />
        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>
        <IconButton
          aria-label="center map"
          color="inherit"
          onClick={handleCenterMap}
        >
            <CenterFocusWeakIcon />
        </IconButton>
          <IconButton
            aria-label="show  new mails"
            color="inherit"
            onClick={handleChatMenuOpen}
          >
            <Badge
              badgeContent={chatNotifications}
              color="secondary"
            >
              <MailIcon />
            </Badge>
          </IconButton>
          <IconButton
            aria-label="show 17 new notifications"
            color="inherit"
            onClick={handleEngagementMenuOpen}
          >
            <Badge
              badgeContent={engagementNotifications}
              color="secondary"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            aria-controls={menuId}
            aria-haspopup="true"
            aria-label="account of current user"
            color="inherit"
            edge="end"
            onClick={handleProfileMenuOpen}
          >
            <ViewHeadlineIcon />
          </IconButton>
          <IconButton
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            aria-label="account of current user"
            color="inherit"
            onClick={handleSignOut}
          >
            <ExitToAppIcon />
          </IconButton>
        </div>
        <div className={classes.sectionMobile}>
          <IconButton
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            aria-label="show more"
            color="inherit"
            onClick={handleMobileMenuOpen}
          >
            <MoreIcon />
          </IconButton>
        </div>
      </Toolbar>
      {renderMobileMenu}
      <AccountDialog
        accountDialogClosedHandler={accountDialogClosedHandler}
        defaultTab={defaultDialogTab}
        isAccountDialogOpened={isAccountDialogOpened}
        title="Dashboard utente"
      />
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
