import React, { createContext, useEffect } from 'react';
import { useCurrentOffersReducer } from '../reducers/useCurrentOffersReducer';
import { SEARCH_CURRENT_OFFERS, CREATE_OFFER, SET_CURRENT_POSITION, SELECT_OFFER } from '../actions';
import {
  OFFERS_SEARCH_QUERY,
  OFFER_GET_QUERY,
} from '../../graphql/queries';
import { useLazyQuery, useMutation } from '@apollo/client';

const CurrentOffersContext = createContext();


const CurrentOffersProvider = (props) => {
  const [state, dispatch] = useCurrentOffersReducer();
  const { offers, currentPosition, selectedOffer } = state;
  const setCurrentPositionDispatch = (aPosition) => dispatch({type: SET_CURRENT_POSITION, payload: aPosition});
  const addOffer = (offer) => dispatch({ type: CREATE_OFFER, payload: offer });
  const searchOffersDispatch = (offers) => dispatch({ type: SEARCH_CURRENT_OFFERS, payload: offers });
  const selectAnOfferDispatch = (offer) => dispatch({ type: SELECT_OFFER, payload: offer });

  const onCompleteOffersSearch = data => {
    if (!data.offers || !data.offers[0] || !data.offers[0].allData) return;
    searchOffersDispatch({position: currentPosition, list: data.offers[0].allData}); 
  };

  const [offersSearch, { data, refetch }] = useLazyQuery(OFFERS_SEARCH_QUERY, {
    onCompleted: onCompleteOffersSearch
  });

  const fetchSearchOffers = async (position, radius) => {
    offersSearch({ variables: {filter: {position:{lat: position.lat, lng: position.lng},
      radius: radius }, salt: Math.random().toString(36).substring(7)}});
  }

  const createOffer = async (offer) => {
    // send the offer as a post call   
    addOffer(offer);
  }

  // We use useEffect to make API calls.
  useEffect(() => {
    fetchSearchOffers(currentPosition);
  }, []);


  // We use useEffect to check new offers updates.
  /*   useEffect(() => {
    if (data) {
      if (!data.offers || !data.offers[0] || !data.offers[0].allData) return;
      searchOffersDispatch({position: currentPosition, list: data.offers[0].allData});
    }
  }, [data]); */

  const reloadOffers = (position = currentPosition, radius = 10000) => {
    setCurrentPositionDispatch(position);
    fetchSearchOffers(position, radius);
    //refetch();
  }
  
  const selectAnOffer = offer => {
    selectAnOfferDispatch(offer);
  }
  const providerValue = {
    offers,
    reloadOffers,
    currentPosition,
    createOffer,
    selectedOffer,
    selectAnOffer
  }

  return (<CurrentOffersContext.Provider value={providerValue}>
    {props.children}
  </CurrentOffersContext.Provider>)

}

export { CurrentOffersContext, CurrentOffersProvider };
