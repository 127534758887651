import { useReducer } from 'react';
import { REFRESH_W3W} from '../actions';

const initialState = {
    w3wAddress: null,
    w3wId: null
};

const w3wReducer = (state, action) => {
    switch (action.type) {
        case REFRESH_W3W:
            return {
                ...state,
                w3wAddress: action.payload.w3wAddress,
                w3wId: action.payload.w3wId
            };
        default:
            return state;
    }
};


export const useW3WReducer = () =>
    useReducer(w3wReducer, initialState);