import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const Privacy = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        spacing={4}
      >
        <Grid
          item
          lg={8}
          xs={12}
        >
          <div className={classes.content}>
            <Typography variant="h1">
            Informativa sui cookie / cookie policy
            </Typography>
            <Typography variant="h6" gutterBottom align="left">
            Cosa sono i cookie?
      </Typography>
            <Typography variant="body1"  align="left">
            I cookie sono stringhe di testo create da un server e memorizzati sull’hard disk del computer o su qualsiasi dispositivo utilizzato dall’utente per accedere ad Internet (smartphone, tablet) per poi essere ritrasmessi ai successivi accessi ad Internet dell’utente stesso.

I cookie permettono di raccogliere informazioni sulla navigazione effettuata dall’utente sul sito web.

I cookie possono essere memorizzati in modo permanente sul Suo computer ed avere una durata variabile (c.d. cookies persistenti), ma possono anche svanire con la chiusura del browser o avere una durata limitata (c.d. cookies di sessione).

I cookie possono essere istallati dal sito che sta visitando (c.d. cookie di prima parte) o possono essere istallati da altri siti web che forniscono servizi di vario genere (c.d. cookie di terze parti).
</Typography>
<Typography variant="h6" gutterBottom align="left">
Come il Titolare utilizza i cookie?
      </Typography>
      <Typography variant="body1"  align="left">

      Cookie tecnici e di sessione. 
Questi cookie sono essenziali alla navigazione in tutto il sito ed all’utilizzo di tutte le sue funzionalità, come ad esempio la compilazione del form per l’iscrizione alla newsletter. Senza questi cookie alcuni servizi necessari, non possono essere fruiti.
Zappami.com utilizza solo cookie tecnici e di sessione.</Typography>
<Typography variant="h6" gutterBottom align="left">
Come modificare e gestire le impostazioni sui cookie?
      </Typography>
      <Typography variant="body1"  align="left">
Zappami.com utilizza i cookie tecnici e di sessione necessari per il buon funzionamento del sito.

Può opporsi alla registrazione di cookie sul Suo hard disk configurando il suo browser di navigazione in modo da disabilitare i cookie. Di seguito riportiamo le modalità offerte dai principali browser:

Internet Explorer  (Gestione dei cookie)
Chrome (Gestione dei cookie)
Firefox  (Gestione dei cookie)
Opera  (Gestione dei cookie)
Safari (Gestione dei cookie)
Dopo questa operazione, tuttavia, alcune funzioni delle pagine web potrebbero non essere eseguite correttamente. 


I dati raccolti utilizzando i cookie non saranno diffusi.
</Typography>
<Typography variant="h6" gutterBottom align="left">
Titolare del trattamento dei dati
      </Typography>
      <Typography variant="body1"  align="left">
      Titolare del trattamento è Inventu s.r.l.s., con sede legale in P.zza Indipendenza n.3 Padru, 07020 (OT) - mail: info@zappami.com</Typography>
<Typography variant="h6" gutterBottom align="left">
I Suoi diritti
      </Typography>
      <Typography variant="body1"  align="left">

      Lei ha diritto di ottenere dal titolare la cancellazione (diritto all'oblio), la limitazione, l'aggiornamento, la rettificazione, la portabilità, l'opposizione al trattamento dei dati personali che La riguardano, nonché in generale può esercitare tutti i diritti previsti dagli artt. 15, 16, 17, 18, 19, 20, 21, 22 del GDPR, consultabili al seguente link.

Per l'esercizio dei Suoi diritti, puoi inviare una comunicazione all’indirizzo sotto indicato ovvero un e-mail a info@zappami.com

Ai sensi dell’Art. 77 del GDPR può presentare una segnalazione o un reclamo rivolgendosi all’Autorità Garante della Privacy con sede in Piazza di Monte Citorio n. 121 - 00186 ROMA - mail: urp@gpdp.it</Typography>

          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Privacy;
