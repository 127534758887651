import React, {useState, useContext, useEffect} from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import { green } from '@material-ui/core/colors';
import { OfferStepForm } from 'components';
import { useMediaQuery } from '@material-ui/core';
import { OfferEditSaveContext } from '../../common/providers/OfferEditSaveContext';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: theme.palette.white
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createMuiTheme({
    palette: {
        primary: green,
    },
});

export default function AddEditOffer(props) {
    const classes = useStyles();
    const { positionOffer, offerData, w3wAddress, w3wId, ...rest } = props;
    const [open, setOpen] = useState(false);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
      });
    const [offerObj, setOfferObj] = useState({position: positionOffer, w3wData: {address: w3wAddress, idW: w3wId},
    });

    const offerEditSaveContext = useContext(OfferEditSaveContext);
    const {
      setOffer,
  } = offerEditSaveContext;
      
    const handleClickOpen = () => {
        //setOpen(true);
        setOffer(offerObj);
    };

    const handleClose = () => {
        //setOpen(false);
    };

    useEffect(() => {
        setOfferObj({position: positionOffer, w3wData: {address: w3wAddress, idW: w3wId},
        });
      }, [w3wAddress]);

    return (
        <div>
            <ThemeProvider theme={theme}>
                <Button
                    size={isDesktop? 'small' : 'large' }
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    startIcon={<AddToQueueIcon />}
                    onClick={handleClickOpen}
                >
                    AGGIUNGI OFFERTA QUÌ
      </Button></ThemeProvider>

        </div>
    );
}
