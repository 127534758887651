/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ChatBubble from './ChatBubble';

import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { CurrentChatContext } from '../../common/providers/CurrentChatContext';
import {AccountContext} from '../../common/providers/AccountContext';
import { MyChatsContext } from '../../common/providers/MyChatsContext';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up"
ref={ref}
{...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: theme.palette.white,
  },
}));

const Chat = (props) => {
  const classes = useStyles();
  const { isOpened } = props;
  const [open, setOpen] = useState(isOpened);
  const [chatterId, setChatterId] = useState('-LrDEBoLokW-5mhaT3ys');
  const {
    isCurrentChatOpened,
    currentChatInfo,
    currentChatMessages,
    getCurrentChatMessages,
    getCurrentChatInfo,
    getCurrentChat,
    updateMessageChat,
    sendMessageChat,
    hideCurrentChat }
    = useContext(CurrentChatContext);
    const {user} = useContext(AccountContext);
    const { getMyChats } = useContext(MyChatsContext);

  const image = 'https://avatarfiles.alphacoders.com/237/237528.jpg';
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if(user && user.id){
      setChatterId(user.id);
    }
  },[user]);

  const handleClose = () => {
    hideCurrentChat();
    getMyChats();
  };

  const updateMessagesAsSeen = (allMessages) => {
    allMessages.list.forEach((aMessage) => {
      if(aMessage.condition === 'DELIVERED' && aMessage.from.id !== user.id){
        updateMessageChat({        _id: aMessage._id,
          condition: 'SEEN'})
      }
    })
  }

  const handleNewMessage = text => {
    sendMessageChat({
      chatID: currentChatInfo._id, fromID: chatterId,
      content: text,
      type: 'TEXT'
    });
  };

  useEffect(() => {
    if(currentChatMessages){
      updateMessagesAsSeen(currentChatMessages);
      setMessages(currentChatMessages);
    }
  }, [currentChatMessages]);

  useEffect(() => {
    //getCurrentChatMessages();
  }, []);

  return (
    <Dialog fullScreen
onClose={handleClose}
open={isCurrentChatOpened}
TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton aria-label="close"
color="inherit"
edge="start"
onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title}
variant="h6">
            Chat
            </Typography>
        </Toolbar>
      </AppBar>
      <ChatBubble
        chatInfo={currentChatInfo}
        chatterId={chatterId}
        messages={messages || []}
        onNewMessage={handleNewMessage}
      />
    </Dialog>);
}

export default Chat;