import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { Sidebar, Topbar, Footer } from './components';
import {CurrentOffersProvider} from '../../common/providers/CurrentOffersContext';
import {PreferredOffersProvider} from '../../common/providers/PreferredOffersContext';

import {W3WProvider} from '../../common/providers/W3wContext';
import {OfferDetailsProvider} from '../../common/providers/offerDetailsContext';
import { createBrowserHistory } from 'history';
import { OfferEditSaveProvider } from '../../common/providers/OfferEditSaveContext';
import {CurrentChatProvider} from '../../common/providers/CurrentChatContext';
import {NotificationsProvider} from '../../common/providers/NotificationsContext';
import {MyOffersProvider} from '../../common/providers/MyOffersContext';
import {MyChatsProvider} from '../../common/providers/MyChatsContext';

const browserHistory = createBrowserHistory();

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 56,
    height: '100%'
  },
  content: {
    height: '100%'
  }
}));

const Minimal = props => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(true);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  //const shouldOpenSidebar = isDesktop ? true : openSidebar;
  const anchor = isDesktop ? 'left' : 'bottom';

  return (
    <CurrentOffersProvider>
      <PreferredOffersProvider>
        <MyOffersProvider>
          <W3WProvider>
            <OfferDetailsProvider>
              <OfferEditSaveProvider>
                <MyChatsProvider>
                  <CurrentChatProvider>
                    <NotificationsProvider>
                      <div className={classes.root}>
                        <Topbar
                          history={browserHistory}
                          onSidebarOpen={handleSidebarOpen}
                        />
                        <Sidebar
                          anchor={anchor}
                          onClose={handleSidebarClose}
                          open={openSidebar}
                          variant="persistent"
                        />
                        <main className={classes.content}>{children}
                          <Footer />
                        </main>
                      </div>
                    </NotificationsProvider>
                  </CurrentChatProvider>
                </MyChatsProvider>
              </OfferEditSaveProvider> 
            </OfferDetailsProvider>
          </W3WProvider>
        </MyOffersProvider>
      </PreferredOffersProvider>
    </CurrentOffersProvider>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Minimal;
